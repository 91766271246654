import {User} from "./user";


export class OnderaannemerFirma {
  public firmaNaam: string;
  public arbeiderCount: number;
  public arbeiders: User[];
  constructor(firmaNaam: string, arbeiderCount: number, arbeiders: User[]) {
    this.firmaNaam = firmaNaam;
    this.arbeiderCount = arbeiderCount;
    this.arbeiders = arbeiders;
  }
}
