<form *ngIf="isLoaded" class="addForm" [formGroup]="addForm" (ngSubmit)="onSubmit(addForm.value)">
  <div style="display:flex;flex-direction: column; gap: 15px 0; max-width: 45%;">
    <h2 style="margin-bottom: 8px">Bedrijf bewerken</h2>
    <label for="name">Bedrijfsnaam</label>
    <input style="margin-bottom: 14px;" nbInput formControlName="name" id="name" type="text" placeholder="Vul bedrijfsnaam in">


    <nb-checkbox formControlName="heeftKostenBerekening" ><i class="fa-solid fa-dollar-sign bigFaIcon"></i> Heeft kostenberekening</nb-checkbox>

    <nb-checkbox formControlName="heeftReparatieModule" ><i class="fa-solid fa-screwdriver-wrench bigFaIcon"></i> Heeft reparatiemodule</nb-checkbox>

    <nb-checkbox formControlName="heeftMagazijnierModule" ><i class="fa-solid fa-warehouse bigFaIcon"></i> Heeft magazijniermodule</nb-checkbox>

    <nb-checkbox formControlName="heeftSchademeldingsModule"><i class="fa-solid fa-triangle-exclamation bigFaIcon"></i> Heeft schademeldingsmodule</nb-checkbox>

    <nb-checkbox formControlName="heeftVerlofAanvragen"><i class="fa-solid fa-calendar-alt bigFaIcon"></i> Heeft verlofaanvragen</nb-checkbox>


    <label for="ovamId"><i class="fa-solid fa-recycle bigFaIcon"></i> OVAM ID</label>
    <input nbInput id="ovamId" formControlName="ovamId" type="text" placeholder="OVAM ID">




    <button nbButton style="background-color: blue;color:white; margin-top: 20px;" type="submit">Bedrijf bewerken</button>

    <ul>
      <li>Code ploegbazen: {{company.code}}</li>
      <li>Code chauffeur: {{company.codeChauffeur}}</li>
      <li>Code onderhoudstechnieker: {{company.codeOnderhoudsTechnieker}}</li>
      <li>Code magazijnier: {{company.codeMagazijnier}}</li>
      <li>Code werfleider: {{company.adminCode}}</li>
      <li>Code administrator: {{company.administratorCode}}</li>
    </ul>

  </div>

  <div style="display:flex;flex-direction: column; gap: 10px 0;max-width: 45%;" formGroupName="prijsInstellingen">
    <label for="dagrapportenModulePrijsPerArbeider"><i class="fa-solid fa-person-digging bigFaIcon"></i> Dagrapporten Module Prijs Per Arbeider</label>
    <input nbInput id="dagrapportenModulePrijsPerArbeider" formControlName="dagrapportenModulePrijsPerArbeider" type="number" class="smallInput" placeholder="EUR">

    <label for="dagboekenModulePrijsPerArbeider"><i class="fa-solid fa-book-open bigFaIcon"></i> Dagboeken Module Prijs Per Arbeider</label>
    <input  nbInput id="dagboekenModulePrijsPerArbeider" formControlName="dagboekenModulePrijsPerArbeider" type="number" class="smallInput" placeholder="EUR">

    <label for="combinatieDagrapportenEnDagboekenModulePrijsPerArbeider"><i class="fa-solid fa-person-digging bigFaIcon"></i> & <i class="fa-solid fa-book-open bigFaIcon"></i>
      Combinatie Dagrapporten en Dagboeken Module Prijs Per Arbeider</label>
    <input nbInput id="combinatieDagrapportenEnDagboekenModulePrijsPerArbeider" formControlName="combinatieDagrapportenEnDagboekenModulePrijsPerArbeider" type="number" class="smallInput" placeholder="EUR">

    <label for="gewoneTransportModulePrijsPerChauffeur"><i class="fa-solid fa-truck bigFaIcon"></i> Gewone Transport Module Prijs Per Chauffeur</label>
    <input nbInput id="gewoneTransportModulePrijsPerChauffeur" formControlName="gewoneTransportModulePrijsPerChauffeur" type="number" class="smallInput" placeholder="EUR">

    <label for="reparatieModulePrijsPerOnderhoudstechnieker"><i class="fa-solid fa-screwdriver-wrench bigFaIcon"></i> Reparatie Module Prijs Per Onderhoudstechnieker</label>
    <input nbInput id="reparatieModulePrijsPerOnderhoudstechnieker" formControlName="reparatieModulePrijsPerOnderhoudstechnieker" type="number" class="smallInput"placeholder="EUR">

    <label for="magazijnierModulePrijsPerMagazijnier"><i class="fa-solid fa-warehouse bigFaIcon"></i> Magazijnier Module Prijs Per Magazijnier</label>
    <input nbInput id="magazijnierModulePrijsPerMagazijnier" formControlName="magazijnierModulePrijsPerMagazijnier" type="number" class="smallInput" placeholder="EUR">

    <label for="verlofAanvragenModulePrijsPerGebruiker"><i class="fa-solid fa-calendar-days bigFaIcon"></i> Verlofaanvragen Module Prijs Per Gebruiker</label>
    <input nbInput id="verlofAanvragenModulePrijsPerGebruiker" formControlName="verlofAanvragenModulePrijsPerGebruiker" type="number" class="smallInput" placeholder="EUR">

    <label for="ovamModulePrijsPerIdentificatieFormulier"><i class="fa-solid fa-recycle bigFaIcon"></i> OVAM Module Prijs Per Identificatie Formulier</label>
    <input nbInput id="ovamModulePrijsPerIdentificatieFormulier" formControlName="ovamModulePrijsPerIdentificatieFormulier" type="number" class="smallInput" placeholder="EUR">

  </div>

</form>
