<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
<div *ngIf="isLoaded"  class="navRow">
  <button mat-raised-button class="nav-button-black" (click)="onCloseClick(false)">Sluiten</button>
  <div class="navigation-row">
    <button *ngIf="index != null && index !== -1" [disabled]="index === 0" mat-raised-button class="nav-button" (click)="onPreviousClick(false)">
      <i class="fa-solid fa-left-long navIcon"></i>
    </button>
    <p *ngIf="index != null && klanten != null && index !== -1"  class="boldText">{{index + 1}}/{{klanten.length}}</p>
    <button  *ngIf="index != null && index !== -1" [disabled]="index === klanten.length - 1" mat-raised-button class="nav-button" (click)="onNextClick(false)">
      <i class="fa-solid fa-right-long navIcon"></i>
    </button>
  </div>
  <button mat-raised-button class="save-button" color="primary" (click)="onSubmit(editForm.value)">Opslaan</button>
</div>
<mat-card *ngIf="isLoaded" class="edit-card-klant">
  <div class="topRow">
    <mat-card-title style="margin-left: 15px; font-size: 22px">{{isRealBedrijfView? 'Verbonden bedrijf' : 'Bedrijf bewerken'}}  <small style="font-size:14px;margin-left: 10px">{{isRealBedrijfView ? '(Dit bedrijf beheert zelf zijn bedrijfsgegevens)':''}}</small></mat-card-title>
    <button mat-raised-button class="delete-button" color="warn" type="button"
            (click)="onDeleteClick()"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
  </div>
  <form [formGroup]="editForm"  class="outerRowDiv">
    <table class="tableKlanten">
      <tbody>
      <tr>
        <td style="min-width:170px !important;">BTW-nummer<br><small>(incl. BE, ...)</small></td>
        <td>
          <input [readonly]="true" class="lightGrey" nbInput id="btwNummer" type="text" formControlName="btwNummer">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
        <td colspan="2"></td>
      </tr>
      <tr>
        <td>Bedrijfsnaam</td>
        <td colspan="3">
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" class="longInput" nbInput id="name" type="text" formControlName="naam">
          <p style="color:red" *ngIf="isNameInvalid">Gelieve een naam in te geven</p>
        </td>
      </tr>
      <tr>
        <td >Straat</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="street" type="text" formControlName="straat">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
        <td class="secondRowText">Huisnummer</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="houseNumber" type="text" formControlName="huisNr">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
      </tr>
      <tr>
        <td>Gemeente</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="gemeente" type="text" formControlName="gemeente">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
        <td class="secondRowText">Postcode</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="postcode" type="number" formControlName="postcode">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
      </tr>
      <tr>
        <td >Telefoonnummer</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="phoneNumber" type="text" formControlName="telefoonNummer">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
        <td class="secondRowText">Land</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="country" type="text" formControlName="land">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
      </tr>
      <tr>
        <td>Email onvangst<br>afvalstofformulieren</td>
        <td>
          <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="email" type="email" formControlName="email">
          <!-- Voeg hier een validatiebericht toe indien nodig -->
        </td>
        <td class="secondRowText" *ngIf="isRealBedrijfView && klant.realBedrijf.werfleider?.handtekening">Account aftekening QR-code</td>
        <td *ngIf="isRealBedrijfView && klant.realBedrijf?.werfleider?.handtekening"><b>{{klant.realBedrijf.werfleider.name}}</b></td>
      </tr>

      </tbody>
    </table>

    <div class="vestigingenOuterDiv" formArrayName="vestigingen">
      <div *ngFor="let vestiging of getVestigingen().controls; let i=index" [formGroupName]="i" class="vestigingenInnerDiv">
        <div class="innerTopRowVestiging">
          <mat-card-title style="width:90%"><span style="text-decoration: underline;">Vestiging {{i+1}}</span><small style="padding-left: 8px">*Verplicht indien klant mogelijks afvalproducent of verwerker is</small></mat-card-title>
          <button *ngIf="!isRealBedrijfView" mat-raised-button class="delete-button" color="warn" type="button"
                  (click)="verwijderVestiging(i)"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
        </div>
        <table class="tableKlanten">
          <tbody>
          <tr>
            <td style="min-width: 170px !important;">Vestigingsnaam</td>
            <td colspan="3">
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" class="longInput" nbInput id="name" type="text" formControlName="naam">
              <p style="color:red" *ngIf="isNameInvalid">Gelieve een naam in te geven</p>
            </td>
          </tr>
          <tr>
            <td style="min-width: 170px !important;">Vestigingsnummer</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="email" type="email" formControlName="vestigingsNummer">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td >Straat</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="street" type="text" formControlName="straat">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Huisnummer</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="houseNumber" type="text" formControlName="huisNr">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td>Gemeente</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="gemeente" type="text" formControlName="gemeente">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td class="secondRowText">Postcode</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="postcode" type="number" formControlName="postcode">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
          </tr>
          <tr>
            <td >Land</td>
            <td>
              <input [readonly]="isRealBedrijfView" [ngClass]="isRealBedrijfView? 'lightGrey' : ''" nbInput id="country" type="text" formControlName="land">
              <!-- Voeg hier een validatiebericht toe indien nodig -->
            </td>
            <td></td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="!isRealBedrijfView" style="display: flex;flex-direction: row;justify-content: flex-start; align-items:center;gap:0 100px; margin:20px 0;">
      <button (click)="addNewVestiging()"    type="button"  mat-raised-button color="primary" class="btn btn-primary addVestigingButton"> <i class="fas fa-plus"></i>Voeg extra vestiging toe</button>
    </div>
  </form>

</mat-card>
<div *ngIf="isLoaded" style="margin-bottom: 500px;"  class="navRow">
  <button mat-raised-button class="nav-button-black" (click)="onCloseClick(false)">Sluiten</button>
  <div class="navigation-row">
    <button *ngIf="index != null && index !== -1" [disabled]="index === 0" mat-raised-button class="nav-button" (click)="onPreviousClick(false)">
      <i class="fa-solid fa-left-long navIcon"></i>
    </button>
    <p *ngIf="index != null && klanten != null && index !== -1"  class="boldText">{{index + 1}}/{{klanten.length}}</p>
    <button  *ngIf="index != null && index !== -1" [disabled]="index === klanten.length - 1" mat-raised-button class="nav-button" (click)="onNextClick(false)">
      <i class="fa-solid fa-right-long navIcon"></i>
    </button>
  </div>
  <button mat-raised-button class="save-button" color="primary" (click)="onSubmit(editForm.value)">Opslaan</button>
</div>

