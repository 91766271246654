import { Injectable, ElementRef } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import {ApiService} from "./api.service";
import {Dagrapport} from "../models/dagrapport";
import {User} from "../models/user";
import {Werkzaamheid} from "../models/werkzaamheid";
import {Werk} from "../models/werk";
import {NbToastrService} from "@nebular/theme";
import {Werf} from "../models/werf";
import isEqual from 'lodash/isEqual';
import {Transportdagrapport} from "../models/transport-dagrapport";
import {Materiaaltransport} from "../models/materiaaltransport";
import {Materiaal} from "../models/materiaal";
import Borders from "exceljs/index";
import FillPattern from "exceljs/index";
import {Machine} from "../models/machine";

@Injectable()
export class ExcelService {

  constructor(private apiService: ApiService, private toastrService: NbToastrService) {}

  async getBase64ImageFromUrl(imageUrl) {
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    return new Promise((resolve, reject) => {
      let reader  = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }


  generateExcelWerkenByWerkzaamheid(allWerken: Werk[], companyName, allWerven: Werf[], werkzaamheid: Werkzaamheid, subActiviteit: string) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Werkzaamheid kost', {
      views: [{state: "frozen", ySplit: 3}],
    });
    // Add new row
    let titleRow = worksheet.addRow(['Werkzaamheid kost']);
    titleRow.font = {
      name: 'Arial',
      family: 4,
      size: 16,
      underline: 'double',
      bold: true,
    };
    titleRow.height = 23;
    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('D1:F1');
    titleRow.getCell('D').value = companyName;



    let totaalKostPloegbaas = 0;
    let totaalKostArbeiders = 0;
    let totaalKostOnderaannemers = 0;
    let totaalKostMachine = 0;
    let totaalMinutenPloegbaas = 0;
    let totaalMinutenArbeiders = 0;
    let totaalMinutenOnderaannemers = 0;
    let allMachines = [];
    let allMaterialen = [];

      let loonPloegbaas = 0;
      let loonArbeider = 0;
      for (let werk of allWerken) {
        let totaalKostWerk = 0;

        loonPloegbaas = allWerven.find(x => x._id === werk.werfId)?.uurLoonPloegbaas;
        loonArbeider = allWerven.find(x => x._id === werk.werfId)?.uurLoonArbeider;
        if(loonPloegbaas == null || loonPloegbaas === 0 ||
          loonArbeider == null || loonArbeider === 0){
          if(allWerven.find(x => x._id === werk.werfId) != null){
            this.toastBadForm(allWerven.find(x => x._id === werk.werfId).naam);
            return;
          }
        } else {
          if(werk.machinisten != null && werk.machinisten.length !== 0){
            for(let i = 0; i < werk.machinisten.length;i++){
              let machine = werk.machines[i];
              let machinist = werk.machinisten[i];
              if (machinist != null) {
                let index = allMachines.findIndex(x => x.machine._id === machine._id);
                if (index == null || index === -1) {
                  allMachines.push({minuten: 0, kost:0 ,machine: machine})
                  index = allMachines.findIndex(x => x.machine._id === machine._id);
                }
                let machinistUurloon;
                let isPloegbaas;
                if (machinist.role === '59cf78e883680012b0438501') {
                  isPloegbaas = true;
                  machinistUurloon = loonPloegbaas;
                } else if (machinist.role === '59cf78e883680012b0438504' || machinist.role === '59cf78e883680012b0438502') {
                  machinistUurloon = loonArbeider;
                  isPloegbaas = false;
                }
                if(isPloegbaas){
                  totaalMinutenPloegbaas += (werk.uren * 60) + werk.minuten;
                } else {
                  totaalMinutenArbeiders += (werk.uren * 60) + werk.minuten;
                }

                allMachines[index].minuten += (werk.uren * 60) + werk.minuten;

                if(isPloegbaas){
                  totaalKostPloegbaas += (machinistUurloon/60) * ((werk.uren * 60) + werk.minuten);
                } else {
                  totaalKostArbeiders += (machinistUurloon/60) * ((werk.uren * 60) + werk.minuten);
                }
                totaalKostWerk += (machinistUurloon/60) * ((werk.uren * 60) + werk.minuten);
                if (machine.kostprijs != null) {
                  allMachines[index].kost += (machine.kostprijs/60) * ((werk.uren * 60) + werk.minuten);
                  totaalKostMachine += (machine.kostprijs/60) * ((werk.uren * 60) + werk.minuten);
                  totaalKostWerk += (machine.kostprijs/60) * ((werk.uren * 60) + werk.minuten);
                }
              }
            }
          }

          if(werk.handwerkers != null && werk.handwerkers.length !== 0) {
            for (let arbeider of werk.handwerkers) {
              let arbeiderUurloon;
              let isPloegbaas;
              if (arbeider.role === '59cf78e883680012b0438501') {
                isPloegbaas = true;
                arbeiderUurloon = loonPloegbaas;
              } else if (arbeider.role === '59cf78e883680012b0438504' || arbeider.role === '59cf78e883680012b0438502') {
                isPloegbaas = false;
                arbeiderUurloon = loonArbeider;
              }

              if(isPloegbaas){
                totaalMinutenPloegbaas += (werk.uren * 60) + werk.minuten;
              } else {
                totaalMinutenArbeiders += (werk.uren * 60) + werk.minuten;
              }

              if(isPloegbaas){
                  totaalKostPloegbaas += (arbeiderUurloon/60) * ((werk.uren * 60) + werk.minuten);
              } else {
                  totaalKostArbeiders += (arbeiderUurloon/60) * ((werk.uren * 60) + werk.minuten);
              }
              totaalKostWerk += (arbeiderUurloon/60) * ((werk.uren * 60) + werk.minuten);
            }
          }
          if(werk.onderaannemers != null && werk.onderaannemers.length !== 0) {
            for (let arbeider of werk.onderaannemers) {
              let arbeiderUurloon;
                arbeiderUurloon = loonArbeider;

                totaalMinutenOnderaannemers += (werk.uren * 60) + werk.minuten;
                totaalKostOnderaannemers += (arbeiderUurloon/60) * ((werk.uren * 60) + werk.minuten);
              totaalKostWerk += (arbeiderUurloon/60) * ((werk.uren * 60) + werk.minuten);
            }
          }
          if(werk.materialen != null && werk.materialen.length !== 0){
            for(let materiaal of werk.materialen){
              if(materiaal.standaardMateriaal != null && materiaal.standaardMateriaal.naam !== 'Geen'){
                if(materiaal.eenheid == null){
                  materiaal.eenheid = '';
                }
                let indexMateriaal = allMaterialen.findIndex(x => x.standaardMateriaal._id === materiaal.standaardMateriaal._id && x.eenheid === materiaal.eenheid);
                if (indexMateriaal == null || indexMateriaal === -1) {
                  allMaterialen.push({standaardMateriaal: materiaal.standaardMateriaal, eenheid: materiaal.eenheid, kost:0, hoeveelheid: 0})
                  indexMateriaal = allMaterialen.findIndex(x => x.standaardMateriaal._id === materiaal.standaardMateriaal._id && x.eenheid === materiaal.eenheid);
                }
                if(materiaal.hoeveelheid){
                  allMaterialen[indexMateriaal].hoeveelheid += materiaal.hoeveelheid;
                }
                allMaterialen[indexMateriaal].kost += totaalKostWerk;
              }
            }
          }
        }
      }

    worksheet.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      if (i === 0 || i === 3 || i === 4) {
        column.width = 30;
      } else if (i === 1 || i === 2) {
        column.width = 15;
      }
    });

    worksheet.addRow([''])
    worksheet.addRow([''])

    let titelRow2 = worksheet.addRow([werkzaamheid.naam]);
    titelRow2.font = {
      name: 'Arial',
      family: 4,
      size: 15,
      underline: 'double',
      bold: true,
    };
    titelRow2.height = 23;
    titelRow2.eachCell((cell, number) => {
      if(number < 5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: '92CDDC '},
          bgColor: {argb: '92CDDC '}
        };
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    if(subActiviteit !== '') {
      let titelRow3 = worksheet.addRow([subActiviteit]);
      titelRow3.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        underline: 'single',
        bold: true,
      };
      titelRow3.height = 23;
      titelRow3.eachCell((cell, number) => {
        if(number < 5){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: '92CDDC '},
            bgColor: {argb: '92CDDC '}
          };
          cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
        }
      });
    }
    worksheet.addRow([''])

    let urenTotaal = Math.floor((totaalMinutenPloegbaas + totaalMinutenArbeiders) / 60);
    let minutenTotaal = (totaalMinutenPloegbaas + totaalMinutenArbeiders) % 60;
    let totaalKost = totaalKostPloegbaas + totaalKostArbeiders;
    let totaalArbeiderRow = worksheet.addRow(['Totale arbeiderskosten:', urenTotaal + 'u', minutenTotaal + 'min', '€' + totaalKost?.toFixed(2)]);
    totaalArbeiderRow.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      bold: true,
    };
    totaalArbeiderRow.height = 23;
    totaalArbeiderRow.eachCell((cell, number) => {
      if(number < 5){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'B7DEE8 '},
          bgColor: {argb: 'B7DEE8 '}
        };
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    worksheet.addRow([''])

    let urenTotaalPloegbaas = Math.floor(totaalMinutenPloegbaas / 60);
    let minutenTotaalPloegbaas = totaalMinutenPloegbaas % 60;
    let ploegbaasRow = worksheet.addRow(['Ploegbaas:', urenTotaalPloegbaas + 'u', minutenTotaalPloegbaas + 'min', '€ ' + loonPloegbaas?.toFixed(2) + " per uur", '€' + totaalKostPloegbaas?.toFixed(2)]);
    ploegbaasRow.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      bold: true,
    };
    ploegbaasRow.height = 23;
    ploegbaasRow.eachCell((cell, number) => {
      if(number < 6){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'B7DEE8 '},
          bgColor: {argb: 'B7DEE8 '}
        };
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    let urenTotaalArbeider = Math.floor(totaalMinutenArbeiders / 60);
    let minutenTotaalArbeider = totaalMinutenArbeiders % 60;
    let arbeiderRow = worksheet.addRow(['Arbeider:',urenTotaalArbeider + 'u', minutenTotaalArbeider + 'min', '€ ' + loonArbeider?.toFixed(2) + " per uur", '€' + totaalKostArbeiders?.toFixed(2)]);
    arbeiderRow.font = {
      name: 'Arial',
      family: 4,
      size: 11,
      bold: true,
    };
    arbeiderRow.height = 23;
    arbeiderRow.eachCell((cell, number) => {
      if(number < 6){
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: 'B7DEE8 '},
          bgColor: {argb: 'B7DEE8 '}
        };
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    worksheet.addRow([''])
    worksheet.addRow([''])
    if(allMachines.length > 0){
      let titelRow4 = worksheet.addRow(['Machines']);
      titelRow4.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        underline: 'double',
        bold: true,
      };
      titelRow4.height = 23;


      let totaalMinutenMachines = 0;
       allMachines.forEach(x => totaalMinutenMachines += x.minuten);
       let totaalKostMachines = 0;
      allMachines.forEach(x => totaalKostMachines += x.kost);
      let urenTotaal = Math.floor(totaalMinutenMachines / 60);
      let minutenTotaal = totaalMinutenMachines % 60;
      let totaalArbeiderRow = worksheet.addRow(['Totale machinekosten:', urenTotaal + 'u', minutenTotaal + 'min', '€' + totaalKostMachines?.toFixed(2)]);
      totaalArbeiderRow.font = {
        name: 'Arial',
        family: 4,
        size: 11,
        bold: true,
      };
      totaalArbeiderRow.height = 23;
      totaalArbeiderRow.eachCell((cell, number) => {
        if(number < 5){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D8E4BC '},
            bgColor: {argb: 'D8E4BC '}
          };
          cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
        }
      });

      worksheet.addRow([''])

      for(let machine of allMachines){
        if(machine.minuten != null && machine.minuten !== 0){
          let uren = Math.floor(machine.minuten / 60);
          let minuten = machine.minuten % 60;

          let totaalRow = worksheet.addRow([machine.machine.naam, uren + 'u', minuten + 'min','€ ' + machine.machine.kostprijs?.toFixed(2) + " per uur", '€' + machine.kost?.toFixed(2)]);
          totaalRow.font = {
            name: 'Arial',
            family: 4,
            size: 11,
            bold: true,
          };
          totaalRow.height = 23;
          totaalRow.eachCell((cell, number) => {
            if(number < 6){
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'D8E4BC '},
                bgColor: {argb: 'D8E4BC '}
              };
              cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
            }
          });
        }
      }
    }
    worksheet.addRow([''])
    worksheet.addRow([''])
    if(allMaterialen.length > 0) {
      let titelRow5 = worksheet.addRow(['Kost per verbruikt materiaal']);
      titelRow5.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        underline: 'double',
        bold: true,
      };
      titelRow5.height = 23;
      for (let materiaal of allMaterialen) {
        let kostPerMateriaalGewicht = '€ ' + (materiaal.kost / materiaal.hoeveelheid)?.toFixed(2);
        let totaalRow = worksheet.addRow([materiaal.standaardMateriaal.naam, materiaal.hoeveelheid + ' ' + materiaal.eenheid,
          'Kost per ' + materiaal.eenheid + ':', kostPerMateriaalGewicht]);
        totaalRow.font = {
          name: 'Arial',
          family: 4,
          size: 11,
          bold: true,
        };
        totaalRow.height = 23;
        totaalRow.eachCell((cell, number) => {
          if(number < 5){
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {argb: 'add8e6 '},
              bgColor: {argb: 'add8e6 '}
            };
            cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
          }
        });
      }
    }
      if(subActiviteit !== ''){
        subActiviteit  = ' - ' + subActiviteit;
      } else {
        subActiviteit = '';
      }
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, werkzaamheid.naam + subActiviteit + ' kostenberekening' + ".xlsx");
      });
  }































  generateExcelDagrapportEnTotalen( headers: string[], dataList: Dagrapport[], transportDagrapporten: Transportdagrapport[], logoURL: string, companyName: string,vroegsteDatumWerf: Date,laatsteDatumWerf: Date, date1, date2, chosenWerfNaam,company, fileName) {
    let workbook = new Workbook();

    let worksheet = workbook.addWorksheet('Dagrapporten + kosten', {
      views: [{state: "frozen", ySplit: 5}],
    });
    worksheet.properties.tabColor = { argb: '99ccff' };
    // Add new row
    let titleRow = worksheet.addRow(['Dagrapporten + kosten']);
    titleRow.font = {
      name: 'Arial',
      family: 4,
      size: 14,
      underline: 'double',
      bold: true,

    };
    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('F1:H1');
    titleRow.height = 23;
    titleRow.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
    titleRow.getCell('A').border = {bottom: {style: 'medium'}, right: {style: 'medium'}, left: {style: 'medium'}, top : {style: 'medium'}};
    titleRow.getCell('A').alignment = { vertical: 'middle', horizontal: 'center' };

    titleRow.getCell('F').value = companyName;

    if(dataList[0]?.date == null){
      dataList[0] = new Dagrapport();
      dataList[0].date = new Date(transportDagrapporten[0].date);
    } else {
      dataList[0].date = new Date(dataList[0].date);
    }
    if(chosenWerfNaam !== 'geen'){
      let locationRow2 = worksheet.addRow([
        'Werf: ' + chosenWerfNaam
      ]);
      locationRow2.font = this.fontSize(13)
      locationRow2.height = 20;
      locationRow2.getCell('A').alignment = { vertical: 'middle', horizontal: 'center' };
      locationRow2.getCell('A').border = { right: {style: 'thin'}};
      }

    let dateRow = worksheet.addRow([
      'Startdatum: ' + ("0" + (vroegsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (vroegsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      vroegsteDatumWerf.getFullYear().toString().slice(-2) + ' - Einddatum: ' + ("0" + (laatsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (laatsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      laatsteDatumWerf.getFullYear().toString().slice(-2)
    ]);
    dateRow.font = {
      name: 'Arial',
      family: 4,
      size: 13
    };

    dateRow.height = 20;
    if(chosenWerfNaam !== 'Geen'){
      worksheet.mergeCells('A2:C2');
      worksheet.mergeCells('A3:C3');
    } else {
      worksheet.mergeCells('A3:C3');
    }
    dateRow.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFAF' } };
    dateRow.getCell('A').border = {bottom: {style: 'thin'}, right: {style: 'thin'},  left: {style: 'thin'}, top : {style: 'thin'}};
    if(chosenWerfNaam === 'Geen'){
      worksheet.addRow(['']);
    }
    let emptyRow = worksheet.addRow([''
    ]);
    let allActiviteiten = [];
    let allSubactiviteiten = [];
    let allWerkers = [];
    let allMateriaalAantallen = [];
    let allChauffeurMateriaalAantallen = [];
    let totaalSchaft = 0;
    let dagrapportWerkersWerkUitgevoerd = [];
    let machinesWerkUitgevoerd = [];

    let headerRow = worksheet.addRow(headers);
    headerRow.font = {
      name: 'Arial',
      family: 4,
      size: 13,
      bold: true,
      color: {argb: '0000be'},
    };
    headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6F7FF' } };
    headerRow.height = 18;
    headerRow.border = {bottom: {style: 'medium'}, top: {style: 'thin'}};

    for (let dagrapport of dataList) {
      if (dagrapport.schaft != null && dagrapport.schaft !== 0) {
        // Get total minutes for current werk

        // Sort werken in descending order of total minutes
        dagrapport.werken = dagrapport.werken.sort((a, b) => (b.uren * 60 + b.minuten) - (a.uren * 60 + a.minuten));


        if(dagrapport.werken[0] != null) {
          dagrapport.werken[0].schaft = dagrapport.schaft;
          // Subtract schaft from highestWerk
          if (dagrapport.werken[0].minuten < dagrapport.schaft) {
            dagrapport.werken[0].minuten = dagrapport.werken[0].minuten + 60 - dagrapport.schaft;
            dagrapport.werken[0].uren = dagrapport.werken[0].uren - 1;
          } else {
            dagrapport.werken[0].minuten = dagrapport.werken[0].minuten - dagrapport.schaft;
          }
        }
      }
      for(let werk of dagrapport.werken) {
        if(werk.werkzaamheid != null){

        if (werk.werkzaamheid?.naam === 'Andere') {
          werk.subActiviteit = werk.anderWerk;
        }
        if (werk.werkzaamheid?.naam === 'Meerwerk') {
          werk.subActiviteit = werk.meerWerk;
        }
        let index = allActiviteiten.findIndex(x => x.werkzaamheid._id === werk.werkzaamheid?._id);
        if ((index == null || index === -1) && werk.werkzaamheid != null) {
          allActiviteiten.push({
            werkzaamheid: werk.werkzaamheid,
            materialen: [],
            minuten: 0,
            arbeidersKosten: 0,
            totaleKosten: 0,
            hoeveelheid: 0,
            machines: []
          })
          index = allActiviteiten.findIndex(x => x.werkzaamheid._id === werk.werkzaamheid?._id);
        }

        if (werk.materialen != null && werk.materialen.length !== 0) {
          for (let materiaal of werk.materialen) {
            if (materiaal.standaardMateriaal != null && materiaal.standaardMateriaal.naam !== 'Geen') {
              if (materiaal.eenheid == null) {
                materiaal.eenheid = '';
              }
              let indexMateriaal = allActiviteiten[index].materialen?.findIndex(x => x.standaardMateriaal?._id === materiaal.standaardMateriaal?._id && x.eenheid === materiaal.eenheid);
              if (indexMateriaal == null || indexMateriaal === -1) {
                allActiviteiten[index].materialen.push({
                  standaardMateriaal: materiaal.standaardMateriaal,
                  eenheid: materiaal.eenheid,
                  kost: 0,
                  hoeveelheid: 0
                })
                indexMateriaal = allActiviteiten[index].materialen?.findIndex(x => x.standaardMateriaal?._id === materiaal.standaardMateriaal?._id && x.eenheid === materiaal.eenheid);
              }
              let indexMateriaalAantallen = allMateriaalAantallen.findIndex(x => x.naam === materiaal.standaardMateriaal?.naam && x.eenheid === materiaal.eenheid);
              if (indexMateriaalAantallen == null || indexMateriaalAantallen === -1) {
                allMateriaalAantallen.push({
                  naam: materiaal.standaardMateriaal?.naam,
                  eenheid: materiaal.eenheid,
                  kost: 0,
                  hoeveelheid: 0
                })
                indexMateriaalAantallen = allMateriaalAantallen.findIndex(x => x.naam === materiaal.standaardMateriaal?.naam && x.eenheid === materiaal.eenheid);
              }
              if (materiaal.hoeveelheid) {
                allActiviteiten[index].materialen[indexMateriaal].hoeveelheid += materiaal.hoeveelheid;
                allMateriaalAantallen[indexMateriaalAantallen].hoeveelheid += materiaal.hoeveelheid;
              }
            }
          }
        }
          if (werk.newMaterialen != null && werk.newMaterialen.length !== 0) {
            for (let materiaal of werk.newMaterialen) {
              if (materiaal.materiaal != null && materiaal.materiaal !== 'Geen') {
                if (materiaal.eenheid == null) {
                  materiaal.eenheid = '';
                }
                let indexMateriaal = allActiviteiten[index].materialen?.findIndex(x => x.materiaalNaam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                if (indexMateriaal == null || indexMateriaal === -1) {
                  allActiviteiten[index].materialen.push({
                    standaardMateriaal: null,
                    eenheid: materiaal.eenheid,
                    kost: 0,
                    hoeveelheid: 0,
                    materiaalNaam: materiaal.materiaal
                  })
                  indexMateriaal = allActiviteiten[index].materialen?.findIndex(x => x.materiaalNaam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                }
                let indexMateriaalAantallen = allMateriaalAantallen.findIndex(x => x.naam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                if (indexMateriaalAantallen == null || indexMateriaalAantallen === -1) {
                  allMateriaalAantallen.push({
                    naam: materiaal.materiaal,
                    eenheid: materiaal.eenheid,
                    kost: 0,
                    hoeveelheid: 0
                  })
                  indexMateriaalAantallen = allMateriaalAantallen.findIndex(x => x.naam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                }
                if (materiaal.hoeveelheid) {
                  allActiviteiten[index].materialen[indexMateriaal].hoeveelheid += materiaal.hoeveelheid;
                  allMateriaalAantallen[indexMateriaalAantallen].hoeveelheid += materiaal.hoeveelheid;
                }
              }
            }
          }

        let indexSubactiviteit;
        if (werk.subActiviteit != null && werk.subActiviteit !== '') {
          indexSubactiviteit = allSubactiviteiten.findIndex(x => x.werkzaamheid._id === werk.werkzaamheid._id && x.subActiviteit === werk.subActiviteit);
          if (indexSubactiviteit == null || indexSubactiviteit === -1) {
            allSubactiviteiten.push({
              werkzaamheid: werk.werkzaamheid,
              subActiviteit: werk.subActiviteit,
              materialen: [],
              minuten: 0,
              arbeidersKosten: 0,
              totaleKosten: 0,
              hoeveelheid: 0,
              machines: []
            })
            indexSubactiviteit = allSubactiviteiten.findIndex(x => x.werkzaamheid._id === werk.werkzaamheid._id && x.subActiviteit === werk.subActiviteit);
          }

          if (werk.materialen != null && werk.materialen.length !== 0) {
            for (let materiaal of werk.materialen) {
              if (materiaal.standaardMateriaal != null && materiaal.standaardMateriaal.naam !== 'Geen') {
                if (materiaal.eenheid == null) {
                  materiaal.eenheid = '';
                }
                let indexMateriaal = allSubactiviteiten[indexSubactiviteit].materialen?.findIndex(x => x.standaardMateriaal?._id === materiaal.standaardMateriaal?._id && x.eenheid === materiaal.eenheid);
                if (indexMateriaal == null || indexMateriaal === -1) {
                  allSubactiviteiten[indexSubactiviteit].materialen.push({
                    standaardMateriaal: materiaal.standaardMateriaal,
                    eenheid: materiaal.eenheid,
                    kost: 0,
                    hoeveelheid: 0
                  })
                  indexMateriaal = allSubactiviteiten[indexSubactiviteit].materialen?.findIndex(x => x.standaardMateriaal?._id === materiaal.standaardMateriaal?._id && x.eenheid === materiaal.eenheid);
                }
                if (materiaal.hoeveelheid) {
                  allSubactiviteiten[indexSubactiviteit].materialen[indexMateriaal].hoeveelheid += materiaal.hoeveelheid;
                }
              }
            }
          }
          if (werk.newMaterialen != null && werk.newMaterialen.length !== 0) {
            for (let materiaal of werk.newMaterialen) {
              if (materiaal.materiaal != null && materiaal.materiaal !== 'Geen') {
                if (materiaal.eenheid == null) {
                  materiaal.eenheid = '';
                }
                let indexMateriaal = allSubactiviteiten[indexSubactiviteit].materialen?.findIndex(x => x.materiaalNaam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                if (indexMateriaal == null || indexMateriaal === -1) {
                  allSubactiviteiten[indexSubactiviteit].materialen.push({
                    standaardMateriaal: null,
                    eenheid: materiaal.eenheid,
                    kost: 0,
                    hoeveelheid: 0,
                    materiaalNaam: materiaal.materiaal
                  })
                  indexMateriaal = allSubactiviteiten[indexSubactiviteit].materialen?.findIndex(x => x.materiaalNaam === materiaal.materiaal && x.eenheid === materiaal.eenheid);
                }
                if (materiaal.hoeveelheid) {
                  allSubactiviteiten[indexSubactiviteit].materialen[indexMateriaal].hoeveelheid += materiaal.hoeveelheid;
                }
              }
            }
          }

        }
        //materialen verzamelen

        let allMaterialen = '';
        if (werk.materialen != null) {
          for (let materiaal of werk.materialen) {
            if (materiaal.standaardMateriaal != null && materiaal.standaardMateriaal.naam !== 'Geen' && materiaal.hoeveelheid != null && materiaal.hoeveelheid !== 0) {
              if (materiaal.eenheid == null) {
                materiaal.eenheid = '';
              }
              allMaterialen += materiaal.hoeveelheid + ' ' + materiaal.eenheid + ' ' + materiaal.standaardMateriaal.naam + '\n';
            }
          }
        }
        if (werk.newMaterialen != null) {
          for (let materiaal of werk.newMaterialen) {
            if (materiaal.materiaal != null && materiaal.materiaal !== '' && materiaal.hoeveelheid != null && materiaal.hoeveelheid !== 0) {
              if (materiaal.eenheid == null) {
                materiaal.eenheid = '';
              }
              allMaterialen += materiaal.hoeveelheid + ' ' + materiaal.eenheid + ' ' + materiaal.materiaal + '\n';
            }
          }
        }
        if (werk.machinisten) {
          for (let i = 0; i < werk.machinisten.length; i++) {

            let machinist = werk.machinisten[i];
            let machine = werk.machines[i];
            let index2 = allWerkers.findIndex(x => x.handwerker._id === machinist._id);
            if (index2 == null || index2 === -1) {
              allWerkers.push({handwerker: machinist, minuten: 0, kost: 0});
              index2 = allWerkers.findIndex(x => x.handwerker._id === machinist._id);
            }
            let indexMachine = allActiviteiten[index].machines?.findIndex(x => x.machine?._id === machine?._id);
            if (indexMachine == null || indexMachine === -1) {
              allActiviteiten[index].machines.push({machine: machine, minuten: 0, kost: 0});
              indexMachine = allActiviteiten[index].machines?.findIndex(x => x.machine?._id === machine._id);
            }
            let indexMachineSubActiviteit;
            if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
              indexMachineSubActiviteit = allSubactiviteiten[indexSubactiviteit].machines?.findIndex(x => x.machine?._id === machine?._id);
              if (indexMachineSubActiviteit == null || indexMachineSubActiviteit === -1 && indexSubactiviteit != null && indexSubactiviteit !== -1) {
                allSubactiviteiten[indexSubactiviteit].machines.push({machine: machine, minuten: 0, kost: 0});
                indexMachineSubActiviteit = allSubactiviteiten[indexSubactiviteit].machines?.findIndex(x => x.machine?._id === machine?._id);
              }
            }

            let index4;
            if (chosenWerfNaam !== 'geen') {
              index4 = machinesWerkUitgevoerd.findIndex(x => x.machine._id === machine._id && x.machinist._id === machinist._id && x.activiteit === werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              if (index4 == null || index4 === -1) {
                machinesWerkUitgevoerd.push({
                  machine: machine,
                  opmerking: werk.opmerking,
                  activiteit: werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                  minuten: 0,
                  kost: 0,
                  machinist: machinist,
                  date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                  materialen: allMaterialen,
                  role: machinist.role
                });
                index4 = machinesWerkUitgevoerd.findIndex(x => x.machine._id === machine._id && x.machinist._id === machinist._id && x.activiteit === werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                machinesWerkUitgevoerd[index4].uurLoon = machine.kostprijs;
              }
            } else {
              index4 = machinesWerkUitgevoerd.findIndex(x => x.werf?._id === dagrapport.werf?._id && x.machine._id === machine._id && x.machinist._id === machinist._id && x.activiteit === werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              if (index4 == null || index4 === -1) {
                machinesWerkUitgevoerd.push({
                  werf: dagrapport.werf,
                  machine: machine,
                  opmerking: werk.opmerking,
                  activiteit: werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                  minuten: 0,
                  kost: 0,
                  machinist: machinist,
                  date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                  materialen: allMaterialen,
                  role: machinist.role
                });
                index4 = machinesWerkUitgevoerd.findIndex(x => x.werf?._id === dagrapport.werf?._id && x.machine._id === machine._id && x.machinist._id === machinist._id && x.activiteit === werk.werkzaamheid.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                machinesWerkUitgevoerd[index4].uurLoon = machine.kostprijs;
              }
            }
            let totaleMinuten = (werk.uren * 60) + werk.minuten;

            allActiviteiten[index].minuten += totaleMinuten
            allActiviteiten[index].machines[indexMachine].minuten += totaleMinuten
            machinesWerkUitgevoerd[index4].minuten += totaleMinuten
            allWerkers[index2].minuten += totaleMinuten
            if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
              allSubactiviteiten[indexSubactiviteit].minuten += totaleMinuten
              allSubactiviteiten[indexSubactiviteit].machines[indexMachineSubActiviteit].minuten += totaleMinuten
            }

            if (machine?.kostprijs != null) {
              let machineKostPrijs = (machine.kostprijs / 60) * ((werk.uren * 60) + werk.minuten);
              allActiviteiten[index].machines[indexMachine].kost += machineKostPrijs;
              allActiviteiten[index].totaleKosten += machineKostPrijs;
              machinesWerkUitgevoerd[index4].kost += machineKostPrijs;
              allWerkers[index2].kost += machineKostPrijs;
              if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
                allSubactiviteiten[indexSubactiviteit].machines[indexMachineSubActiviteit].kost += machineKostPrijs;
                allSubactiviteiten[indexSubactiviteit].totaleKosten += machineKostPrijs;
              }
            } else {
              //this.toastBadFormMachineKost(machine.naam);
              //return;
            }
          }
        }


        if (werk.handwerkers) {
          for (let arbeider of werk.handwerkers) {
            let index2 = allWerkers.findIndex(x => x.handwerker._id === arbeider._id);
            if (index2 == null || index2 === -1) {
              allWerkers.push({handwerker: arbeider, minuten: 0, kost: 0});
              index2 = allWerkers.findIndex(x => x.handwerker._id === arbeider._id);
            }
            let index3;
            if (chosenWerfNaam !== 'geen') {
              index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              if (index3 == null || index3 === -1) {
                dagrapportWerkersWerkUitgevoerd.push({
                  handwerker: arbeider,
                  opmerking: werk.opmerking,
                  minuten: 0,
                  kost: 0,
                  machines: [],
                  activiteit: werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                  date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                  materialen: allMaterialen,
                  role: arbeider.role
                });
                index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              }
            } else {
              index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.werf._id === dagrapport.werf._id && x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              if (index3 == null || index3 === -1) {
                dagrapportWerkersWerkUitgevoerd.push({
                  werf: dagrapport.werf,
                  handwerker: arbeider,
                  opmerking: werk.opmerking,
                  minuten: 0,
                  kost: 0,
                  machines: [],
                  activiteit: werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                  date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                  materialen: allMaterialen,
                  role: arbeider.role
                });
                index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.werf._id === dagrapport.werf._id && x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
              }
            }
            dagrapportWerkersWerkUitgevoerd[index3].role = arbeider.role;
            if (arbeider.role === '59cf78e883680012b0438501') {
              dagrapportWerkersWerkUitgevoerd[index3].uurLoon = dagrapport.werf.uurLoonPloegbaas;
            } else if (arbeider.role === '59cf78e883680012b0438504' || arbeider.role === '59cf78e883680012b0438502') {
              dagrapportWerkersWerkUitgevoerd[index3].uurLoon = dagrapport.werf.uurLoonArbeider;
            }
            let totaleMinuten = (werk.uren * 60) + werk.minuten;
            dagrapportWerkersWerkUitgevoerd[index3].minuten += totaleMinuten;
            allWerkers[index2].minuten += totaleMinuten;
            allActiviteiten[index].minuten += totaleMinuten;
            if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
              allSubactiviteiten[indexSubactiviteit].minuten += totaleMinuten;
            }
            if(dagrapportWerkersWerkUitgevoerd[index3].uurLoon != null){
              let arbeiderKost = (dagrapportWerkersWerkUitgevoerd[index3].uurLoon / 60) * ((werk.uren * 60) + werk.minuten);
              allActiviteiten[index].arbeidersKosten += arbeiderKost;
              allActiviteiten[index].totaleKosten += arbeiderKost;
              allWerkers[index2].kost += arbeiderKost;
              dagrapportWerkersWerkUitgevoerd[index3].kost += arbeiderKost;
              if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
                allSubactiviteiten[indexSubactiviteit].arbeidersKosten += arbeiderKost;
                allSubactiviteiten[indexSubactiviteit].totaleKosten += arbeiderKost;
              }
            }
          }
        }



          if (werk.onderaannemers) {
            for (let arbeider of werk.onderaannemers) {
              let index2 = allWerkers.findIndex(x => x.handwerker._id === arbeider._id);
              if (index2 == null || index2 === -1) {
                allWerkers.push({handwerker: arbeider, minuten: 0, kost: 0, firmaNaam: arbeider.firmaNaam});
                index2 = allWerkers.findIndex(x => x.handwerker._id === arbeider._id);
              }
              let index3;
              if (chosenWerfNaam !== 'geen') {
                index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                if (index3 == null || index3 === -1) {
                  dagrapportWerkersWerkUitgevoerd.push({
                    handwerker: arbeider,
                    opmerking: werk.opmerking,
                    minuten: 0,
                    kost: 0,
                    machines: [],
                    activiteit: werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                    date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                    materialen: allMaterialen,
                    role: arbeider.role,
                    firmaNaam: arbeider.firmaNaam
                  });
                  index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                    x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                }
              } else {
                index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.werf._id === dagrapport.werf._id && x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                  x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                if (index3 == null || index3 === -1) {
                  dagrapportWerkersWerkUitgevoerd.push({
                    werf: dagrapport.werf,
                    handwerker: arbeider,
                    opmerking: werk.opmerking,
                    minuten: 0,
                    kost: 0,
                    machines: [],
                    activiteit: werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : ''),
                    date: new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0),
                    materialen: allMaterialen,
                    role: arbeider.role,
                    firmaNaam: arbeider.firmaNaam
                  });
                  index3 = dagrapportWerkersWerkUitgevoerd.findIndex(x => x.werf._id === dagrapport.werf._id && x.handwerker._id === arbeider._id && x.activiteit === werk.werkzaamheid?.naam + (werk.subActiviteit != null ? ' - ' + werk.subActiviteit : '') &&
                    x.date.getTime() === new Date(dagrapport.date.getFullYear(), dagrapport.date.getMonth(), dagrapport.date.getDate(), 0, 0, 0, 0).getTime() && x.materialen === allMaterialen && x.opmerking === werk.opmerking);
                }
              }
              dagrapportWerkersWerkUitgevoerd[index3].role = arbeider.role;
              dagrapportWerkersWerkUitgevoerd[index3].uurLoon = 0;

              dagrapportWerkersWerkUitgevoerd[index3].minuten += (werk.uren * 60) + werk.minuten;
              allWerkers[index2].minuten += (werk.uren * 60) + werk.minuten;
              allActiviteiten[index].minuten += (werk.uren * 60) + werk.minuten;
              if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
                allSubactiviteiten[indexSubactiviteit].minuten += (werk.uren * 60) + werk.minuten;
              }
            /*
             if(dagrapportWerkersWerkUitgevoerd[index3].uurLoon != null){
                let arbeiderCost = (dagrapportWerkersWerkUitgevoerd[index3].uurLoon / 60) * ((werk.uren * 60) + werk.minuten);
                allActiviteiten[index].kost += arbeiderCost;
                allWerkers[index2].kost += arbeiderCost;
                dagrapportWerkersWerkUitgevoerd[index3].kost += arbeiderCost;
                if (indexSubactiviteit != null && indexSubactiviteit !== -1) {
                  allSubactiviteiten[indexSubactiviteit].kost += arbeiderCost;
                }
              }*/
            }
          }
      }
      }
    }

    //transport berekeningen
    let transportWerkenUitgevoerd = [];
    for(let transportdagrapport of transportDagrapporten){
      for(let transportwerk of transportdagrapport.transportwerken){
        if(transportwerk.uren == null || transportwerk.minuten == null){
          const startDate = new Date(transportwerk.dateLaadplaats);
          const endDate = new Date(transportwerk.dateLosplaats);

          if(startDate.getMinutes() > endDate.getMinutes() ){
            transportwerk.minuten = endDate.getMinutes() + 60 - startDate.getMinutes();
            if(transportwerk.minuten > 60){
              transportwerk.minuten = transportwerk.minuten - 60;
            }
            transportwerk.uren = endDate.getHours() - startDate.getHours() - 1;
          } else {
            transportwerk.minuten = endDate.getMinutes() - startDate.getMinutes() ;
            transportwerk.uren = endDate.getHours() - startDate.getHours();
          }
        }
        let index2 = allWerkers.findIndex(x => x.handwerker._id === transportwerk.user_id?._id);
        if (index2 == null || index2 === -1) {
          if(transportwerk.user_id == null) transportwerk.user_id = new User('Onbekend', 'onbekend');
          allWerkers.push({handwerker: transportwerk.user_id, minuten: 0, kost: 0});
          index2 = allWerkers.findIndex(x => x.handwerker._id === transportwerk.user_id?._id);
        }
        let richting = '';
        if(transportwerk.losplaats?._id !== '62c2625220243f679e1b96b0' && transportwerk.losplaats?._id === transportwerk.werf._id){
          richting = 'Aanvoer';
        } else if(transportwerk.laadplaats?._id !== '62c2625220243f679e1b96b0' && transportwerk.laadplaats?._id === transportwerk.werf._id){
          richting = 'Afvoer';
        } else {
          richting = 'Verplaatsing';
        }

        let transportMaterialen = [];
        if (transportwerk.materialen != null && transportwerk.materialen.length !== 0) {
          for(let materiaal of transportwerk.materialen){
            if (materiaal.materiaal !== 'Geen' && materiaal.hoeveelheid != null &&  materiaal.hoeveelheid !== 0) {
              if (materiaal.materiaalGewicht == null) {
                materiaal.materiaalGewicht = '';
              }
                let transportMateriaal = new Materiaal(materiaal.materiaal, '0');
                transportMateriaal.aantal = materiaal.hoeveelheid == null ? 0 : materiaal.hoeveelheid;
                transportMateriaal.eenheid =   materiaal.materiaalGewicht;
                transportMateriaal.richting = richting;
                transportMaterialen.push(transportMateriaal);

                let indexMateriaalAantallen = allChauffeurMateriaalAantallen.findIndex(x => x.naam === materiaal.materiaal && x.eenheid === materiaal.materiaalGewicht);
                if (indexMateriaalAantallen == null || indexMateriaalAantallen === -1) {
                  allChauffeurMateriaalAantallen.push({
                    naam: materiaal.materiaal,
                    eenheid: materiaal.materiaalGewicht,
                    hoeveelheid: 0
                  })
                  indexMateriaalAantallen = allChauffeurMateriaalAantallen.findIndex(x => x.naam === materiaal.materiaal && x.eenheid === materiaal.materiaalGewicht);
                }
               allChauffeurMateriaalAantallen[indexMateriaalAantallen].hoeveelheid += materiaal.hoeveelheid;
            }
          }
        }
        let transportMachine;
        if (transportwerk.machine != null) {
          transportMachine = new Machine(transportwerk.machine?.naam, transportwerk.machine._id);
          transportMachine.richting = richting;
          transportMachine.aantal = 1;
        }
        let transportMachine2;
        if (transportwerk.machine2 != null) {
          transportMachine2 = new Machine(transportwerk.machine2?.naam, transportwerk.machine2._id);
          transportMachine2.richting = richting;
          transportMachine2.aantal = 1;
        }
        allWerkers[index2].minuten += (transportwerk.uren * 60) + transportwerk.minuten;
        let index = transportWerkenUitgevoerd.findIndex(x => x.werf._id === transportwerk.werf?._id && x.user_id?._id === transportwerk.user_id?._id &&
            x.date.getTime() === new Date(transportdagrapport.date.getFullYear(), transportdagrapport.date.getMonth(), transportdagrapport.date.getDate()).getTime());
          if (index == null || index === -1) {
            transportWerkenUitgevoerd.push({
              werf: transportwerk.werf,
              minuten: 0,
              kost: 0,
              date: new Date(transportdagrapport.date.getFullYear(), transportdagrapport.date.getMonth(), transportdagrapport.date.getDate(), 0, 0, 0, 0),
              transportMaterialen: [],
              machines: [],
              user_id: transportwerk.user_id,
              role: '59cf78e883680012b0438502',
              opmerkingen: []
            });
            index = transportWerkenUitgevoerd.findIndex(x => x.werf._id === transportwerk.werf._id && x.user_id?._id === transportwerk.user_id?._id &&
              x.date.getTime() === new Date(transportdagrapport.date.getFullYear(), transportdagrapport.date.getMonth(), transportdagrapport.date.getDate()).getTime());
          }
        transportWerkenUitgevoerd[index].minuten += (transportwerk.uren * 60) + transportwerk.minuten;
          if(company?.uurLoonChauffeur != null){
            transportWerkenUitgevoerd[index].kost += (company?.uurLoonChauffeur/ 60) * ((transportwerk.uren * 60) + transportwerk.minuten);
            allWerkers[index2].kost += (company?.uurLoonChauffeur/ 60) * ((transportwerk.uren * 60) + transportwerk.minuten);
          }
        if(transportMaterialen != null && transportMaterialen.length !== 0){
          for(let materiaal of transportMaterialen){
            let findMateriaal = transportWerkenUitgevoerd[index].transportMaterialen?.findIndex(x => x.naam === materiaal.naam && x.eenheid === materiaal.eenheid && x.richting === materiaal.richting);
            if(findMateriaal == null || findMateriaal === -1){
              transportWerkenUitgevoerd[index].transportMaterialen.push(materiaal);
            } else {
              transportWerkenUitgevoerd[index].transportMaterialen[findMateriaal].aantal += materiaal.aantal;
            }
          }
        }
        if(transportMachine != null){
          let findMachine = transportWerkenUitgevoerd[index].machines?.findIndex(x => x._id === transportMachine._id && x.richting === richting);
          if(findMachine == null || findMachine === -1){
            transportWerkenUitgevoerd[index].machines.push(transportMachine);
          } else {
            transportWerkenUitgevoerd[index].machines[findMachine].aantal += transportMachine.aantal;
          }
        }
        if(transportMachine2 != null){
          let findMachine = transportWerkenUitgevoerd[index].machines?.findIndex(x => x._id === transportMachine2._id && x.richting === richting);
          if(findMachine == null || findMachine === -1){
            transportWerkenUitgevoerd[index].machines.push(transportMachine2);
          } else {
            transportWerkenUitgevoerd[index].machines[findMachine].aantal += transportMachine2.aantal;
          }
        }
        if(transportwerk.opmerking != null && transportwerk.opmerking !== ''){
          transportWerkenUitgevoerd[index].opmerkingen.push(transportwerk.opmerking);
        }
      }
    }
      let mergWerkers = [...machinesWerkUitgevoerd, ...dagrapportWerkersWerkUitgevoerd, ...transportWerkenUitgevoerd];

    if(chosenWerfNaam !== 'geen') {
      mergWerkers.sort(this.sortIndex);
    } else {
      mergWerkers.sort(this.sortIndexMetVerschillendeWerven);
    }
    let totaalKostArbeiders = 0;
    let totaalKostMachines = 0;
    let totaalKostChauffeurs = 0;
    let countSameDagrapport = 0;
    let isEvenRow = true;
      for(let rowCount  = 5; rowCount < mergWerkers.length + 5; rowCount++){
        let mergWerker = mergWerkers[rowCount - 5];
        if(mergWerker.machine != null){
          mergWerker.soort = mergWerker.machine?.naam;
          totaalKostMachines += mergWerker.kost;
        } else if (mergWerker.role === '59cf78e883680012b0438501') {
          if(mergWerker.handwerker.functieNaam === 'Onderaannemer'? mergWerker.soort = mergWerker.handwerker.functieNaam : mergWerker.soort = 'Ploegbaas')
          totaalKostArbeiders += mergWerker.kost;
        } else if (mergWerker.role === '59cf78e883680012b0438504') {
          totaalKostArbeiders += mergWerker.kost;
          mergWerker.soort = 'Arbeider';
        } else if (mergWerker.role === '59cf78e883680012b0438502') {
          mergWerker.soort = 'Chauffeur';
          totaalKostChauffeurs += mergWerker.kost;
        }
        let machinistRow;
        if(mergWerker.transportMaterialen == null){
          machinistRow = [
            ("0" + (mergWerker.date.getDate())).slice(-2) + '/' + ("0" + (mergWerker.date.getMonth() + 1)).slice(-2) + '/' + mergWerker.date.getFullYear(),
            mergWerker.machinist?.name == null? mergWerker.handwerker?.name : mergWerker.machinist.name,
            mergWerker.handwerker?.functieNaam !== 'Onderaannemer'? mergWerker.soort: 'OA ' + mergWerker.handwerker.firmaNaam,
            mergWerker.uurLoon == null ? 'onb.' : '€' + mergWerker.uurLoon,
            mergWerker.kost != null ? +mergWerker.kost?.toFixed(2): '',
            Math.floor(mergWerker.minuten / 60) + 'U' + (mergWerker.minuten % 60),
            mergWerker.activiteit,
            mergWerker.opmerking?.toString(),
            mergWerker.materialen?.toString()
          ];
        } else {
          let materialen = '';
          let opmerkingen = '';
          for(let materiaal of mergWerker.transportMaterialen){
            materialen += materiaal.richting + ' ' + materiaal.aantal + ' ' + materiaal.eenheid + ' ' + materiaal.naam + '\n';
          }
          for(let machines of mergWerker.machines){
            materialen += machines.richting + ' ' + machines.aantal + ' ' + machines.naam + '\n';
          }
          for(let opmerking of mergWerker.opmerkingen){
            opmerkingen += opmerking + '\n';
          }
          materialen = materialen.substring(0, materialen.length - 1);
          machinistRow = [
            ("0" + (mergWerker.date.getDate())).slice(-2) + '/' + ("0" + (mergWerker.date.getMonth() + 1)).slice(-2) + '/' + mergWerker.date.getFullYear(),
            mergWerker.user_id?.name,
            'Chauffeur',
            company.uurLoonChauffeur == null ? 'onb.' : '€' + company.uurLoonChauffeur,
            mergWerker.kost != null ? +mergWerker.kost?.toFixed(2): '',
            Math.floor(mergWerker.minuten / 60) + 'U' + (mergWerker.minuten % 60),
            materialen,
            opmerkingen,
            ''
          ];
        }
        //WERF VANVOOR INVOEGEN ALS GEEN WERF GEKOZEN
        if(chosenWerfNaam === 'geen'){
          machinistRow = [mergWerker.werf?.naam, ... machinistRow];
        }
        let realMachinistRow = worksheet.addRow(machinistRow);
        realMachinistRow.font = {
            name: 'Arial',
            family: 4,
            size: 10,
            bold: false,
          };
        realMachinistRow.height = 25;
        if(mergWerker.transportMaterialen != null && mergWerker.machines != null){
          let totaal = mergWerker.transportMaterialen?.length + mergWerker.machines?.length;
          if(totaal > 1){
              realMachinistRow.height = 22 + (12 * (mergWerker.transportMaterialen.length + mergWerker.machines.length)) ;
          } else {
              realMachinistRow.height = 37;
          }
        }
        //check for fill color ploeg ,sameenstelling en uren vakken
        if(rowCount > 5) {
          mergWerkers[rowCount - 6].date = new Date(mergWerkers[rowCount - 6].date);
          let thisDate = (mergWerker.date.getFullYear() * 1000) + (mergWerker.date.getMonth() * 50) + mergWerker.date.getDate();
          let previousDate = (mergWerkers[rowCount - 6].date.getFullYear() * 1000) + (mergWerkers[rowCount - 6].date.getMonth() * 50) + mergWerkers[rowCount - 6].date.getDate();
          if (thisDate !== previousDate || mergWerker.activiteit !== mergWerkers[rowCount - 6].activiteit || (chosenWerfNaam === "geen" && (mergWerker.werf?.naam !== mergWerkers[rowCount - 6].werf?.naam)) ||
            !isEqual(mergWerker.materialen, mergWerkers[rowCount - 6].materialen) ||
            !isEqual(mergWerker.opmerking, mergWerkers[rowCount - 6].opmerking)) {

          } else {
            if (!isEvenRow) {
              realMachinistRow.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'F9F9F9 '}};
            }
          }
        }
        if(rowCount > 5){
          if(chosenWerfNaam !== 'geen'){
            ['A','B','C','D','E','F', 'G', 'H', 'I'].forEach(letter => {
              if(countSameDagrapport !== 0){
                if(letter === 'B' || letter === 'C' || letter === 'D' || letter === 'E' || letter === 'F'){
                  worksheet.getCell(letter + (rowCount)).border = { left: {style:'thin'}, right: {style:'thin'} };
                } else {
                  worksheet.getCell(letter + (rowCount)).border = { top: {style:'thin'} , bottom: {style:'thin'}, left: {style:'thin'}, right: {style:'thin'} };
                }
              } else {
                worksheet.getCell(letter + (rowCount)).border = { top: {style:'thin'} , left: {style:'thin'}, right: {style:'thin'} };
              }

            });
          } else {
            ['A','B','C','D','E','F', 'G', 'H', 'I', 'J'].forEach(letter => {
              if(countSameDagrapport !== 0){
                if(letter === 'C' || letter === 'D' || letter === 'E' || letter === 'F' || letter === 'G'){
                  worksheet.getCell(letter + (rowCount)).border = { left: {style:'thin'}, right: {style:'thin'} };
                } else {
                  worksheet.getCell(letter + (rowCount)).border = { top: {style:'thin'} , bottom: {style:'thin'}, left: {style:'thin'}, right: {style:'thin'} };
                }
              } else {
                worksheet.getCell(letter + (rowCount)).border = { top: {style:'thin'} , left: {style:'thin'}, right: {style:'thin'} };
              }

            });
          }
          mergWerkers[rowCount - 6].date = new Date(mergWerkers[rowCount - 6].date);
          let thisDate = (mergWerker.date.getFullYear() * 1000) + (mergWerker.date.getMonth() * 50) + mergWerker.date.getDate();
          let previousDate = (mergWerkers[rowCount - 6].date.getFullYear() * 1000) + (mergWerkers[rowCount - 6].date.getMonth() * 50) + mergWerkers[rowCount - 6].date.getDate();

          if(mergWerker.transportMaterialen != null || (thisDate !== previousDate || mergWerker.activiteit !== mergWerkers[rowCount - 6].activiteit ||(chosenWerfNaam === "geen" && (mergWerker.werf?.naam !== mergWerkers[rowCount - 6].werf?.naam)) ||
            !isEqual(mergWerker.materialen, mergWerkers[rowCount - 6].materialen) ||
            !isEqual(mergWerker.opmerking, mergWerkers[rowCount - 6].opmerking))){
            if(chosenWerfNaam !== 'geen'){
              worksheet.mergeCells('A' + (rowCount - countSameDagrapport) + ':A' + (rowCount));
              worksheet.mergeCells('G' + (rowCount - countSameDagrapport) + ':G' + (rowCount));
              worksheet.mergeCells('H' + (rowCount - countSameDagrapport) + ':H' + (rowCount));
              worksheet.mergeCells('I' + (rowCount - countSameDagrapport) + ':I' + (rowCount));
              ['A','B','C','D','E', 'F','G', 'H', 'I'].forEach(letter => {
                worksheet.getCell(letter + rowCount).border = { bottom: {style:'thin'}, left: {style:'thin'}, right: {style:'thin'} };
              });
            } else {
              worksheet.mergeCells('A' + (rowCount - countSameDagrapport) + ':A' + (rowCount));
              worksheet.mergeCells('B' + (rowCount - countSameDagrapport) + ':B' + (rowCount));
              worksheet.mergeCells('H' + (rowCount - countSameDagrapport) + ':H' + (rowCount));
              worksheet.mergeCells('I' + (rowCount - countSameDagrapport) + ':I' + (rowCount));
              worksheet.mergeCells('J' + (rowCount - countSameDagrapport) + ':J' + (rowCount));
              ['A','B','C','D','E', 'F','G', 'H', 'I', 'J'].forEach(letter => {
                worksheet.getCell(letter + rowCount).border = { bottom: {style:'thin'}, left: {style:'thin'}, right: {style:'thin'} };
              });

            }
            if(isEvenRow){
              realMachinistRow.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'F9F9F9 '}};
              isEvenRow = false;
            } else {
              isEvenRow = true;
            }
            countSameDagrapport = 0;
          } else if(rowCount !== mergWerkers.length + 4){
            countSameDagrapport++;
          } else {
            if(chosenWerfNaam !== 'geen'){
              worksheet.mergeCells('A' + (rowCount - countSameDagrapport) + ':A' + (rowCount + 1));
              worksheet.mergeCells('G' + (rowCount - countSameDagrapport) + ':G' + (rowCount + 1));
              worksheet.mergeCells('H' + (rowCount - countSameDagrapport) + ':H' + (rowCount + 1));
              worksheet.mergeCells('I' + (rowCount - countSameDagrapport) + ':I' + (rowCount + 1));
            } else {
              worksheet.mergeCells('A' + (rowCount - countSameDagrapport) + ':A' + (rowCount + 1));
              worksheet.mergeCells('B' + (rowCount - countSameDagrapport) + ':B' + (rowCount + 1));
              worksheet.mergeCells('H' + (rowCount - countSameDagrapport) + ':H' + (rowCount + 1));
              worksheet.mergeCells('I' + (rowCount - countSameDagrapport) + ':I' + (rowCount + 1));
              worksheet.mergeCells('J' + (rowCount - countSameDagrapport) + ':J' + (rowCount + 1));
            }
            countSameDagrapport = 0;
          }
        }
      }
    let totalRow = worksheet.addRow([""]);
    totalRow.font = this.fontSizeBold(13);
    totalRow.height = 20;
      if(chosenWerfNaam !== 'geen'){
        totalRow.getCell('D').value = 'Totaal:';
        worksheet.getCell('E' + (mergWerkers.length + 6)).value = { formula: '=SUM(E6:E' + (mergWerkers.length + 5) + ')', date1904: false };
        totalRow.getCell('F').value = '€';
      } else {
        totalRow.getCell('E').value = 'Totaal:';
        worksheet.getCell('F' + (mergWerkers.length + 6)).value = { formula: '=SUM(F6:F' + (mergWerkers.length + 5) + ')', date1904: false };
        totalRow.getCell('G').value = '€';
      }

    worksheet.columns.forEach(function (column, i) {
      if(chosenWerfNaam === 'geen'){
        if(i === 7 ||i === 8 || i === 9){
          column.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        } else {
          column.alignment = { horizontal: 'center', vertical: 'middle'};
        }
      } else {
        if(i === 6 ||i === 7 || i === 8){
          column.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        } else {
          column.alignment = { horizontal: 'center', vertical: 'middle'};
        }
      }
      if(chosenWerfNaam !== 'geen'){
        if(i === 0){
          column.width = 11;
        } else if(i === 1){
          column.width = 24;
        } else if(i === 2){
          column.width = 26;
        } else if(i === 3 || i === 4 || i === 5){
          column.width = 10;
        } else {
          column.width = 52;
        }
      } else {
        if(i === 0){
          column.width = 35;
        } else if(i === 1){
          column.width = 11;
        } else if(i === 2){
          column.width = 24;
        } else if(i === 3){
         column.width = 26;
        } else if(i === 4 || i === 5 || i === 6){
          column.width = 10;
        } else {
          column.width = 52;
        }
      }
    });













    //totaal pagina

    let worksheet2 = workbook.addWorksheet('Overzicht kosten & materialen', {
      views: [{state: "frozen", ySplit: 3}],
    });
    worksheet2.properties.tabColor = { argb: '99ccff' };
    // Add new row
    let titleRow2 = worksheet2.addRow(['Overzicht kosten & materialen']);
    titleRow2.font = {
      name: 'Arial',
      family: 4,
      size: 14,
      underline: 'double',
      bold: true,
    };
    titleRow2.height = 23;
    titleRow2.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
    titleRow2.getCell('A').border = {bottom: {style: 'medium'}, right: {style: 'medium'}, left: {style: 'medium'}, top : {style: 'medium'}};
    titleRow2.getCell('A').alignment = { horizontal: 'center', vertical: 'middle' };

    worksheet2.mergeCells('A1:B1');
    worksheet2.mergeCells('D1:H1');
    titleRow2.getCell('D').value = companyName;

    if(dataList[0]?.date == null){
      dataList[0] = new Dagrapport();
      dataList[0].date = new Date(transportDagrapporten[0].date);
    } else {
      dataList[0].date = new Date(dataList[0].date);
    }
    if(chosenWerfNaam !== 'geen'){
      let locationRow2 = worksheet2.addRow([
        'Werf: ' + chosenWerfNaam
      ]);
      locationRow2.font = this.fontSize(13)
      locationRow2.height = 20;
      locationRow2.getCell('A').alignment = { horizontal: 'center', vertical: 'middle' };
      locationRow2.getCell('A').border = { right: {style: 'thin'}};
    }

    let dateRow2 = worksheet2.addRow([
      'Startdatum: ' + ("0" + (vroegsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (vroegsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      vroegsteDatumWerf.getFullYear().toString().substr(-2) + ' - Einddatum: ' + ("0" + (laatsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (laatsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      laatsteDatumWerf.getFullYear().toString().substr(-2)
    ]);
    dateRow2.font = this.fontSize(13);
    dateRow2.height = 20;

    if(chosenWerfNaam !== 'geen'){
      worksheet2.mergeCells('A2:B2');
      worksheet2.mergeCells('A3:B3');
    } else {
      worksheet2.mergeCells('A2:B2');
    }
    dateRow2.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFAF' } };
    dateRow2.getCell('A').border = {bottom: {style: 'thin'}, right: {style: 'thin'},  left: {style: 'thin'}, top : {style: 'thin'}};
    dateRow2.getCell('A').alignment = { horizontal: 'center' };
    worksheet2.addRow(['']);

    let newTitelRow = worksheet2.addRow(['Totale kosten:']);
    newTitelRow.font = {
      name: 'Arial',
      family: 4,
      size: 15,
      underline: 'double',
      bold: true,
    };
    newTitelRow.height = 18;
    let totaalRow = worksheet2.addRow(['Kosten arbeiders:']);
    totaalRow.font = {
      name: 'Arial',
      family: 4,
      size: 14
    };
    totaalRow.height = 18;
    totaalRow.getCell('B').value = "€ " + totaalKostArbeiders?.toFixed(2);
    totaalRow.getCell('B').font = {
      name: 'Arial',
      family: 4,
      size: 14,
      bold: true
    };
    totaalRow.eachCell((cell, number) => {
      if (number < 3) {
        cell.fill = this.fillColor('8FE250');
        cell.border = this.thinBorder();
      }
    });
    let totaalRowMachines = worksheet2.addRow(['Kosten machines&machinisten']);
    totaalRowMachines.font = {
      name: 'Arial',
      family: 4,
      size: 14
    };
    totaalRowMachines.height = 18;
    totaalRowMachines.getCell('B').value = "€ " + totaalKostMachines?.toFixed(2);
    totaalRowMachines.getCell('B').font = {
      name: 'Arial',
      family: 4,
      size: 14,
      bold: true
    };
    totaalRowMachines.eachCell((cell, number) => {
      if (number < 3) {
        cell.fill = this.fillColor('8FE250');
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    let totaalRowChauffeurs = worksheet2.addRow(['Kosten chauffeurs:']);
    totaalRowChauffeurs.font = {
      name: 'Arial',
      family: 4,
      size: 14
    };
    totaalRowChauffeurs.height = 18;
    totaalRowChauffeurs.getCell('B').value = "€ " + totaalKostChauffeurs?.toFixed(2);
    totaalRowChauffeurs.getCell('B').font = {
      name: 'Arial',
      family: 4,
      size: 14,
      bold: true
    };
    totaalRowChauffeurs.eachCell((cell, number) => {
      if (number < 3) {
        cell.fill = this.fillColor('8FE250');
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    let totaalRowProject = worksheet2.addRow(['Kosten project:']);
    totaalRowProject.font = {
      name: 'Arial',
      family: 4,
      size: 14
    };
    totaalRowProject.height = 18;
    totaalRowProject.getCell('B').value = "€ " + (totaalKostArbeiders + totaalKostChauffeurs + totaalKostMachines)?.toFixed(2);
    totaalRowProject.getCell('B').font = {
      name: 'Arial',
      family: 4,
      size: 14,
      bold: true
    };
    totaalRowProject.eachCell((cell, number) => {
      if (number < 3) {
        cell.fill = this.fillColor('00B0F0');
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      }
    });
    worksheet2.addRow(['']);
    worksheet2.addRow(['']);
    let allRealWerkers = allWerkers.filter(x => x.handwerker.role !== '59cf78e883680012b0438502');
    let allChauffeurs = allWerkers.filter(x => x.handwerker.role === '59cf78e883680012b0438502');
    let allOnderaannemers = allRealWerkers.filter(x => x.handwerker.functieNaam === 'Onderaannemer');
    allRealWerkers = allRealWerkers.filter(x => x.handwerker.functieNaam !== 'Onderaannemer');

    allRealWerkers = allRealWerkers.sort((a,b) => a.handwerker.name.toLowerCase() > b.handwerker.name.toLowerCase() ? 1 : -1);
    allChauffeurs = allChauffeurs.sort((a,b) => a.handwerker.name.toLowerCase() > b.handwerker.name.toLowerCase() ? 1 : -1);
    allOnderaannemers =  allOnderaannemers.sort((a,b) => a.handwerker.name.toLowerCase() > b.handwerker.name.toLowerCase() ? 1 : -1);
    allMateriaalAantallen = allMateriaalAantallen.sort((a,b) => a.naam.toLowerCase() > b.naam.toLowerCase() ? 1 : -1);
    allChauffeurMateriaalAantallen = allChauffeurMateriaalAantallen.sort((a,b) => a.naam.toLowerCase() > b.naam.toLowerCase() ? 1 : -1);

    let titelRow = worksheet2.addRow(['Kosten per arbeider:', '', '', '', (allMateriaalAantallen.length !== 0 ? 'Materiaalverbruik door arbeiders:' : ''),
      '', '', (allChauffeurMateriaalAantallen.length !== 0 ? 'Materiaallevering door chauffeurs:' : '')]);
    titelRow.font = {
      name: 'Arial',
      family: 4,
      size: 14,
      underline: 'double',
      bold: true,
    };
    titelRow.height = 18;
    if(allMateriaalAantallen.length !== 0){
      //merge cell e to f
      if(chosenWerfNaam !== 'geen'){
        worksheet2.mergeCells('E12:G12');
      } else {
        worksheet2.mergeCells('E11:G11');
      }
    }
    let materiaalCounter = 0;
    let chauffeurMateriaalCounter = 0;

    for (let user of allRealWerkers) {
      if (user.minuten != null && user.minuten !== 0) {
        let uren = Math.floor(user.minuten / 60);
        let minuten = user.minuten % 60;
        let userName = user.handwerker.name;
        let totaalRow = worksheet2.addRow([userName, uren + 'u ' + minuten + 'min', '€' + user.kost?.toFixed(2)]);
        totaalRow.font = this.fontSize(11);
        totaalRow.height = 17;
        if(allMateriaalAantallen[materiaalCounter] != null){
          totaalRow.getCell('E').value = allMateriaalAantallen[materiaalCounter].naam;
          totaalRow.getCell('F').value =  allMateriaalAantallen[materiaalCounter].hoeveelheid + ' ' + allMateriaalAantallen[materiaalCounter].eenheid;
          totaalRow.getCell('F').font = this.fontSizeBold(11);
          materiaalCounter++;
        }
        if(allChauffeurMateriaalAantallen != null && allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null) {
          totaalRow.getCell('H').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].naam;
          totaalRow.getCell('I').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].hoeveelheid + ' ' + allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].eenheid;
          totaalRow.getCell('I').font = this.fontSizeBold(11);
          chauffeurMateriaalCounter++;
        }
      }
    }
    if(allOnderaannemers.length !== 0){
      let onderaannemerRow = worksheet2.addRow(['Kosten per onderaannemer:']);
      onderaannemerRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        underline: 'double',
        bold: true,
      };
      onderaannemerRow.height = 18;
      for (let user of allOnderaannemers) {
        if (user.minuten != null && user.minuten !== 0) {
          let uren = Math.floor(user.minuten / 60);
          let minuten = user.minuten % 60;
          let userName = user.handwerker.name +  ' - ' +user.handwerker.firmaNaam;
          let totaalRow = worksheet2.addRow([userName, uren + 'u ' + minuten + 'min', '€' + user.kost?.toFixed(2)]);
          totaalRow.font = this.fontSize(11);
          totaalRow.height = 17;
          if(allMateriaalAantallen[materiaalCounter] != null){
            totaalRow.getCell('E').value = allMateriaalAantallen[materiaalCounter].naam;
            totaalRow.getCell('F').value =  allMateriaalAantallen[materiaalCounter].hoeveelheid + ' ' + allMateriaalAantallen[materiaalCounter].eenheid;
            totaalRow.getCell('F').font = this.fontSizeBold(11);
            materiaalCounter++;
          }
          if(allChauffeurMateriaalAantallen != null && allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null) {
            totaalRow.getCell('H').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].naam;
            totaalRow.getCell('I').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].hoeveelheid + ' ' + allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].eenheid;
            totaalRow.getCell('I').font = this.fontSizeBold(11);
            chauffeurMateriaalCounter++;
          }
        }
      }
    }

    if(allChauffeurs.length !== 0){
      let chauffeursRow = worksheet2.addRow(['Kosten per chauffeur:']);
      chauffeursRow.font = {
        name: 'Arial',
        family: 4,
        size: 14,
        underline: 'double',
        bold: true,
      };
      chauffeursRow.height = 18;
      for (let user of allChauffeurs) {
        if (user.minuten != null && user.minuten !== 0) {
          let uren = Math.floor(user.minuten / 60);
          let minuten = user.minuten % 60;
          let totaalRow = worksheet2.addRow([user.handwerker.name, uren + 'u ' + minuten + 'min', '€' + user.kost?.toFixed(2)]);
          totaalRow.font = this.fontSize(11);
          totaalRow.height = 17;
          if(allMateriaalAantallen[materiaalCounter] != null){
            totaalRow.getCell('E').value = allMateriaalAantallen[materiaalCounter].naam;
            totaalRow.getCell('F').value =  allMateriaalAantallen[materiaalCounter].hoeveelheid + ' ' + allMateriaalAantallen[materiaalCounter].eenheid;
            totaalRow.getCell('F').font = this.fontSizeBold(11);
            materiaalCounter++;
          }
          if(allChauffeurMateriaalAantallen != null && allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null) {
            totaalRow.getCell('H').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].naam;
            totaalRow.getCell('I').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].hoeveelheid + ' ' + allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].eenheid;
            totaalRow.getCell('I').font = this.fontSizeBold(11);
            chauffeurMateriaalCounter++;
          }
        }
      }
    }
    if(allMateriaalAantallen[materiaalCounter] != null || allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null){
      while(allMateriaalAantallen[materiaalCounter] != null || allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null){
        let totaalRow = worksheet2.addRow(['', '', '']);
        totaalRow.font = this.fontSize(11);
        totaalRow.height = 17;
        if(allMateriaalAantallen[materiaalCounter] != null){
          totaalRow.getCell('E').value = allMateriaalAantallen[materiaalCounter].naam;
          totaalRow.getCell('F').value =  allMateriaalAantallen[materiaalCounter].hoeveelheid + ' ' + allMateriaalAantallen[materiaalCounter].eenheid;
          totaalRow.getCell('F').font = this.fontSizeBold(11);
          materiaalCounter++;
        }
        if(allChauffeurMateriaalAantallen != null && allChauffeurMateriaalAantallen[chauffeurMateriaalCounter] != null) {
          totaalRow.getCell('H').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].naam;
          totaalRow.getCell('I').value = allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].hoeveelheid + ' ' + allChauffeurMateriaalAantallen[chauffeurMateriaalCounter].eenheid;
          totaalRow.getCell('I').font = this.fontSizeBold(11);
          chauffeurMateriaalCounter++;
        }
      }
    }
    worksheet2.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      if (i === 0) {
        column.width = 40;
      } else if(i === 1 ){
        column.width = 20;
      } else if( i === 2 || i === 3 || i === 5 || i === 6 || i === 8){
       column.width = 14;
      } else {
        column.width = 28;
      }
    });






    //kosten per activiteit pagina

    let worksheet3 = workbook.addWorksheet('Kosten per activiteit', {
      views: [{state: "frozen", ySplit: 3}],
    });
    worksheet3.properties.tabColor = { argb: '99ccff' };
    // Add new row
    let titleRow3 = worksheet3.addRow(['Kosten per activiteit']);
    titleRow3.font = {
      name: 'Arial',
      family: 4,
      size: 14,
      underline: 'double',
      bold: true,
    };
    titleRow3.height = 23;
    titleRow3.getCell('A').alignment = { horizontal: 'center', vertical : 'middle' };
    titleRow3.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '99ccff' } };
    titleRow3.getCell('A').border = {bottom: {style: 'medium'}, right: {style: 'medium'}, left: {style: 'medium'}, top : {style: 'medium'}};
    worksheet3.mergeCells('A1:B1');
    worksheet3.mergeCells('D1:H1');
    titleRow3.getCell('D').value = companyName;

    if(dataList[0]?.date == null){
      dataList[0] = new Dagrapport();
      dataList[0].date = new Date(transportDagrapporten[0].date);
    } else {
      dataList[0].date = new Date(dataList[0].date);
    }
    if(chosenWerfNaam !== 'geen'){
      let locationRow2 = worksheet3.addRow([
        'Werf: ' + chosenWerfNaam
      ]);
      locationRow2.font = this.fontSize(13)
      locationRow2.height = 20;
      locationRow2.getCell('A').alignment = { horizontal: 'center', vertical: 'middle' };
      locationRow2.getCell('A').border = { right: {style: 'thin'}};
      }

    let dateRow3 = worksheet3.addRow([
      'Startdatum: ' + ("0" + (vroegsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (vroegsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      vroegsteDatumWerf.getFullYear().toString().substr(-2) + ' - Einddatum: ' + ("0" + (laatsteDatumWerf.getDate())).slice(-2) + '/' + ("0" + (laatsteDatumWerf.getMonth() + 1)).slice(-2) + '/' +
      laatsteDatumWerf.getFullYear().toString().substr(-2)
    ]);
    dateRow3.font = this.fontSize(13);
    dateRow3.height = 20;
    dateRow3.getCell('A').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFAF' } };
    dateRow3.getCell('A').border = {bottom: {style: 'thin'}, right: {style: 'thin'},  left: {style: 'thin'}, top : {style: 'thin'}};
    dateRow3.getCell('A').alignment = { horizontal: 'center' };
    if(chosenWerfNaam !== 'geen'){
      worksheet3.mergeCells('A2:B2');
      worksheet3.mergeCells('A3:B3');
    } else {
      worksheet3.mergeCells('A2:B2');
    }

   worksheet3.addRow(['']);

    for (let activiteit of allActiviteiten) {
      let uren = Math.floor(activiteit.minuten / 60);
      let minuten = activiteit.minuten % 60;
      let totaalRow = worksheet3.addRow([activiteit.werkzaamheid.naam, 'Arbeidstijd: ' + uren + 'u' + minuten + 'min', 'Arbeidskost: ' + '€' + activiteit.arbeidersKosten?.toFixed(2)]);
      totaalRow.height = 23;
      totaalRow.eachCell((cell, number) => {
        if (number === 1) {
          cell.fill = this.fillColor('B0F010');
          cell.font = this.fontSizeBold(12, '0000FF');
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
          cell.border = this.thinBorder();
        } else {
          cell.font = this.fontSize(10, '595959')
        }
      });

      // wordt beneden pas gebruikt. staat hier voor samenvoegen van rijen als er maar 1 subactiviteit is
      let alleSubsOfActiviteit = allSubactiviteiten.filter(x => activiteit.werkzaamheid._id === x.werkzaamheid._id);

      const addTotaalKostenRow = (row, activiteit) => {
        row.font = this.fontSize(10);
        row.height = 18;
        row.eachCell((cell,number) => {
          if(number === 1){
            cell.fill = this.fillColor('FFFF00');
            cell.border = this.thinBorder();
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.value = {
              richText: [
                {
                  text: 'Kosten activiteit: ',
                  font: this.fontSize(10, '000000')
                },
                {
                  text: '€ ' + activiteit.totaleKosten?.toFixed(2),
                  font: this.fontSizeBold(12, '3d54c8')
                },
              ],
            }
          }
        })
      }
      if(activiteit.machines != null && activiteit.machines.length !== 0){
        let isFirstMachine = true;
        let secondMachine = true;
        for (let machine of activiteit.machines) {
          let uren2 = Math.floor(machine.minuten / 60);
          let minuten2 = machine.minuten % 60;
          let machineRow = worksheet3.addRow(['', machine.machine.naam, 'Machineuren: ' + uren2 + 'u' + minuten2 + 'min', 'Machinekost: ' + '€' + machine.kost?.toFixed(2)]);
          machineRow.font = this.fontSize(11);
          machineRow.height = 14;
          if(isFirstMachine){
              addTotaalKostenRow(machineRow, activiteit);
            isFirstMachine = false;
          }
          machineRow.eachCell((cell, number) => {
            if (number !== 1) {
              cell.font = this.fontSize(10, '595959')
            }
          });
          activiteit.kost += machine.kost;
        }
      } else {
        let totaalActiviteitRow = worksheet3.addRow(['Kosten activiteit: ' + activiteit.kost?.toFixed(2)]);
        addTotaalKostenRow(totaalActiviteitRow, activiteit);
      }



      if (activiteit.materialen != null) {
        for (let materiaal of activiteit.materialen) {
          let materiaalRow;
          let kostprijs = (activiteit.kost / materiaal.hoeveelheid)?.toFixed(2);
          if(materiaal.materiaalNaam == null){
            materiaalRow = worksheet3.addRow(['', 'Materiaal: ' + materiaal.standaardMateriaal.naam, 'Totale hoeveelheid: ' + materiaal.hoeveelheid + ' ' + materiaal.eenheid,
              'Kostprijs per ' + materiaal.eenheid + ' : ' + "€" + kostprijs]);
          } else {
            materiaalRow = worksheet3.addRow(['', 'Materiaal: ' + materiaal.materiaalNaam, 'Totale hoeveelheid: ' + materiaal.hoeveelheid + ' ' + materiaal.eenheid,
              'Kostprijs per ' + materiaal.eenheid + ' : ' + "€" + kostprijs])
          }
          materiaalRow.font = this.fontSize(10)
          materiaalRow.height = 18;
          materiaalRow.eachCell((cell, number) => {
            if (number === 2) {
              cell.fill = this.fillColor('92CDDC');
              cell.border = this.thinBorder();
              cell.value = {
                richText: [
                  {
                    text: 'Materiaal: ',
                    font: this.fontSize(10, '000000')
                  },
                  {
                    text: materiaal.materiaalNaam == null ? materiaal.standaardMateriaal.naam : materiaal.materiaalNaam,
                    font: this.fontSize(10, '3d54c8')
                  },
                ],
              }
            } else if (number === 3) {
              cell.fill = this.fillColor('92CDDC');
              cell.border = this.thinBorder();
              cell.value = {
                richText: [
                  {
                    text: 'Totale hoeveelheid: ',
                    font: this.fontSize(10, '000000')
                  },
                  {
                    text: +materiaal.hoeveelheid + ' ' + materiaal.eenheid,
                    font: this.fontSize(10, '3d54c8')
                  },
                ],
              }
            } else if (number === 4) {
              cell.fill = this.fillColor('92CDDC');
              cell.border = this.thinBorder();
              cell.value = {
                richText: [
                  {
                    text: 'Kostprijs per ' + materiaal.eenheid + ' : ',
                    font:  this.fontSize(10, '000000')
                  },
                  {
                    text: (materiaal.hoeveelheid == null || materiaal.hoeveelheid === 0) ? ' onb.' : '€ ' + kostprijs,
                    font: this.fontSize(10, '3d54c8')
                  },
                ],
              }
            }
          });
        }
      }
      if (alleSubsOfActiviteit[0] != null) {
        let subRow = worksheet3.addRow(['', 'Subactiviteiten' ]);
        subRow.font = this.fontSizeBold(11, 'FFFFFF');
        subRow.height = 15;
        worksheet3.mergeCells('B' + subRow.number + ':E' + subRow.number);
        subRow.getCell('B').fill = this.fillColor('00B0F0');
        subRow.getCell('B').border = this.thinBorder();
        subRow.getCell('B').alignment = { horizontal: 'center', vertical: 'middle' };
        subRow.eachCell((cell, number) => {
        });
        let subActiviteitIndex = 0;
        for (let subActiviteit of alleSubsOfActiviteit) {
          let uren = Math.floor(subActiviteit.minuten / 60);
          let minuten = subActiviteit.minuten % 60;

          let totaalRow = worksheet3.addRow(['', subActiviteit.subActiviteit, 'Arbeidstijd: ' + uren + 'u' + minuten + 'min', 'Arbeidskost: ' + '€' + subActiviteit.arbeidersKosten?.toFixed(2)]);
          totaalRow.height = 22;
          totaalRow.eachCell((cell, number) => {
            if (number === 2) {
              cell.fill = this.fillColor('FFC000');
              cell.border = this.thinBorder();
              cell.font = this.fontSizeBold(11, '0000FF');
              cell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (number > 2) {
              cell.font = this.fontSize(10, '595959');
            }
          });
          let isFirstMachine = true;
          const addTotaalKostenSubactiviteitRow = (row, activiteit) => {
            row.font = this.fontSize(10);
            row.height = 18;
            row.eachCell((cell,number) => {
              if(number === 2){
                cell.fill = this.fillColor('FFFF00');
                cell.border = this.thinBorder();
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                cell.value = {
                  richText: [
                    {
                      text: 'Kosten activiteit: ',
                      font: this.fontSize(10, '000000')
                    },
                    {
                      text: '€ ' + activiteit.totaleKosten?.toFixed(2),
                      font: this.fontSizeBold(12, '3d54c8')
                    },
                  ],
                }
              }
            })
          }
          if(subActiviteit.machines != null && subActiviteit.machines.length !== 0){
            for (let machine of subActiviteit.machines) {
              let uren2 = Math.floor(machine.minuten / 60);
              let minuten2 = machine.minuten % 60;
              let machineRow = worksheet3.addRow(['', '', machine.machine.naam, 'Machineuren: ' + uren2 + 'u' + minuten2 + 'min', 'Machinekost: ' + '€' + machine.kost?.toFixed(2)]);
              machineRow.font = this.fontSize(10);
              machineRow.height = 14;
              machineRow.eachCell((cell, number) => {
                if (number > 2) {
                  cell.font = this.fontSize(10, '595959');
                }
              });
              subActiviteit.kost += machine.kost;
              if (isFirstMachine) {
                addTotaalKostenSubactiviteitRow(machineRow, subActiviteit);
                isFirstMachine = false;
              }
            }
          } else {
            let totaalActiviteitRow = worksheet3.addRow(['', 'Kosten activiteit: ' + subActiviteit.kost?.toFixed(2)]);
            addTotaalKostenSubactiviteitRow(totaalActiviteitRow, subActiviteit);
          }


          if (subActiviteit.materialen != null) {
            for (let materiaal of subActiviteit.materialen) {
              let materiaalRow;
              let kostprijs = (subActiviteit.kost / materiaal.hoeveelheid)?.toFixed(2);
              if(subActiviteit.materiaalNaam == null){
                materiaalRow = worksheet3.addRow(['','',  'Materiaal: ' + materiaal.standaardMateriaal?.naam, 'Totale hoeveelheid: ' + materiaal.hoeveelheid + ' ' + materiaal.eenheid,
                  'Kostprijs per ' + materiaal.eenheid + ' : ' + "€" + kostprijs]);
              } else {
                materiaalRow = worksheet3.addRow(['','',  'Materiaal: ' + materiaal.materiaalNaam, 'Totale hoeveelheid: ' + materiaal.hoeveelheid + ' ' + materiaal.eenheid,
                  'Kostprijs per ' + materiaal.eenheid + ' : ' + "€" + kostprijs]);
              }
              materiaalRow.font = this.fontSize(10);
              materiaalRow.height = 18;
              materiaalRow.eachCell((cell, number) => {
                if (number === 3) {
                  cell.fill = this.fillColor('92CDDC');
                  cell.border = this.thinBorder();
                  cell.value = {
                    richText: [
                      {
                        text: 'Materiaal: ',
                        font: this.fontSize(10, '000000'),
                      },
                      {
                        text: materiaal.materiaalNaam == null ? materiaal.standaardMateriaal.naam : materiaal.materiaalNaam,
                        font: this.fontSize(10, '3d54c8'),
                      },
                    ],
                  }
                } else if (number === 4) {
                  cell.fill = this.fillColor('92CDDC');
                  cell.border = this.thinBorder();
                  cell.value = {
                    richText: [
                      {
                        text: 'Totale hoeveelheid: ',
                        font: this.fontSize(10, '000000'),
                      },
                      {
                        text: +materiaal.hoeveelheid + ' ' + materiaal.eenheid,
                        font: this.fontSize(10, '3d54c8'),
                      },
                    ],
                  }
                } else if (number === 5) {
                  cell.fill = this.fillColor('92CDDC');
                  cell.border = this.thinBorder();
                  cell.value = {
                    richText: [
                      {
                        text: 'Kostprijs per ' + materiaal.eenheid + ' : ',
                        font: this.fontSize(10, '000000'),
                      },
                      {
                        text:(materiaal.hoeveelheid == null || materiaal.hoeveelheid === 0) ? ' onb.' : '€ ' + kostprijs,
                        font: this.fontSize(10, '3d54c8'),
                      },
                    ],
                  }
                }
              });
            }
          }
          if (subActiviteitIndex + 1 < alleSubsOfActiviteit.length) {
            worksheet3.addRow([''
            ]);
            subActiviteitIndex++;
          }
        }
      }
      worksheet3.addRow([''
      ]);
      let horizontalLine = worksheet3.addRow(['', '', '', '', '']);
      worksheet3.mergeCells( 'A' + (horizontalLine.number) + ':E' + (horizontalLine.number));
      horizontalLine.font = this.fontSize(10);
      horizontalLine.height = 2;
      horizontalLine.eachCell((cell, number) => {
        cell.fill = this.fillColor('244062');
      });
    }
    worksheet3.addRow([''
    ]);
    worksheet3.columns.forEach(function (column, i) {
      let maxLength = 0;
      column['eachCell']({includeEmpty: true}, function (cell) {
        let columnLength = cell.value?.toString().length;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      if (i === 0 || i === 1) {
        column.width = 44;
      } else {
        column.width = 28;
      }
    });


    if (logoURL != null) {
      this.getBase64ImageFromUrl(logoURL)
        .then(result => {
          let base64 = result as string;
          let logo = workbook.addImage({
            base64: base64,
            extension: 'png',
          });
          worksheet.addImage(logo, 'K1:M2');
          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            fs.saveAs(blob, fileName);
          })
            .catch(err => console.error(err));
        });
    } else {
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(blob, fileName);
      });
    }

  }
  sortDay = function (p1, p2) {
      if(p1.machine != null){
        return -1;
      } else if(p2.machine != null){
        return 1;
      }
      if(p1.handwerker.functieNaam === 'Ploegbaas'){
        return -1;
      } else if(p2.handwerker.functieNaam === 'Ploegbaas'){
         return 1;
      }
      if(p1.handwerker.functieNaam === 'Arbeider'){
        return -1;
      } else if(p2.handwerker.functieNaam === 'Arbeider'){
        return 1;
      }
      if(p1.handwerker.functieNaam === 'Onderaannemer'){
        return -1;
      } else if(p2.handwerker.functieNaam === 'Onderaannemer'){
        return 1;
      }
      if(p1.handwerker.functieNaam === 'Chauffeur'){
        return -1;
      } else if(p2.handwerker.functieNaam === 'Chauffeur'){
        return 1;
      }
      return 0;
  }

  sortIndex = function (p1, p2) {
    let p1Date = (p1.date.getFullYear() * 1000) + (p1.date.getMonth() * 50) + p1.date.getDate();
    let p2Date = (p2.date.getFullYear() * 1000) + (p2.date.getMonth() * 50) + p2.date.getDate();

    if(p1Date < p2Date){
      return -1;
    } else if(p1Date > p2Date){
      return 1;
    } else {
      if(p1.aantal != null){
        return -1;
      }
      if(p2.aantal != null){
        return 1;
      }
      if(p1.activiteit === p2.activiteit){
        const comparisonResultMateriaal = p1.materialen?.toString()?.localeCompare(p2.materialen?.toString());
        if (comparisonResultMateriaal < 0) {
          return -1;
        } else if (comparisonResultMateriaal > 0) {
          return 1;
        }
        const comparisonResultOpmerking = p1.opmerking?.toString()?.localeCompare(p2.opmerking?.toString());
        if (comparisonResultOpmerking < 0) {
          return -1;
        } else if (comparisonResultOpmerking > 0) {
          return 1;
        }
        if(p1.machinist?.naam != null) {
          return -1;
        } else if(p2.machinist?.naam != null) {
          return 1;
        } else if(p1.role === '59cf78e883680012b0438501') {
          return -1;
        } else if(p2.role === '59cf78e883680012b0438501') {
          return 1;
        } else if(p1.role === '59cf78e883680012b0438504') {
          return -1;
        } else if(p2.role === '59cf78e883680012b0438504') {
          return 1;
        } else {
          return 0;
        }
      } else {
        return p1.activiteit?.localeCompare(p2.activiteit); // Replace "name" with the property you want to sort by alphabetically.
      }
    }
  }
  sortIndexMetVerschillendeWerven = function (p1, p2) {
    let p1Date = (p1.date.getFullYear() * 1000) + (p1.date.getMonth() * 50) + p1.date.getDate();
    let p2Date = (p2.date.getFullYear() * 1000) + (p2.date.getMonth() * 50) + p2.date.getDate();

    if(p1Date < p2Date){
      return -1;
    } else if(p1Date > p2Date){
      return 1;
    } else {

      const comparisonResult = p1.werf?.naam?.localeCompare(p2.werf?.naam);
      if (comparisonResult < 0) {
        return -1;
      } else if (comparisonResult > 0) {
        return 1;
      }
      if(p1.aantal != null){
        return -1;
      }
      if(p2.aantal != null){
        return 1;
      }
        if(p1.activiteit === p2.activiteit){
          const comparisonResultMateriaal = p1.materialen?.toString()?.localeCompare(p2.materialen?.toString());
          if (comparisonResultMateriaal < 0) {
            return -1;
          } else if (comparisonResultMateriaal > 0) {
            return 1;
          }
          const comparisonResultOpmerking = p1.opmerking?.toString()?.localeCompare(p2.opmerking?.toString());
          if (comparisonResultOpmerking < 0) {
            return -1;
          } else if (comparisonResultOpmerking > 0) {
            return 1;
          }
          if(p1.machinist?.naam != null) {
            return -1;
          } else if(p2.machinist?.naam != null) {
            return 1;
          } else if(p1.role === '59cf78e883680012b0438501') {
            return -1;
          } else if(p2.role === '59cf78e883680012b0438501') {
            return 1;
          } else if(p1.role === '59cf78e883680012b0438504') {
            return -1;
          } else if(p2.role === '59cf78e883680012b0438504') {
            return 1;
          } else {
            return 0;
          }
        } else {
          return p1.activiteit?.localeCompare(p2.activiteit); // Replace "name" with the property you want to sort by alphabetically.
        }
    }
  }
  toastBadForm(werfNaam: string) {
    this.toastrService.warning( 'Op ' + werfNaam + ' is geen uurloon ingesteld voor de ploegbaas of arbeider', 'Vul het uurloon in op de werf.');
  }
  toastBadFormMachineKost(werfNaam: string) {
    this.toastrService.warning( 'Op machine ' + werfNaam + ' is geen kostprijs ingesteld voor de machine(inclusief machinist)', 'Vul de kostprijs in');
  }
  thinBorder(): Partial<Borders> {
    return {
      top: {style: 'thin'},
      left: {style: 'thin'},
      bottom: {style: 'thin'},
      right: {style: 'thin'}
    };
  }
  fillColor(color: string): FillPattern {
    return {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: color},
      bgColor: {argb: color}
    }
  }
  fontSize(size: number, textColor?: string){
    if(textColor == null){
      return  {
        name: 'Arial',
        family: 4,
        size: size,
      };
    } else {
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        color: {
          argb: textColor,
          theme: 1,
        },
      };
    }
  }
  fontSizeBold(size: number, textColor?: string){
    if(textColor == null){
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        bold: true
      };
    } else {
      return  {
        name: 'Arial',
        family: 4,
        size: size,
        bold: true,
        color: {
          argb: textColor,
          theme: 1,
        },
      };
    }
  }
}
