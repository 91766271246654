import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { PagesComponent } from './pages.component';
import {OverzichtDagrapportenComponent} from "./overzicht-dagrapporten/overzicht-dagrapporten.component";
import {SettingsComponent} from "./settings/settings.component";
import {AdminComponent} from "./admin/admin.component";
import {UpdatePopupDialogComponent} from "./update-popup-dialog/update-popup-dialog.component";
import {VerlofsComponent} from "./verlofs/verlofs.component";
import {StaatDerVerletdagenComponent} from "./staat-der-verletdagen/staat-der-verletdagen.component";
import {VerlofdagenInstellingenComponent} from "./verlofdagen-instellingen/verlofdagen-instellingen.component";
import {AanwezigheidslijstComponent} from "./aanwezigheidslijst/aanwezigheidslijst.component";
import {KlantenComponent} from "./klanten/klanten.component";
import {KlantenEditComponent} from "./klanten/klanten-edit/klanten-edit.component";
import {KlantenAddComponent} from "./klanten/klanten-add/klanten-add.component";
import {AddNewCompanyComponent} from "./admin/add-new-company/add-new-company.component";
import {EditCompanyComponent} from "./admin/edit-company/edit-company.component";
import {WerfplanningComponent} from "./werfplanning/werfplanning.component";

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'staat-der-verletdagen',
      component: StaatDerVerletdagenComponent
    },
    {
      path:'e-digidlist',
      loadChildren : () => import('./edigid-list/edigid-list.module').then(m => m.EdigidListModule)
    },
    {
      path:'transport-dagrapporten',
      loadChildren : () => import('./transport-dagrapporten/transport-dagrapporten.module').then(m => m.TransportDagrapportenModule)
    },
    {
      path: 'magazijn-taken',
      loadChildren : () => import('./magazijn-taken/magazijn-taken.module').then(m => m.MagazijnTakenModule)
    },
    {
      path: 'klanten',
      component: KlantenComponent
    },
    {
      path: 'editklant/:id',
      component: KlantenEditComponent
    },
    {
      path: 'editEigenBedrijf',
      component: KlantenAddComponent
    },
    {
      path: 'schademeldingen',
      loadChildren : () => import('./schademeldingen/schademeldingen.module').then(m => m.SchademeldingenModule)
    },
    {
      path: 'aanwezigheidslijst',
      component: AanwezigheidslijstComponent
    },
    {
      path: 'users',
      loadChildren : () => import('./users/users.module').then(m => m.UsersModule)
    },
    {
      path: 'overzicht-dagrapporten',
      component: OverzichtDagrapportenComponent
    },
    {
      path: 'settings',
      component: SettingsComponent
    },
    {
      path: 'werfplanning',
      component: WerfplanningComponent
    },
    {
      path:'reparaties',
      loadChildren : () => import('./reparaties/reparaties.module').then(m => m.ReparatiesModule)
    },
    {
      path:'materieel-instellen',
      loadChildren : () => import('./materieel-instellen/materieel-instellen.module').then(m => m.MaterieelInstellenModule)
    },
    {
      path:'verlofs',
      component: VerlofsComponent
    },
    {
      path:'verlofdagen-instellen',
      component: VerlofdagenInstellingenComponent
    },
    {
      path: 'admin',
      component: AdminComponent
    },
    {
      path: 'admin/add-new-company',
      component: AddNewCompanyComponent
    },
    {
      path: 'admin/edit-company',
      component: EditCompanyComponent
    },
    {
      path: 'update-popup-dialog',
      component: UpdatePopupDialogComponent
    },
    {
      path: 'dagrapporten',
      loadChildren : () => import('./dagrapporten/dagrapporten.module').then(m => m.DagrapportenModule)
    },
    {
      path: '',
      redirectTo: 'dagrapporten',
      pathMatch: 'full',
    },
  ],
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
