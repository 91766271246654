import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NbCalendarRange, NbToastrService} from "@nebular/theme";
import {FormService} from "../../../services/form.service";
import {ApiService} from "../../../services/api.service";
import {Werf} from "../../../models/werf";
import {DagboekDerWerken} from "../../../models/dagboek-der-werken";
import moment, {Moment} from "moment";
import {NbMomentDateService} from "@nebular/moment";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'dagboek-pdf-dialog',
  templateUrl: './dagboek-pdf-dialog.component.html',
  styleUrls: ['./dagboek-pdf-dialog.component.scss']
})
export class DagboekPdfDialogComponent implements OnInit {
  checkbox: any;
  werf: Werf;
  firstDagboek: DagboekDerWerken;
  latestDagboek: DagboekDerWerken;
  firstDate: any;
  lastDate: any;
  rangeForm: FormGroup;
  isLoaded: boolean;
  maanden: string[] = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
  weekDateRanges: { start: Date, end: Date }[];
  startOfProjectDate: Date;
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private formService: FormService,
    private router: Router,
    private toastrService: NbToastrService,
    public dialogRef: MatDialogRef<DagboekPdfDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.werf = data.werf;
    this.firstDagboek = data.firstDagboek;
    this.latestDagboek = data.latestDagboek;
    this.startOfProjectDate = new Date(this.werf.startDatum);
    if (this.firstDagboek != null) {
      this.firstDagboek.date = new Date(this.firstDagboek.date);
      this.firstDate = this.firstDagboek.date;
    }
    if (this.latestDagboek != null) {
       this.latestDagboek.date = new Date(this.latestDagboek.date);
      this.lastDate = this.latestDagboek.date;
    }
    this.weekDateRanges = this.getWeekRanges(this.lastDate);
    this.rangeForm = this.fb.group({
      dateRange: this.fb.group({
        start: [null],
        end: [null]
      })
    });
    this.isLoaded = true;
  }

  ngOnInit(): void {
    this.formService.isSave = null;
    this.checkbox = false;
  }


  closeDialog(){
    this.formService.isSave = null;
    this.dialog.closeAll();
  }

  downloadPdf() {
    if( this.rangeForm.get('dateRange').value.start == null || this.rangeForm.get('dateRange').value.end == null){
      this.toastrService.warning( 'Gelieve een start- en einddatum te kiezen.', 'Vul een datum in.');
      return;
    }

    this.formService.isSave = true;
    this.formService.startDate = new Date(this.rangeForm.get('dateRange').value.start);
    this.formService.endDate = new Date(this.rangeForm.get('dateRange').value.end);
    this.dialog.closeAll();
  }

  dateToString(date: Date){
    return date.getDate() + ' ' + this.maanden[date.getMonth()] + ' ' + date.getFullYear();
  }

  clearDatum() {
    this.rangeForm.reset({
      dateRange: { start: null, end: null }
    });
  }
  fetchDataForRange(start: Date, end: Date) {
    // Implementeer logica om data op te halen voor gegeven range
     this.rangeForm.patchValue({
      dateRange: {
        start: start,
        end: end
     }});
  }
  getWeekRanges(endDate: Date): { start: Date, end: Date }[] {
    let ranges = [];
    let endDateAdjusted = new Date(endDate);
    endDateAdjusted.setHours(23, 59, 59, 999); // Ensure endDate includes the full day

    let today = new Date();
    today.setHours(23, 59, 59, 999); // Ensure today's date includes the full day

    // Start from the closest previous Monday to the endDate
    let currentDate = new Date(endDateAdjusted);
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));

    while (ranges.length < 3) {
      let endOfWeek = new Date(currentDate);
      endOfWeek.setDate(currentDate.getDate() + 4); // Calculate the Friday of the current week

      if(endOfWeek < this.startOfProjectDate){
        break;
      }
      // Check if the endOfWeek is before the endDate and today's date
      if (endOfWeek > endDateAdjusted) {
        //set to week before this week on monday
        currentDate.setDate(currentDate.getDate() - 7);
        //set to monday
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));
      } else {
        if(currentDate < this.startOfProjectDate){
          currentDate = new Date(this.startOfProjectDate);
        }
        ranges.push({
          start: new Date(currentDate), // Start of the week (Monday)
          end: new Date(endOfWeek) // End of the week (Friday)
        });

        // Move to the Monday of the previous week
        currentDate.setDate(currentDate.getDate() - 7);
      }
    }
    return ranges; // Reverse to maintain chronological order from oldest to newest
  }


  selectWholeProject() {
    const endDate = this.latestDagboek?.date ? this.latestDagboek.date : new Date();

    this.rangeForm.patchValue({
      dateRange: {
        start: this.startOfProjectDate,
        end: endDate
      }
    });
  }
}
