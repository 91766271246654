import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {FormService} from "../../../services/form.service";
import {ApiService} from "../../../services/api.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'groups-view-delete-dialog',
  templateUrl: './save-totalen-dialog.component.html',
  styleUrls: ['./save-totalen-dialog.component.scss']
})
export class SaveTotalenDialogComponent implements OnInit {
  checkbox: any;
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private formService: FormService,
    private router: Router,
    private toastrService: NbToastrService
  ) { }

  ngOnInit(): void {
    this.formService.isSave = null;
    this.checkbox = false;
  }


  closeDialog(){
    this.formService.isSave = null;
    this.dialog.closeAll();
  }


  getExcel() {
    this.formService.isSave = false;
    this.dialog.closeAll();
  }

  saveTotalen() {
    if(this.checkbox === true){
      this.formService.isSave = true;
      this.dialog.closeAll();
    } else {
      this.toastrService.warning( 'Als u de totalen wilt bijhouden moet u het vakje aanvinken.', 'Vink het vakje aan.');
    }
  }
}
