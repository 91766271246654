import {User} from "../user";
import {Company} from "../company";
import {Vestiging} from "./Vestiging";



export class Bedrijf {
  public _id : string;
  naam: string;
  straat: string;
  huisNr: string;
  postcode: number;
  land: string;
  gemeente: string;
  vestigingen: Vestiging[];
  ondernemingsNummer: string;
  email: string;
  telefoonNummer: string;
  btwNummer: string;
  company_id: Company;
  realBedrijf: Bedrijf;
  werfleider: User;
  tempMessage: string;
  tempRegistrationCode: number;
  constructor(naam: string,_id: string) {
    this.naam = naam;
    this._id = _id;
  }
}
