import {Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, NgZone, Input} from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import {Company} from "../../../models/company";
import {ApiService} from "../../../services/api.service";
import {FormService} from "../../../services/form.service";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  addForm;
  public isLoaded: boolean;
  company: Company;
  isSaving: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toastrService: NbToastrService,
    private formService: FormService,
    private router: Router
  ) {}

  ngOnInit(){
    this.isLoaded = false;
    this.company = this.formService.company;
    if(!this.apiService.isAdmin){
      this.router.navigate(['/']);
    }
    console.log(this.company)
    this.addForm = this.formBuilder.group({
      name: [this.company.name, [Validators.required]],
      heeftKostenBerekening: this.company.heeftKostenBerekening,
      heeftReparatieModule: this.company.heeftReparatieModule,
      heeftMagazijnierModule: this.company.heeftMagazijnierModule,
      heeftSchademeldingsModule:  this.company.heeftSchademeldingsModule,
      heeftVerlofAanvragen: this.company.heeftVerlofAanvragen,
      ovamId: [this.company.ovamId],
      prijsInstellingen: this.formBuilder.group({
        dagrapportenModulePrijsPerArbeider: [this.company.prijsInstellingen.dagrapportenModulePrijsPerArbeider, [Validators.min(0)]],
        dagboekenModulePrijsPerArbeider: [this.company.prijsInstellingen.dagboekenModulePrijsPerArbeider, [Validators.min(0)]],
        combinatieDagrapportenEnDagboekenModulePrijsPerArbeider: [this.company.prijsInstellingen.combinatieDagrapportenEnDagboekenModulePrijsPerArbeider, [Validators.min(0)]],
        gewoneTransportModulePrijsPerChauffeur: [this.company.prijsInstellingen.gewoneTransportModulePrijsPerChauffeur, [Validators.min(0)]],
        reparatieModulePrijsPerOnderhoudstechnieker: [this.company.prijsInstellingen.reparatieModulePrijsPerOnderhoudstechnieker, [Validators.min(0)]],
        magazijnierModulePrijsPerMagazijnier: [this.company.prijsInstellingen.magazijnierModulePrijsPerMagazijnier, [Validators.min(0)]],
        verlofAanvragenModulePrijsPerGebruiker: [this.company.prijsInstellingen.verlofAanvragenModulePrijsPerGebruiker, [Validators.min(0)]],
        ovamModulePrijsPerIdentificatieFormulier: [this.company.prijsInstellingen.ovamModulePrijsPerIdentificatieFormulier, [Validators.min(0)]]
      })
    });

    this.isSaving = false;
    this.isLoaded = true;
  }

  async onSubmit(data) {
    if (!this.isSaving) {
      this.isSaving = true;
      let prijsInstellingen = data.prijsInstellingen;
      prijsInstellingen._id = this.company.prijsInstellingen._id;
      data.prijsInstellingen = undefined;
      data._id = this.company._id;
      data.grondwerkerHeeftReparatie = data.heeftReparatieModule;
      data.werfleiderHeeftReparatie = data.heeftReparatieModule;
      data.grondwerkerHeeftMagazijnier = data.heeftMagazijnierModule;
      data.werfleiderHeeftMagazijnier = data.heeftMagazijnierModule;
      let sendObject = {company: data, prijsInstellingen: prijsInstellingen};
      this.apiService.editCompany(sendObject).subscribe(x => {
        this.isSaving = false;
        console.log(x);
        this.toastrService.show('Bedrijf toegevoegd', 'Success', {status: 'success'});
      })
    }
  }

}
