import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-download-popup',
  templateUrl: './download-popup.component.html',
  styleUrls: ['./download-popup.component.scss']
})
export class DownloadPopupComponent implements OnInit {
  @Input() showPopup: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  cancelDownload(): void {
    // Implement the logic to cancel the download
  }
}
