

export class StaatDerVerlet {
  public _id: string;
  weerverletDagen:number;
  ontoegankelijkheidDagen: number;
  schorsingsDagen: number;
  werkDagen: number;
  verletDagen: number;
  date: Date;
  dagboekBladNummer: number;
  company_id: string;
  werf_id: string;
  isoDate: string;
  vorderingStaatNr: number;
  constructor() {
  }
}
