import {APP_INITIALIZER, enableProdMode, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbToastrModule,
  NbDialogModule,
  NbDatepickerModule,
  NbAutocompleteModule,
  NbInputModule,
  NbOptionModule,
  NbSelectModule,
  NbTabsetModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule, HttpResponse} from "@angular/common/http";
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy, NbPasswordAuthStrategyOptions} from "@nebular/auth";
import {environment} from "../environments/environment";
import {PagesModule} from "./pages/pages.module";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideStorage,getStorage } from '@angular/fire/storage';
import {NbMomentDateModule} from "@nebular/moment";
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {QRCodeModule} from "angularx-qrcode";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {NieuweCountService} from "./services/NieuweCountService";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular";

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    NbThemeModule.forRoot({name: 'default'}),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NbMomentDateModule,
    HttpClientModule,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthJWTToken,
            key: 'token',
            getter(
              module: string,
              res: HttpResponse<Object>,
              options: NbPasswordAuthStrategyOptions,
            ) {
              let companyid = res.body['company_id']['_id'];
              let role = res.body['role'];
              let userId = res.body['userid'];
              let userName = res.body['username'];
              let token = res.body['token']['access_token'];
              return companyid + '||' + role + '||' + userId + '||' + userName + '||' + token;
            },
          },

          baseEndpoint: environment.apiURL,
          login: {
            endpoint: 'auth.login',
            method: 'post',
          },
        }),
      ],
      forms: {},
    }),
    PagesModule,
    MatButtonModule,
    MatFormFieldModule,
    NbAutocompleteModule,
    NbInputModule,
    NbOptionModule,
    NbSelectModule,
    NbTabsetModule,
    QRCodeModule,
  ],
  providers: [  { provide: LOCALE_ID, useValue: 'nl-NL' },
    NieuweCountService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

