<h1 class="custom-title"><i class="fa-solid fa-calendar-alt bigFaIcon"></i>Verlof aanvragen<span style="font-size: 12px;padding-left: 20px">Bij goedkeuring of weigering ontvangt de aanvrager (ploegbaas of arbeider) een popup-melding op zijn telefoon.
  Alle verlofaanvragen zijn tevens zichtbaar in de mobiele app.</span></h1>
<div class="header">
    <form class="search-form">
      	<p class="filterTitle">Filter op werknemer</p>
        <input class="search-box"  nbInput type="text" matInput [formControl]="searchForm"
            (keyup)="onSearchEnter($event)" placeholder="Zoeken..." />
    </form>
		<div class="kleurenCodeCol">
      <p> </p>
    </div>

  <button mat-raised-button class="add-button" (click)="printVerlofs()"><div class="divInsideButton"><img class="downloadImage" ngSrc="assets/file-arrow-down-solid.svg" alt="foto" height="16" width="16">PDF verlofdagen laatste 3 maanden</div></button>

</div>



<div *ngIf="isLoaded" style="height:calc(100vh - 200px) !important;" cdkScrollable class="scroll-table">
    <table class="item-list">
        <tr class="item-box-2">
            <th class="minMaxWidth">Aangevraagd door</th>
            <th class="minMaxWidth">Datum/periode</th>
            <th >Soort verlof</th>
            <th style="margin-left:30px !important;">Acties</th>
        </tr>
        <tr [ngClass]="verlof.isApproved ? 'greenList' : verlof.isRejected ? 'rejectedList' : 'greyList' " class="item-box verlof" *ngFor="let verlof of filteredVerlofs">
          <td class="minMaxWidth"><span *ngIf="verlof.user_id?._id !== verlof.verlofOntvanger?._id && verlof.user_id?.name !== verlof.naam" >Door: {{verlof.user_id.name}}<br><b>Voor: </b></span>
            <b>{{verlof.verlofOntvanger?.name != null ? verlof.verlofOntvanger.name :  verlof.naam}}</b></td>
          <td class="minMaxWidth" *ngIf="verlof.soort === 'single' || verlof.soort === 'speciale'"> <b>{{days[verlof.singleDatum.getDay()] + ' ' +("0"+(verlof.singleDatum.getDate())).slice(-2) + '/' +("0"+(verlof.singleDatum.getMonth()+1)).slice(-2) + '/' + verlof.singleDatum.getFullYear().toString().slice(-2)}}</b></td>
          <td style="line-height:1.5" class="minMaxWidth" *ngIf="verlof.soort === 'periode'"><b>{{days[verlof.periodeStartDatum.getDay()] + ' ' +("0"+(verlof.periodeStartDatum.getDate())).slice(-2) + '/' +("0"+(verlof.periodeStartDatum.getMonth()+1)).slice(-2) + '/' + verlof.periodeStartDatum.getFullYear().toString().slice(-2)}}</b>
          <br>tot <b>{{days[verlof.periodeEindDatum.getDay()] + ' ' +("0"+(verlof.periodeEindDatum.getDate())).slice(-2) + '/' +("0"+(verlof.periodeEindDatum.getMonth()+1)).slice(-2) + '/' + verlof.periodeEindDatum.getFullYear().toString().slice(-2)}}</b></td>
          <td>Type verlof: {{convertVerlofToRedenText(verlof)}}</td>
          <td style="margin-left:30px !important;">
          <button mat-raised-button color="primary" style="margin-right:35px !important;" (click)="acceptVerlof(verlof)">Accepteren</button>
          <button mat-raised-button color="warn" (click)="rejectVerlof(verlof)">Afwijzen</button>
            <p style="margin:10px 0 0 0" *ngIf="verlof.isRejected && verlof.afwijsReden != null && verlof.afwijsReden !== ''">Antwoord: {{verlof.afwijsReden}}</p>
          </td>
        </tr>
    </table>
</div>
<div *ngIf="isPrint" style="margin-left:10px; display:none;font-size:12px !important;"  id="printContainer" >
  <table style="width: 90%;margin-left:5%">
    <tr style="text-align:start">
      <th style="width:26%">Aangevraagd door & voor:</th>
      <th style="width:16%">Status</th>
      <th style="width:22%">Datum/periode</th>
      <th style="width:36%"  >Soort verlof</th>
    </tr>
    <tr *ngFor="let verlof of printVerlofDagen" class=".pageBreak" [ngClass]="verlof.isApproved ? 'greenList' : verlof.isRejected ? 'rejectedList' : 'greyList' ">
      <td style="width:30%">Door: {{verlof.user_id.name}}<br><b>Voor: {{verlof.verlofOntvanger?.name != null ? verlof.verlofOntvanger.name :  verlof.naam}}</b></td>
      <td style="width:18%">{{verlof.isApproved ? 'Goedgekeurd' : verlof.isRejected? 'Afgekeurd' : ''}}</td>
      <td style="width:22%" *ngIf="verlof.soort === 'single' || verlof.soort === 'speciale'"> <b>{{days[verlof.singleDatum.getDay()] + ' ' +("0"+(verlof.singleDatum.getDate())).slice(-2) + '/' +("0"+(verlof.singleDatum.getMonth()+1)).slice(-2) + '/' + verlof.singleDatum.getFullYear().toString().slice(-2)}}</b></td>
      <td style="width:22%;line-height:1.5"  *ngIf="verlof.soort === 'periode'"><b>{{days[verlof.periodeStartDatum.getDay()] + ' ' +("0"+(verlof.periodeStartDatum.getDate())).slice(-2) + '/' +("0"+(verlof.periodeStartDatum.getMonth()+1)).slice(-2) + '/' + verlof.periodeStartDatum.getFullYear().toString().slice(-2)}}</b>
        <br>tot <b>{{days[verlof.periodeEindDatum.getDay()] + ' ' +("0"+(verlof.periodeEindDatum.getDate())).slice(-2) + '/' +("0"+(verlof.periodeEindDatum.getMonth()+1)).slice(-2) + '/' + verlof.periodeEindDatum.getFullYear().toString().slice(-2)}}</b></td>
      <td  style="width:30%">Type verlof: {{convertVerlofToRedenText(verlof)}}</td>
    </tr>
  </table>
</div>

