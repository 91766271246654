<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <i class="fa-solid fa-bars fa-2x"></i>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Selux werklogger</a>
    <img src="assets/logo.png" style="width:50px !important;height:50px !important;">
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
<div *ngIf="heeftTestObjecten && (userRole === 'administrator' || userRole === 'werfleider')" class="heeftTestObjectenDiv">
  <div class="testObjectTextColumn">
    <b class="testObjectText" style="color: #ff0000;">Opgelet: Er zijn nog testobjecten actief binnen uw bedrijf!</b>
    <p class="testObjectText" ><b>Testobjecten:</b> 5 activiteiten, 5 machines, 5 materialen, 5 werven,<br> 5 arbeiders, 2 onderaannemers</p>
  </div>
  <button nbButton status="warning" size="small" (click)="verwijderTestObjecten()">Testobjecten<br>verwijderen</button>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton status="basic"  style="margin-left:40px !important;background-color: #d6e6ff" (click)="openUpdateWindow()">
        Updates
      </button>
      <nb-badge *ngIf="isRecentNewUpdate" text="nieuw" status="success" position="top right" style="position: absolute; top: -10px; right: -10px;"></nb-badge>

    </nb-action>
  </nb-actions>
  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton status="info" style="margin-left:40px !important;" (click)="openHandleiding()">
        Handleiding
      </button>
    </nb-action>
  </nb-actions>
  <nb-actions size="small">
    <nb-action class="control-item">
      <button nbButton outline status="danger" (click)="onLogoutClick()">
        Afmelden
      </button>
    </nb-action>
  </nb-actions>
</div>
