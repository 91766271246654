import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService, NbToastrService,
} from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import {NbAuthService, NbTokenStorage} from '@nebular/auth';
import {UpdatePopupDialogComponent} from "../../../pages/update-popup-dialog/update-popup-dialog.component";
import {
  AanmaakvolgordePopupDialogComponent
} from "../../../pages/aanmaakvolgorde-popup-dialog/aanmaakvolgorde-popup-dialog.component";
import {ApiService} from "../../../services/api.service";
import {FormService} from "../../../services/form.service";
import {MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import moment from "moment";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  isLoaded: boolean = false;
  userRole: string;
  heeftTestObjecten: boolean;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  languageSelect: any;
  afmelden: string = '';
  isRecentNewUpdate: boolean = false;
  updateDate: Date = new Date(2024,6,11,0,0);

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private authService: NbAuthService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private tokenStorage: NbTokenStorage,
    private formService: FormService,
    private toastrService: NbToastrService
  ) {}

  async ngOnInit() {
      const { xl } = this.breakpointService.getBreakpointsMap();
      this.themeService
        .onMediaQueryChange()
        .pipe(
          map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
          takeUntil(this.destroy$),
        )
        .subscribe(
          (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
        );
      this.themeService
        .onThemeChange()
        .pipe(
          map(({ name }) => name),
          takeUntil(this.destroy$),
        )
        .subscribe((themeName) => (this.currentTheme = themeName));


      while(this.apiService.thisCompany == null){
        await this.delay(50);
      }

    //use moment and look if its older than 1 month
    let dateWithOneMonth = moment(this.updateDate).add(1, 'months').toDate();

    if(dateWithOneMonth > new Date()){
      this.isRecentNewUpdate = true;
    } else {
      this.isRecentNewUpdate = false;
    }
    this.userRole = this.apiService.role;
    this.heeftTestObjecten = this.apiService.thisCompany.heeftTestObjecten;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onLogoutClick() {
    this.authService.logout('email').subscribe(() => {
      this.tokenStorage.clear();
      this.formService.allWerven = null;
      this.formService.allMachines = null;
      this.formService.allWerkzaamheden = null;
      this.formService.allUsers = null;
      this.formService.allOnderaannemers = null;
      this.formService.filterUsers = null;
      this.formService.allChauffeurs = null;
      this.formService.comingFromAutoLogin = false;
      this.formService.company = null;
      this.router.navigate(['auth/login']); // Use Angular's router to navigate
    });
  }

  openUpdateWindow() {
    const dialogRef = this.dialog.open(UpdatePopupDialogComponent, {
      width: '800px',
      panelClass: 'mat-dialog-padding'
    });
  }

  openHandleiding() {
    const dialogRef = this.dialog.open(AanmaakvolgordePopupDialogComponent, {
      height: '700px',
      width: '800px',
      panelClass: 'mat-dialog-padding'
    });
  }

  verwijderTestObjecten() {
    console.log('verwijder test objecten');
    const dialogRef = this.dialog.open(DeleteDialogTestObjecten, {
      width:'600px',
      panelClass: 'mat-dialog-padding'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(this.formService.isDelete){
        this.apiService.deleteTestObjectsOfCompany().subscribe(x => {
          this.apiService.thisCompany.heeftTestObjecten = false;
          this.heeftTestObjecten = false;
          this.toastrService.success('De testobjecten zijn succesvol verwijderd', 'Succes!' , {duration: 3500});
        });
      }
    });

  }
}


@Component({
  selector: 'delete-dialog-test-objecten',
  templateUrl: 'delete-testobjects-dialog.html',
  imports: [
    MatButton,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions
  ],
  standalone: true
})
export class DeleteDialogTestObjecten {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogTestObjecten>,
    public formService: FormService
  ) {
    this.formService.isDelete = false;
  }

  onNoClick(): void {
    this.formService.isDelete = false;
    this.dialogRef.close();
  }

  onDeleteClick() {
    this.formService.isDelete = true;
    this.dialogRef.close();
  }
}

