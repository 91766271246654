<app-download-popup [showPopup]="isGeneratingPidpaPdf || formService.isGeneratingStaatDerVerlet"></app-download-popup>

<div  class="topRow">
   <h1 style="min-width:150px !important;margin-left: 5px !important;" class="custom-title"><i class="fa-solid fa-book-open bigFaIcon"></i>Dagboek<br>der werken</h1>
  <button class="showWervenButton" nbButton ghost (click)="showWervenOfWerfleider = !showWervenOfWerfleider">
    <nb-icon pack="fa" [icon]="showWervenOfWerfleider ? 'chevron-up' : 'chevron-down'"></nb-icon>
  </button>
   <div style="display:flex;flex-direction:column;align-items: center;justify-content: center;margin-left: 35px">
     <div class="shorterFlex">
       <p class="sortText">Werf</p>
       <div class="colFlex">
         <input [disabled]="isViewingDay" #autoInput
                nbInput
                type="text"
                (input)="onChange()"
                placeholder="Kies een werf.."
                [nbAutocomplete]="auto" class="autoComplete" />
         <nb-autocomplete (selectedChange)="clickAutoCompleteWerf($event)" #auto>
           <nb-option *ngFor="let option of observableFilteredItems$ | async" [value]="option">
             {{ option }}
           </nb-option>
         </nb-autocomplete>

         <button [disabled]="isViewingDay" class="closeButton" [ngClass]="autoInput.value == null || autoInput.value === '' ? 'hideButton' : ''"
                 nbButton status="warning" (click)="clearAutocomplete()">
           <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
         </button>
       </div>
     </div>
     <h3 *ngIf="geenStartDatumOpWerf && autoInput?.value != null && autoInput.value !== ''" style="font-weight:bold;margin-top:10px;border:1px solid black;border-radius:5px;padding:5px">Er is geen bevel van aanvang ingesteld op deze werf.<br> Hierdoor kan u geen dagboeken opslaan.</h3>
   </div>
  <div class="shorterFlex">
    <p class="sortText">Datum</p>
    <div class="colFlex">
      <div class="monthSelector">
        <button [disabled]="isViewingDay" (click)="previousMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-left-long"></i></button>
        <nb-select [disabled]="isViewingDay" class="bigSelect" (selectedChange)="checkMaand($event)" [ngModel]="maand">
          <nb-option *ngFor="let maand of maanden" [value]="maand">{{ maand }}</nb-option>
        </nb-select>
        <button [disabled]="isViewingDay" (click)="nextMonth()" class="arrow-button"><i style="font-size: 23px" class="fa fa-arrow-right-long"></i></button>
      </div>
      <nb-select [disabled]="isViewingDay" class="jaarSelect" (selectedChange)="checkJaar($event)" [ngModel]="jaar">
        <nb-option *ngFor="let jaar of jaren" [value]="jaar">{{ jaar }}</nb-option>
      </nb-select>
    </div>
  </div>
  <button (click)="verwijderDagboeken()" *ngIf="canDeleteDagboeken" mat-raised-button class="dagboek-delete-button" color="warn" type="button"><div class="divInsideButton">Dagboeken verwijderen<i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></div></button>
  <button *ngIf="!isViewingDay && !canDeleteDagboeken && !isGeneratingPidpaPdf" mat-raised-button (click)="printPidpaDagboeken()" class="add-button-pidpa">
    <div class="divInsideButton"><i style="font-size: 27px;margin-right: 12px" class="fa fa-file-pdf"></i>Dagboek der Werken</div></button>
  <button *ngIf="!isViewingDay && !formService.isGeneratingStaatDerVerlet && !canDeleteDagboeken" mat-raised-button (click)="generateStaatDerVerletdagen()" color="green" class="add-button-staat">
    <div class="divInsideButton"><i style="font-size: 27px;margin-right: 12px" class="fa fa-file-excel"></i>Excel staat der<br>verlet Fluvius</div></button>
</div>
<h2 class="geenWerfText" *ngIf="currentWerf == null && !(showWervenOfWerfleider && wervenOfThisWerfleider != null && wervenOfThisWerfleider.length !== 0)">
  <i style="height:10px;width:10px; margin-right:15px" class="fas fa-info-circle"></i>
  Kies hierboven een werf
  <i class="fas fa-arrow-up"></i>
</h2>

<div style="max-width: 1200px !important;" class="container">
  <h2 *ngIf="showWervenOfWerfleider && wervenOfThisWerfleider != null && wervenOfThisWerfleider.length !== 0" class="infoText8">
    Werven waarvan u werfleider bent:
  </h2>
  <div *ngIf="showWervenOfWerfleider && wervenOfThisWerfleider != null && wervenOfThisWerfleider.length !== 0" class="werven-container">
    <div (click)="selectWerfOfWerfleider(werf)" *ngFor="let werf of wervenOfThisWerfleider" class="werf">
      <p style="color:white"><b>{{ werf.naam }}</b></p>
      <i style="color: purple;" class="fa-solid fa-square-arrow-up-right"></i>    </div>
  </div>
  <h3 *ngIf="showWervenOfWerfleider && (wervenOfThisWerfleider == null ||  wervenOfThisWerfleider.length === 0)" class="no-werf-leader">
    U bent nog geen werfleider van een bepaalde werf.
  </h3>
</div>



<div  *ngIf="currentWerf != null && isLoaded" class="outerInfoBox">
  <div class="infoRow">
    <p class="infoTextTop"><i class="fa-solid fa-circle-user"></i> {{currentWerf.werfleiders != null && currentWerf.werfleiders.length !== 0 ?
      showWerfleiderNames(currentWerf.werfleiders) : 'Geen werfleider'}}</p>
    <p *ngIf="currentWerf.uitvoeringsTermijn != null" class="infoTextTop">Uitvoeringstermijn: <b>{{currentWerf.uitvoeringsTermijn}}</b> dagen</p>
    <p *ngIf="latestDagboek != null" class="infoTextTop">Verstreken dagen: <b>{{latestDagboek.bladNummer}}</b></p>
    <p *ngIf="firstDagboek && latestDagboek" class="infoTextTop"><i class="fa-solid fa-calendar-days"></i> Dagboeken: <b>{{dateToString(this.firstDagboek.date)}}</b> tot <b>{{dateToString(this.latestDagboek.date)}}</b></p>
    <p class="infoTextTop" *ngIf="currentWerf != null &&  currentWerf.uitvoeringsTermijn == null" style="font-size: 15px;max-width:400px">
      <b>Gelieve een uitvoeringstermijn op deze werf  in te stellen bij werfinstellingen.</b>
    </p>
  </div>

</div>


<div *ngIf="isLoaded"  cdkScrollable class="scroll-table-verlet stylish-table">
  <div   *ngIf="!isViewingDay" class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
    <div [formGroup]="deleteDagboekForm"  *ngIf="!isViewingDay" class="calendar-grid">
      <ng-container  *ngFor="let day of daysInMonth$ | async;">
        <div  (click)="bekijk(day)" *ngIf="!day.isEmpty; else emptyBlock"
             [ngClass]="!day.hasDagboek && nextDagboekWithNumbers != null && day.soort !== 'vroegereDatum' && day.soort !== 'latereDatum' && day.soort !== 'feestdag' && day.soort !== 'verlofdag'? 'kanOpslaan': day.soort"
             class="day-container">
          <div class="topRowBox">
            <p class="datumText"><strong>{{day.date.getDate() + ' ' + maanden[day.date.getMonth()] }}</strong></p>
            <p class="soortText" *ngIf="day.soort !== 'latereDatum' && day.soort !== 'vroegereDatum'">{{day.soort.toUpperCase()}} <span style="padding-right: 25px;padding-left:6px; color:blue" *ngIf="!day.hasDagboek"> ?</span></p>
            <nb-checkbox class="delete-checkbox" (click)="changeDeleteDagboek(day.isoDate)" *ngIf="day.hasDagboek"  [formControlName]="day.isoDate"></nb-checkbox>
          </div>
          <div  *ngIf="day.hasDagboek" class="topRowBox2">
            <p class="aantalActiviteitenText" *ngIf="day.werkenCount !== 0"><strong><i style="color:black !important;" class="fa fa-person-digging faIcon"></i> {{day.werkenCount}}</strong> act.</p>
            <p class="werkUrenSpan"><span class="borderWerkuren" *ngIf="day.werkUren !== '0U'"><i style="color:black !important;" class="fa fa-clock faIcon"></i> {{day.werkUren}}</span></p>
            <p class="smallText">W{{day.dagboek.werkDagNummmer}} | V{{day.dagboek.verletDagNummer}}</p>
          </div>
            <p *ngIf="nextDagboekDate != null && nextDagboekDate.toISOString().split('T')[0] === day.isoDate && nextDagboekDate?.getTime() <= todayDate.getTime() " class="beginGoedkeurenText">
              Ga hier verder met het opslaan van dagboeken <i class="fa-solid fa-arrow-up-right-from-square faIcon"></i></p>
          <div  *ngIf="currentWerf.startDatum != null && currentWerf.startDatum.toISOString().split('T')[0] === day.date.toISOString().split('T')[0]" style="min-height:20px !important; margin-top:-8px !important;" class="rowFlex5">
            <p style="color:black;font-weight: bold; padding:5px; border:1px solid blue;border-radius:5px; margin:10px 0 0 0 !important;">STARTDATUM</p>
          </div>
          <div *ngIf="day.dagboek != null" class="employeeRow">
            <div *ngIf="day.dagboek.arbeiderCount != null && day.dagboek.arbeiderCount !== 0" class="employeeBox">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{day.dagboek.arbeiderCount}}</span> arbeider{{day.dagboek.arbeiderCount === 1 ? '' : 's'}}</p>
            </div>
            <div *ngIf="day.dagboek.machinistenCount != null && day.dagboek.machinistenCount !== 0" class="employeeBox">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{day.dagboek.machinistenCount}}</span> machinist{{day.dagboek.machinistenCount === 1 ? '' : 'en'}}</p>
            </div>
            <div *ngIf="day.dagboek.onderaannemersCount != null && day.dagboek.onderaannemersCount !== 0" class="employeeBox">
              <i class="fa fa-handshake faIcon"></i>
              <p><span class="valueText">{{day.dagboek.onderaannemersCount}}</span> onderaannemer{{day.dagboek.onderaannemersCount === 1 ? '' : 's'}}</p>
            </div>
            <div *ngIf="day.dagboek.chauffeursCount != null && day.dagboek.chauffeursCount !== 0" class="employeeBox">
              <i class="fa fa-truck faIcon"></i>
              <p><span class="valueText">{{day.dagboek.chauffeursCount}}</span> chauffeur{{day.dagboek.chauffeursCount === 1 ? '' : 's'}}</p>
            </div>
            <div *ngIf="day.dagboek.machinesCount != null && day.dagboek.machinesCount !== 0" class="employeeBox">
              <i class="fa fa-snowplow faIcon"></i>
              <p><span class="valueText">{{day.dagboek.machinesCount}}</span> machine{{day.dagboek.machinesCount === 1 ? '' : 's'}}</p>
            </div>
          </div>
        </div>
        <ng-template #emptyBlock><div  class="day-container empty"></div></ng-template>
        <div *ngIf="day.day === 'Vrijdag'" class="break"></div>
      </ng-container>
    </div>
  <div  [formGroup]="staatForm"  *ngIf="isLoaded && isViewingDay">
    <div class="header">
      <button mat-raised-button class="nav-button-black" (click)="bekijk(null)">Sluiten</button>
        <p class="headerText">{{days[currentDate.getDay()]}} - {{("0"+(currentDate.getDate())).slice(-2)}}/{{("0"+((currentDate.getMonth()) + 1) ).slice(-2)}}/{{currentDate.getUTCFullYear()}}</p>
      <div class="form-row">
        <label style="width:50px !important" for="soort">Soort:</label>
        <nb-select (selectedChange)="changeNumbersOfDay($event)" class="big-input" id="soort" formControlName="soortVerlet">
          <nb-option value="werkdag">Werkdag</nb-option>
          <nb-option value="weerverlet">Weerverlet (regen/vorst)</nb-option>
          <nb-option value="ontoegankelijk">Terrein ontoegankelijk</nb-option>
          <nb-option value="schorsing">Schorsing der werken</nb-option>
          <nb-option value="verlofdag">Verlofdag</nb-option>
          <nb-option value="feestdag">Feestdag</nb-option>
        </nb-select>
      </div>
      <div *ngIf="currentDagboek == null && nextDagboekDate != null && currentDate.toISOString().split('T')[0]  === nextDagboekDate.toISOString().split('T')[0] "  class="form-row">
        <p class="countText">{{currentCount}}\{{totalPossibleDayCount}}
      </div>
      <button *ngIf="currentCount === totalPossibleDayCount && currentDate.toISOString().split('T')[0]  <= nextDagboekDate.toISOString().split('T')[0]" (click)="saveDagboek()" mat-raised-button class="save-button2" color="primary">Opslaan</button>
      <button *ngIf="nextDagboekDate != null && currentDate.toISOString().split('T')[0]  === nextDagboekDate.toISOString().split('T')[0] &&
      currentDate.toISOString().split('T')[0] !== todayDate.toISOString().split('T')[0] && lastDayOfMonth !== currentDate.getDate()" (click)="saveDagboek(true)" mat-raised-button class="save-button2" color="primary">
        <div style="display: flex;">Opslaan en volgende <i style="font-size: 20px;margin-left: 8px" class="fa fa-arrow-right"></i></div></button>
      <button *ngIf="_id != null" mat-raised-button class="delete-button" color="warn" type="button"
              (click)="onDeleteClick()"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
    </div>
    <div *ngIf="company.heeftKopieerDagboeken && currentDagboek == null && nextDagboekDate != null && currentDate.toISOString().split('T')[0]  <= nextDagboekDate.toISOString().split('T')[0] && this.kopieerDagboeken != null && this.kopieerDagboeken.length !== 0" class="kopieerDagrapportDiv">
      <button  (click)="kopieerDagboek()" mat-raised-button class="kopieerDagrapportButton" color="primary">Kopieer vorig dagboek</button>
      <nb-select  style="width:700px; max-width:700px !important;"  [ngModelOptions]="{standalone: true}" [(ngModel)]="kopieerDagboekSelect">
        <nb-option *ngFor="let dagboek of kopieerDagboeken" value="{{dagboek._id}}">
          <span style="min-width:185px">{{days[dagboek.date.getDay()]}} {{dagboek.date | date}} <br>
            <span [ngClass]="dagboek.soortVerlet" style="padding:2px;color: black">{{dagboek.soortVerlet.toUpperCase()}}</span> {{dagboek.werkzaamheden != null && dagboek.werkzaamheden.length !== 0? '(' + dagboek.werkzaamheden.length + ' act.) ' : ' '}}</span>
        <br>
          <div class="kopieerFlexWrapEmployees">
            <div  *ngIf="dagboek.arbeiderCount != null && dagboek.arbeiderCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{dagboek.arbeiderCount}}</span> arbeider{{dagboek.arbeiderCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.machinistenCount != null && dagboek.machinistenCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-user faIcon"></i>
              <p><span class="valueText">{{dagboek.machinistenCount}}</span> machinist{{dagboek.machinistenCount === 1 ? ' ' : 'en '}}</p>
            </div>
            <div *ngIf="dagboek.onderaannemersCount != null && dagboek.onderaannemersCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-handshake faIcon"></i>
              <p><span class="valueText">{{dagboek.onderaannemersCount}}</span> onderaannemer{{dagboek.onderaannemersCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.chauffeursCount != null && dagboek.chauffeursCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-truck faIcon"></i>
              <p><span class="valueText">{{dagboek.chauffeursCount}}</span> chauffeur{{dagboek.chauffeursCount === 1 ? ' ' : 's '}}</p>
            </div>
            <div *ngIf="dagboek.machinesCount != null && dagboek.machinesCount !== 0" class="employeeBoxSmall">
              <i class="fa fa-snowplow faIcon"></i>
              <p><span class="valueText">{{dagboek.machinesCount}}</span> machine{{dagboek.machinesCount === 1 ? ' ' : 's '}}</p>
            </div>
          </div>
        </nb-option>
      </nb-select>
    </div>
    <h4 class="zelfdeDagInfoText" *ngIf="todayDate.toISOString().split('T')[0] === currentDate.toISOString().split('T')[0]">
      Opgelet: Deze dag is nog niet afgerond. Als u opslaat voordat alle arbeiders hun rapporten hebben ingevoerd, worden deze niet opgenomen in het dagboek.
    </h4>
    <div class="container">
      <div class="edit-form">
        <div class="firstRow">
          <div class="flexColumn">
            <div class="form-row">
              <label style="width:83px !important;" for="bladnummer">Bladnummer</label>
              <input [readOnly]="currentDagboek != null" formControlName="bladNummer" id="bladnummer" nbInput type="number" class="short-input">
            </div>

            <div class="form-row">
              <label style="width:83px !important;" for="werkdag-nr">Werkdag nr</label>
              <input [readOnly]="currentDagboek != null"  formControlName="werkDagNummmer" id="werkdag-nr" nbInput type="number" class="short-input">
            </div>

            <div class="form-row">
              <label style="width:83px !important;" for="verletdag">Verletdag nr</label>
              <input [readOnly]="currentDagboek != null"  formControlName="verletDagNummer" id="verletdag" nbInput type="number" class="short-input">
            </div>
          </div>
          <div class="flexColumn">


            <div style="min-height:40px !important;" class="form-row">
              <label style="width:75px !important;font-size: 15px">Werkuren</label>
              <label style="font-weight: bold">{{staatForm.controls['werkuren'].value != null && staatForm.controls['werkuren'].value !== '' ? staatForm.controls['werkuren'].value : ''}}</label>
            </div>
            <div class="form-row">
              <p class="uurText">Startuur</p>
              <div class="uurFlexer">
                <nb-select (selectedChange)="calculateWerkUren()" class="smallSelect" formControlName="uurStart">
                  <nb-option value="5">5</nb-option>
                  <nb-option value="6">6</nb-option>
                  <nb-option value="7">7</nb-option>
                  <nb-option value="8">8</nb-option>
                  <nb-option value="9">9</nb-option>
                  <nb-option value="10">10</nb-option>
                  <nb-option value="11">11</nb-option>
                  <nb-option value="12">12</nb-option>
                  <nb-option value="13">13</nb-option>
                  <nb-option value="14">14</nb-option>
                  <nb-option value="15">15</nb-option>
                  <nb-option value="16">16</nb-option>
                  <nb-option value="17">17</nb-option>
                  <nb-option value="18">18</nb-option>
                  <nb-option value="19">19</nb-option>
                </nb-select>
                <p class="infoText">Uur</p>
                <nb-select (selectedChange)="calculateWerkUren()" class="smallSelect" formControlName="minutenStart">
                  <nb-option value="0">0</nb-option>
                  <nb-option value="15">15</nb-option>
                  <nb-option value="30">30</nb-option>
                  <nb-option value="45">45</nb-option>
                </nb-select>
                <p class="infoText">Min</p>
              </div>
            </div>
            <div class="form-row">
              <p class="uurText">Einduur:</p>
              <div class="uurFlexer">
                <nb-select (selectedChange)="calculateWerkUren()" class="smallSelect" formControlName="uurEind">
                  <nb-option value="5">5</nb-option>
                  <nb-option value="6">6</nb-option>
                  <nb-option value="7">7</nb-option>
                  <nb-option value="8">8</nb-option>
                  <nb-option value="9">9</nb-option>
                  <nb-option value="10">10</nb-option>
                  <nb-option value="11">11</nb-option>
                  <nb-option value="12">12</nb-option>
                  <nb-option value="13">13</nb-option>
                  <nb-option value="14">14</nb-option>
                  <nb-option value="15">15</nb-option>
                  <nb-option value="16">16</nb-option>
                  <nb-option value="17">17</nb-option>
                  <nb-option value="18">18</nb-option>
                  <nb-option value="19">19</nb-option>
                </nb-select>
                <p class="infoText">Uur</p>
                <nb-select (selectedChange)="calculateWerkUren()" class="smallSelect" formControlName="minutenEind">
                  <nb-option value="0">0</nb-option>
                  <nb-option value="15">15</nb-option>
                  <nb-option value="30">30</nb-option>
                  <nb-option value="45">45</nb-option>
                </nb-select>
                <p class="infoText">Min</p>
              </div>
            </div>
          </div>
          <div *ngIf="!isLoadingWeather"  style="margin-left:8px;" class="flexColumn">
            <div class="temp-row">
              <label>Temperatuur</label>
              8h<input  formControlName="temp8h" nbInput type="number" class="tiny-input">°C
              <span style="margin-left:6px !important;">13h</span><input formControlName="temp13h" nbInput type="number" class="tiny-input">°C
            </div>
            <div class="form-row">
              <label for="regen">Regen gedurende uren</label>
              <input formControlName="regenGedurendeUren" id="regen" nbInput type="number" class="medium-input">
            </div>
            <div class="form-row">
              <label for="weergesteldheid">Weergesteldheid</label>
              <nb-select class="weer-input" id="weergesteldheid" formControlName="weergesteldheid">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
          </div>
          <div *ngIf="!isLoadingWeather" style="margin-right: 0 !important;" class="flexColumn">

            <div class="form-row">
              <label style="width:97px !important;" for="voormiddag">Voormiddag</label>
              <nb-select class="weer-input" id="voormiddag" formControlName="toestandVoormiddag">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>

            <div class="form-row">
              <label style="width:97px !important;"  for="namiddag">Namiddag</label>
              <nb-select class="weer-input" id="namiddag" formControlName="toestandNamiddag">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
            <div class="form-row">
              <label style="width:97px !important;"  for="vorige-nacht">Vorige nacht/weekend</label>
              <nb-select class="weer-input" id="vorige-nacht" formControlName="toestandVorigeNacht">
                <nb-option *ngFor="let weertoestand of simpeleWeersOmstandigheden" value="{{weertoestand}}">{{weertoestand}}</nb-option>
              </nb-select>
            </div>
          </div>
          <div *ngIf="isLoadingWeather" style="max-width: 520px !important;align-items: center; margin-right: 0px !important;padding-left: 40px;" class="flexColumn">
            <div  class="rowFlex5">
              <i style="font-size: 40px" class="fa fa-cloud-sun"></i>
              <p style="font-size: 18px;margin:0; padding:0 10px">Weerdata ophalen..</p>
              <mat-progress-spinner [mode]="'indeterminate'" [diameter]="35" ></mat-progress-spinner>
            </div>
            <p style="width: 100%;margin-top: 30px" >Indien dit blijft laden, gelieve het ADRES van deze werf in stellen bij<br> 'Materieel & werven' -> 'Werf bewerken' -> 'Adres/locatie'</p>
          </div>


          </div>


      <div style="width:1050px !important;" class="summary-container" formArrayName="werkzaamheden">
          <h3 style="width:100%;font-weight:500;margin-bottom:2px !important;margin-top:-10px;margin-left: 10px"><i class="fa fa-person-digging faIconBigger"></i> Uitgevoerde activiteiten</h3>
          <div class="werkzaamheidRow" *ngFor="let control of werkzaamhedenControls; let i = index">
                <i  (click)="removeWerkzaamheid(i)" *ngIf="werkzaamhedenControls.length - 1 !== i" class="fas fa-circle-minus minus-icon"></i>
            <div class="emptyWerkDiv" *ngIf="werkzaamhedenControls.length - 1 === i"></div>
            <input nbInput
                   [formControl]="control"
                   style="width: 700px !important;max-width:700px !important; height: auto;" />
          </div>
        <button type="button" (click)="addWerkzaamheid()" mat-raised-button color="primary" class="addWerkButton btn btn-primary">
          <i class="fas fa-plus"></i> Voeg extra activiteit toe
        </button>
      </div>

      <div style="padding:4px !important;" *ngIf="photoControls.length !== 0" class="summary-container" >
        <div class="fotoContainer">
            <p class="section-title">Extra foto's <small style="font-size: 10px;">*Komen voorlopig nog niet op de PDF bij selectie, alleen in excel (niet nuttig)</small></p>
            <div class="fotoRow" formArrayName="photos">
              <div  *ngFor="let photo of photoControls;let i = index" [formGroupName]="i">
                <div class="fotoColumn">
                  <div class="innerColumn" >
                    <div class="addFotoDiv">
                      <label class="addFotoLabel" for="file">Voeg foto toe aan dagboek</label>
                      <nb-checkbox class="checkbox" id=file style="margin: 17px 0 0 10px;" formControlName="addedFoto"></nb-checkbox>
                    </div>
                    <input nbInput class="fotoInput" id="name" type="text" placeholder="Extra foto" formControlName="fotoNaam">

                    <img *ngIf="photo?.value?.foto != null" src="{{photo.value.foto}}" class="photo" name="photo" (click)="imagePopUp(photo.value.foto)">
                    <p style="margin-bottom: 30%;font-size: 18px;font-weight: 600" *ngIf="photo?.value?.foto == null">Geen foto</p>
                  </div>
                </div>
              </div>
            </div>
      </div>
      </div>
      <div class="names-container">
        <div class="names-section">
          <p class="names-title"><i class="fa fa-user faIconBig"></i>Arbeiders<input id="werkdag-nr" formControlName="arbeiderCount" nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let arbeider of arbeiders">{{ arbeider.name }}</li>
          </ul>
        </div>
        <div class="names-section">
          <p class="names-title"><i class="fa fa-user faIconBig"></i>Machinisten  <input   formControlName="machinistenCount" nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let machinist of machinisten">{{ machinist.name }}</li>
          </ul>
        </div>
        <div class="names-section">
          <p class="names-title"><i class="fa fa-truck faIconBig"></i>Chauffeurs  <input formControlName="chauffeursCount"  nbInput type="number" class="person-short-input"> pers</p>
          <ul>
            <li  style="padding:6px 10px !important;" *ngFor="let chauffeur of chauffeurs">{{ chauffeur?.name }}</li>
          </ul>
        </div>
        <div formArrayName="machines" class="vast-materiaal-section">
          <p class="names-title"><i class="fa fa-snowplow faIconBig"></i>Machines (<strong>{{machinesGetter().controls?.length}}</strong>)</p>
          <span (click)="addMachine()" *ngIf="machinesGetter().controls.length === 0" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
          <ul class="big-input-3">
            <li *ngFor="let machine of machinesGetter().controls; let i=index" [formGroupName]="i">
              <i (click)="removeMachine(i)" class="fas fa-minus-circle minus-icon"></i>
              <input nbInput type="text" placeholder="Naam.." (input)="onChangeMachine($event,i)" formControlName="naam" #machineAutoInput [nbAutocomplete]="auto1" class="machineInput" />
              <nb-autocomplete  (selectedChange)="clickAutoCompletemachine($event,i)" #auto1>
                <nb-option *ngFor="let option of observableFilteredMachines$ | async" [value]="option">
                  {{ option }}
                </nb-option>
              </nb-autocomplete>
              <input formControlName="aantal" nbInput type="number" class="person-short-input">
            </li>
          </ul>
          <span (click)="addMachine()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
        </div>
        <div *ngIf="opmerkingen != null && opmerkingen.length !== 0"  class="materialen-section">
          <p class="names-title"><i class="fa fa-comment faIconBig"></i>Opmerkingen door arbeiders<br>
            <span style="font-weight:400">(komt niet in dagboek)</span>  </p>
          <ul *ngFor="let opmerking of opmerkingen">
            <li>
              <strong>{{opmerking}}</strong>
            </li>
          </ul>
        </div>

        <div class="names-section" >
          <p class="names-title"><i class="fa fa-handshake faIconBig"></i>Onderaannemers</p>
          <ng-container formArrayName="onderaannemerFirmas">
            <ul *ngFor="let onderaanneemFirma of onderaannemerFirmasControls;let i = index" [formGroupName]="i">
              <li style="padding: 6px 10px !important;">
                <i (click)="removeOnderaannemerFirma(i)" class="fas fa-minus-circle smaller-minus-icon"></i>
                <strong>Firma: {{ onderaanneemFirma.value.firmaNaam }}</strong>
              </li>
              <li style="padding: 6px 10px !important;"><input formControlName="arbeiderCount" nbInput type="number" class="person-short-input"> pers</li>
              <ng-container *ngIf="onderaanneemFirma.value.arbeiders != null && onderaanneemFirma.value.arbeiders.length !== 0">
                <li style="padding: 6px 10px !important;" *ngFor="let arbeider of onderaanneemFirma.value.arbeiders">
                  {{ arbeider.name }}
                </li>
              </ng-container>
            </ul>
          </ng-container>
          <ul>
            <li>
              Extra onderaannemer firma<span (click)="addOnderaannemerFirma()" style="position:initial !important;"  class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
              <input placeholder="Naam.." formControlName="extraOnderaannemerFirma" nbInput type="text" class="onderaannemerInput">
            </li>
          </ul>
        </div>
        <div class="vast-materiaal-section" formArrayName="materialen">
          <p class="names-title"><i class="fa fa-cube faIconBig"></i>Materialen (<strong>{{materialenControls.length}}</strong>)</p>
          <ul>
            <li [formGroupName]="i" class="big-input-3" *ngFor="let control of materialenControls; let i = index">
              <i (click)="removeMateriaal(i)"   class="fas fa-minus-circle minus-icon"></i>
              <input formControlName="naam" placeholder="Naam.."  nbInput type="text" class="materiaal-input">
              <input formControlName="hoeveelheid" placeholder="Hoeveelh.." nbInput type="number" class="medium-input">
            </li>
          </ul>
          <span (click)="addMateriaal()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
               </span>
        </div>
        <div class="vast-materiaal-section" formArrayName="vastMaterieelOpWerf">
          <p class="names-title">Permanent materieel op de werf<small> (bv. een elektriciteitskast) Wordt automatisch overgenomen in het volgende dagboek</small> (<strong>{{vasteMaterieelOpWerfControls?.length }}</strong>)</p>
          <ul>
            <li class="big-input-3" *ngFor="let control of vasteMaterieelOpWerfControls;let i = index" [formGroupName]="i">
                <i (click)="removeVastMateriaalOpWerf(i)" *ngIf="vasteMaterieelOpWerfControls.length >= 1"  class="fas fa-minus-circle minus-icon"></i>
              <input formControlName="naam"  placeholder="Naam.." nbInput type="text" class="machineInput">
              <input formControlName="aantal" placeholder="Aantal.."  nbInput type="number" class="person-short-input">
            </li>
          </ul>
          <div (click)="addVastMaterieelOpWerf()" class="custom-icon">
                <i class="fas fa-plus plus-icon"></i>
          </div>
        </div>
      </div>
      <div class="extraTextOuterDiv">
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-comment faIconBig"></i>Opmerkingen</p>
          <textarea nbInput (input)="limitLines($event)" formControlName="opmerkingen"  class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-flask-vial faIconBig"></i>Monstername - proeven</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="monsternameProeven"  class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText"><i class="fa fa-check-circle faIconBig"></i>Getroffen beslissingen</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="getroffenBeslissingen" class="opmerkingInput"></textarea>
        </div>
        <div class="extraTextRow">
          <p class="extraText">Bezoeken - allerlei</p>
          <textarea nbInput (input)="limitLines($event)"  formControlName="bezoekenAllerlei" class="opmerkingInput"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>

