<mat-dialog-content style="padding-bottom: 0px !important;">
  <h2 mat-dialog-title>Nodig bedrijf uit op platform</h2>
  <form style="display:flex;flex-direction: column">
    <mat-form-field appearance="fill" style="width:250px !important;">
      <mat-label>Naam</mat-label>
      <input matInput placeholder="Naam" [(ngModel)]="data.name" name="name">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:250px !important;">
      <mat-label>E-mailadres</mat-label>
      <input matInput placeholder="E-mailadres" [(ngModel)]="data.email" name="email">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:380px !important; ">
      <mat-label>Bericht</mat-label>
      <textarea style="height:180px !important;" matInput placeholder="Bericht" [(ngModel)]="data.message" name="message"></textarea>
    </mat-form-field>
  </form>
  <mat-dialog-actions style="padding: 0 40px">
    <button mat-raised-button (click)="closeDialog()" color="warn">Annuleren</button>
    <button mat-raised-button [mat-dialog-close]="data" color="primary">Verzenden</button>
  </mat-dialog-actions>
</mat-dialog-content>

