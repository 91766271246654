import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {VasteVerlofdagenBouwService} from "../../services/vasteVerlofdagenBouw.service";
import {BehaviorSubject, forkJoin, mapTo, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Werf} from "../../models/werf";
import {Werfplanning} from "../../models/werfplanning";
import {User} from "../../models/user";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";

class DayInMonth {
  date: Date;
  day: string;
  isoDate: string;
  isEmpty: boolean;
  soort: string;
}
@Component({
  selector: 'ngx-werfplanning',
  templateUrl: './werfplanning.component.html',
  styleUrls: ['./werfplanning.component.scss']
})
export class WerfplanningComponent implements OnInit {
  days: string[] = ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'];
  settingsForm: UntypedFormGroup;
  company: Company;
  isLoaded: boolean;
  isSaving: boolean = false;
  isViewingDay: boolean;
  maanden = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September", "Oktober","November","December"];
  todayDate: Date;
  jaren: number[] = [];
  currentDate: Date;
  maand: string;
  daysInMonth: BehaviorSubject<DayInMonth[]> = new BehaviorSubject<DayInMonth[]>(null);
  daysInMonth$: Observable<DayInMonth[]> = this.daysInMonth.asObservable();
  lastDayOfMonth: number;
  jaar: string;
  werven: Werf[];
  werfPlanningen: Werfplanning[] = [];
  allUsers: User[];
  isLoadedCount: number;
  assignmentForm: FormGroup;
  selectedDate: Date;
  unassignedArbeiders: User[];
  selectedArbeiders: any[] = [];


  constructor(
    private apiService: ApiService,
    private formService: FormService,
    private vasteVerlofDagenBouw: VasteVerlofdagenBouwService, private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
  }
  async ngOnInit() {
    this.isLoadedCount = 0;
    const company$ = this.company == null ? this.apiService.getCompany().pipe(
      tap(x => {
        this.company = x as Company;
        if(this.company._id == null){
          this.company._id = this.company.id;
        }
        this.formService.company = this.company;
      }),
      mapTo(1)
    ) : of(1);
    const werven$ = this.werven == null ? this.apiService.getWerven(true,false,true, false).pipe(
      tap(x => {
        this.werven = x as Werf[];
        this.werven  = this.werven.sort((a, b) => a.naam.localeCompare(b.naam));
        for(let werf of this.werven){
          if(!werf.isActiefOpWerfPlanning && werf.uitvoeringsTermijn != null && werf.startDatum != null){
            let startDatum = new Date(werf.startDatum);
            let eindDatum = new Date(werf.startDatum);
            eindDatum.setDate(eindDatum.getDate() + werf.uitvoeringsTermijn);

            let difference = Math.abs(eindDatum.getTime() - startDatum.getTime());
            let days = difference / (1000 * 3600 * 24);
            if(days > werf.uitvoeringsTermijn){
              werf.isActiefOpWerfPlanning = true;
              this.apiService.updateIsWerfActief(werf._id, true).subscribe();
            }
          }
        }
        //shift werven isactief to front of array
        this.werven = this.werven.sort((a, b) => a.isActiefOpWerfPlanning === b.isActiefOpWerfPlanning ? 0 : a.isActiefOpWerfPlanning ? -1 : 1);
      }),
      mapTo(1)
    ) : of(1);

    const users$ = this.allUsers == null ? this.apiService.getUsers().pipe(
      tap(async x => {
        this.allUsers = x as User[];
        this.allUsers.sort((a,b) => a.name?.localeCompare(b.name));
        this.allUsers = this.allUsers.filter(x => x.name !== 'bjorn massoels');
      }),
      mapTo(1)
    ) : of(1);

    forkJoin([company$, werven$, users$])
      .pipe(tap(([count1, count2, count3]) => this.isLoadedCount = count1 + count2 + count3))
      .subscribe();

    while(this.isLoadedCount < 3){
      await this.delay(50);
    }
    this.isLoaded = false;
    this.isSaving = false;
    this.isViewingDay = false;
    this.todayDate = new Date();
    this.currentDate = new Date();
    this.jaren.push(this.todayDate.getFullYear() - 1);
    this.jaren.push(this.todayDate.getFullYear());
    //push de volgende 5 jaren
    for(let i = 1; i < 5; i++){
      this.jaren.push(this.todayDate.getFullYear() + i);
    }
    this.jaar = this.todayDate.getFullYear().toString();
    this.maand = this.maanden[this.currentDate.getMonth()];
    await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());
    this.werfPlanningen = this.werven.map(w => {
      const planning = new Werfplanning();
      planning.werf = w;
      planning.selectedArbeiders = [];
      planning.opmerkingAanArbeiders = '';
      return planning;
    });
    this.unassignedArbeiders = this.allUsers.filter(x => x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438504');
    this.assignmentForm = this.fb.group({
      arbeiders: [],
      werf: '',
      opmerkingAanArbeiders: ''
    });
    this.isLoaded = true;

  }
  checkJaar($event: any) {
    this.isLoaded = false;
    if($event > this.currentDate.getFullYear()){
      this.maand = this.maanden[0];
      this.currentDate = new Date($event,0 , 1);
    } else if ($event < this.currentDate.getFullYear()) {
      this.maand = this.maanden[11];
      this.currentDate = new Date($event,11 , 1);
    }
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
  }

  checkMaand($event: any) {
    this.isLoaded = false;
    let maandIndex = this.maanden.indexOf($event);
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  async calculateDaysInMonth(month: number, year: number) {
    const datesObjects: any[] = [];
    while(this.company == null){
      await this.delay(50);
    }
    let eigenVerlofDagen = this.company.verlofDagen as Array<{fromDate: Date, toDate: Date}>;
    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    // Loop through the days of the month and generate Date objects
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      // Reset the time to 0 hour, 0 minute, 0 seconds
      date.setHours(4, 0, 0, 0);
      if(date.getDay() !== 0 && date.getDay() !== 6){
        let soort;
        let isoDate = date.toISOString().split('T')[0];
        if(year === 2023){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          } else {
            soort = 'werken';
          }
        } else if(year === 2024){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }  else if(year === 2025){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          }  else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }
        if(eigenVerlofDagen != null && eigenVerlofDagen.length !== 0){
          let verlofDag = eigenVerlofDagen.find(x => {
            let fromIso = x.fromDate ? new Date(x.fromDate).toISOString().split('T')[0] : null;
            let toIso = x.toDate ? new Date(x.toDate).toISOString().split('T')[0] : null;

            if (toIso == null) {
              return fromIso === isoDate;
            } else {
              return fromIso <= isoDate && isoDate <= toIso;
            }
          });
          if (verlofDag) {
            soort = 'verlofdag';
          }
        }
        datesObjects.push({date: date, day: this.days[date.getDay()], soort: soort, werkenCount: 0,isoDate: date.toISOString().split('T')[0], werkUren: 0});
      }
    }

    this.lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    //change to friday if the last day is saturday or sunday
    if(new Date(year, month + 1, 0).getDay() === 0){
      this.lastDayOfMonth = this.lastDayOfMonth - 2;
    } else if(new Date(year, month + 1, 0).getDay() === 6){
      this.lastDayOfMonth = this.lastDayOfMonth -1;
    }
    console.log(datesObjects);
    this.daysInMonth.next(datesObjects);
    let tempDaysInMonth = this.daysInMonth.getValue();
    this.daysInMonth$ = this.daysInMonth.pipe(
      map(daysArray => {
        // Calculate the index of the first day of the month
        const firstDayIndex = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), tempDaysInMonth[0].date.getDate()).getDay();

        // Create an array of empty slots with a special flag
        const emptySlots = new Array(Math.max(0, firstDayIndex - 1)).fill({ isEmpty: true });

        // Merge the emptySlots array with the actual daysArray
        return [...emptySlots, ...daysArray];
      })
    );


  }

  bekijk(day: DayInMonth) {
      if(!this.isViewingDay){
        this.formService.selectedIsoDateWerfPlanning = day.isoDate;
        this.selectedDate = day.date;
        this.isViewingDay = true;

      } else {
        this.isViewingDay = false;
        this.formService.selectedIsoDateWerfPlanning = null;
      }
  }



  assignArbeiders() {
    const selectedArbeiderIds: string[] = this.assignmentForm.get('arbeiders').value;
    const selectedWerfId: string = this.assignmentForm.get('werf').value;
    const opmerking: string = this.assignmentForm.get('opmerkingAanArbeiders').value;

    const selectedWerf = this.werven.find(w => w._id === selectedWerfId);
    const selectedArbeiders = this.allUsers.filter(a => selectedArbeiderIds.includes(a._id));

    const existingPlanning = this.werfPlanningen.find(p => p.werf._id === selectedWerfId);
    if (existingPlanning) {
      existingPlanning.selectedArbeiders = selectedArbeiders;
      existingPlanning.opmerkingAanArbeiders = opmerking;
    } else {
      const newPlanning = new Werfplanning();
      newPlanning.werf = selectedWerf;
      newPlanning.selectedArbeiders = selectedArbeiders;
      newPlanning.opmerkingAanArbeiders = opmerking;
      newPlanning._id = Date.now().toString(); // Temporary ID generation
      this.werfPlanningen.push(newPlanning);
    }

    this.assignmentForm.reset({
      arbeiders: [],
      werf: '',
      opmerkingAanArbeiders: ''
    });
  }

  removePlanning(planning: Werfplanning) {
    const index = this.werfPlanningen.indexOf(planning);
    if (index >= 0) {
      this.werfPlanningen.splice(index, 1);
    }
  }

  getWerfName(werfId: string): string {
    return this.werven.find(w => w._id === werfId)?.naam || 'Onbekende werf';
  }

  getArbeiderNames(arbeiders: User[]): string {
    return arbeiders.map(a => a.name).join(', ');
  }
  convertDate(date: Date){
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('0' + date.getFullYear()).slice(-2);
  }
  drop(event: CdkDragDrop<User[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const itemsToTransfer = this.selectedArbeiders.length > 0 ? [...this.selectedArbeiders] : [event.item.data];

      // Remove selected items from the previous container
      if (event.previousContainer.data === this.unassignedArbeiders) {
        this.unassignedArbeiders = this.unassignedArbeiders.filter(
          item => !itemsToTransfer.some(selected => selected._id === item._id)
        );
      } else {
        const sourceWerfplanning = this.werfPlanningen.find(wp => wp.selectedArbeiders === event.previousContainer.data);
        if (sourceWerfplanning) {
          sourceWerfplanning.selectedArbeiders = sourceWerfplanning.selectedArbeiders.filter(
            item => !itemsToTransfer.some(selected => selected._id === item._id)
          );
        }
      }

      // Insert items at the correct index in the new container
      if (event.container.data === this.unassignedArbeiders) {
        this.unassignedArbeiders.splice(event.currentIndex, 0, ...itemsToTransfer);
      } else {
        const targetWerfplanning = this.werfPlanningen.find(wp => wp.selectedArbeiders === event.container.data);
        if (targetWerfplanning) {
          targetWerfplanning.selectedArbeiders.splice(event.currentIndex, 0, ...itemsToTransfer);
        }
      }

      // Clear selection after drop
      this.selectedArbeiders = [];

      // Trigger change detection
      this.cdr.detectChanges();
    }
  }
  changeIsWerfActive(werfplanning: Werfplanning) {
    if(!werfplanning.werf.isActiefOpWerfPlanning){
      werfplanning.werf.isActiefOpWerfPlanning = true;
    } else {
      werfplanning.werf.isActiefOpWerfPlanning = false;
    }
    this.apiService.updateIsWerfActief(werfplanning.werf._id, werfplanning.werf.isActiefOpWerfPlanning).subscribe();
    let werfIndex = this.werfPlanningen.findIndex(x => x.werf._id === werfplanning.werf._id);
    this.werfPlanningen[werfIndex].werf.isActiefOpWerfPlanning = werfplanning.werf.isActiefOpWerfPlanning;
    this.werfPlanningen  = this.werfPlanningen.sort((a, b) => a.werf.naam.localeCompare(b.werf.naam));
    this.werfPlanningen = this.werfPlanningen.sort((a, b) => a.werf.isActiefOpWerfPlanning === b.werf.isActiefOpWerfPlanning ? 0 : a.werf.isActiefOpWerfPlanning ? -1 : 1);
  }

  getAllArbeiders(): User[] {
    return [
      ...this.unassignedArbeiders,
      ...this.werfPlanningen.flatMap(wp => wp.selectedArbeiders)
    ];
  }
  isSelected(arbeider: User): boolean {
    return this.selectedArbeiders.some(selected => selected._id === arbeider._id);
  }
  toggleSelection(arbeider: User, event: MouseEvent): void {
    event.preventDefault();
    const index = this.selectedArbeiders.findIndex(selected => selected._id === arbeider._id);
    if (index === -1) {
      this.selectedArbeiders.push(arbeider);
    } else {
      this.selectedArbeiders.splice(index, 1);
    }
  }
}
