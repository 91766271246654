import {Injectable} from "@angular/core";

@Injectable()
export class VasteVerlofdagenBouwService {
  rustDagenBouw2023: Date[] = [
    new Date('2023-10-30'),
    new Date('2023-10-31'),
    new Date('2023-12-26'),
    new Date('2023-12-27'),
    new Date('2023-12-28'),
    new Date('2023-12-29'),
  ];
  feestDagen2023: Date[] = [
    new Date('2023-08-15'),
    new Date('2023-11-01'),
    new Date('2023-11-13'),
    new Date('2023-12-25'),
  ];
  rustDagenBouw2024: Date[] = [
    new Date('2024-01-02'),
    new Date('2024-01-03'),
    new Date('2024-01-04'),
    new Date('2024-01-05'),
    new Date('2024-04-02'),
    new Date('2024-05-10'),
    new Date('2024-12-23'),
    new Date('2024-12-24'),
    new Date('2024-12-26'),
    new Date('2024-12-27'),
    new Date('2024-12-30'),
    new Date('2024-12-31'),

  ];
  feestDagen2024: Date[] = [
    new Date('2024-01-01'),
    new Date('2024-04-01'),
    new Date('2024-05-01'),
    new Date('2024-05-09'),
    new Date('2024-05-20'),
    new Date('2024-08-15'),
    new Date('2024-08-16'),
    new Date('2024-11-01'),
    new Date('2024-11-11'),
    new Date('2024-12-25'),
  ];
  rustDagenBouw2025: Date[] = [
    new Date('2025-01-02'),
    new Date('2025-01-03'),
    new Date('2025-04-18'),
    new Date('2025-05-02'),
    new Date('2025-05-30'),
    new Date('2025-11-10'),
    new Date('2025-12-22'),
    new Date('2025-12-23'),
    new Date('2025-12-24'),
    new Date('2025-12-26'),
    new Date('2025-12-29'),
    new Date('2025-12-30'),
    new Date('2025-12-31')
  ];
  feestDagen2025: Date[] = [
    new Date('2025-01-01'),
    new Date('2025-04-21'),
    new Date('2025-05-01'),
    new Date('2025-05-29'),
    new Date('2025-06-09'),
    new Date('2025-07-21'),
    new Date('2025-08-15'),
    new Date('2025-11-01'),
    new Date('2025-11-11'),
    new Date('2025-12-25')
  ];
}
