<div style="text-align: start;padding:14px;">
  <p style="font-weight: bold;">Uw aanpassingen zijn nog niet opgeslagen!</p>
  <p >Bent u zeker dat u deze pagina wilt verlaten zonder op te slaan?</p>
  <div style="display: flex;flex-direction: row; justify-content:space-between; align-items:center">
    <button style="margin-left:30px;width:100px;height:40px;font-size:15px;background-color: #f4f8ff"  mat-raised-button (click)="closeDialog()" >
      Annuleren
    </button>
    <button style="margin-right:30px;width:100px;height:40px;font-size:15px"  mat-raised-button color="warn" (click)="doorgaan()" >
      Verlaten
    </button>
  </div>
</div>
