import {User} from "./user";


export class Verlof {
  public _id: string;
  public naam: string;
  public verlofOntvanger: User;
  public aanvraagDatum: Date;
  public soort: string;
  public singleDatum: Date;
  public periodeStartDatum: Date;
  public periodeEindDatum: Date;
  public specialeSoort: string;
  public company_id: string;
  public user_id: User;
  public andereReden: string;

  public isApproved: boolean;
  public isRejected: boolean;
  afwijsReden: string;

  constructor(naam: string, _id: string) {
    this.naam = naam;
    this._id = _id;
  }
}
