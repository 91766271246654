<div class="header">
    <h1 class="custom-title"><i class="fa-solid fa-building bigFaIcon"></i>Klanten</h1>
  <button mat-raised-button color="primary" (click)="stelEigenBedrijfIn()">Bedrijfsgegevens instellen</button>
 <div class="outerDivFlexRow">
      <div class="colFlex">
        <mat-label class="sortText">Filter op bedrijf</mat-label>
        <div class="rowFlex5">
          <input #autoInput
                 nbInput
                 type="text"
                 (input)="onChange()"
                 placeholder="Filter op bedrijf.."
                 [nbAutocomplete]="auto" class="autoComplete" />
          <nb-autocomplete (selectedChange)="clickAutoCompleteBedrijf($event)" #auto>
            <nb-option *ngFor="let option of observableFilteredItems$ | async" [value]="option">
              {{ option }}
            </nb-option>
          </nb-autocomplete>
          <button class="closeButton" [ngClass]="autoInput.value == null || autoInput.value === '' ? 'hideButton' : ''"
                  nbButton status="warning" (click)="clearAutocomplete()">
            <nb-icon style="font-size: 22px" pack="fa" icon="xmark"></nb-icon>
          </button>
        </div>
      </div>
 </div>

    <button mat-raised-button (click)="addKlant()" *ngIf="!isOn" class="add-button">Toevoegen</button>
    <button mat-raised-button color="warn" (click)="isOn= false" *ngIf="isOn" class="add-button">Sluiten</button>

</div>

<ngx-klanten-add *ngIf="isOn" [klanten]="klanten" (outputEvent)="onAddKlant()"></ngx-klanten-add>

<div style=" height:calc(100vh - 190px) !important; width:1200px !important; " cdkScrollable class="scroll-table stylish-table">
    <table class="item-list">
      <thead>
      <tr class="item-box-2">
        <th style="max-width:130px !important;" class="action-container"></th>
        <th>Bedrijfsnaam</th>
          <th>Email</th>
          <th>Locatie</th>
          <th>Ondernemingsnummer<br>& vestigingen</th>
          <th>Is verbonden</th>
      </tr>
      </thead>
    <tbody>
      <tr class="item-box klant" *ngFor="let klant of klanten">
        <td  class="openButtonDiv">
            <button mat-raised-button class="nav-button" (click)="onClick(klant)">OPEN</button>
        </td>
        <td >{{klant.naam}}</td>
          <td>{{klant.email}}</td>
          <td>{{klant.straat}} {{klant.huisNr}}<br>{{klant.postcode}} {{klant.gemeente}}<br> {{klant.land}}</td>
          <td style="white-space: pre-line">
            <p style="margin-bottom: 5px;">{{klant.btwNummer}}</p>
            <p style="line-height: 30px;" *ngIf="klant.vestigingen != null && klant.vestigingen.length > 0"><span *ngFor="let vestiging of klant.vestigingen">{{vestiging.naam}}<br></span></p>
            <p *ngIf="klant.vestigingen == null || klant.vestigingen.length === 0">Geen vestiging</p>
          </td>
          <td *ngIf="!klant.realBedrijf">
            <p *ngIf="klant.tempRegistrationCode">* Uitnodiging verstuurd</p>
            <button style="background: lightblue" (click)="maakVerbinding(klant)"  nbButton >Klant uitnodigen</button>
          </td>
        <td  *ngIf="klant.realBedrijf"><p class="verbonden">VERBONDEN</p></td>
      </tr>
    </tbody>
    </table>
</div>
