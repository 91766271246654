
import {Werf} from "./werf";
import {User} from "./user";
import {Werk} from "./werk";
import {TransportWerk} from "./transportwerk";
import {Transportdagrapport} from "./transport-dagrapport";

export class Dagrapport {
  public _id: string;
  public id: string;
  public werf: Werf;
  public startDate: Date;
  public endDate: Date ;
  public date: Date;
  public handwerkers: User[];
  public werk: Werk;
  public werken: Werk[];
  public schaft: number;
  public isFinished: boolean;
  public user_id: User;
  public opmerkingen: string[];
  public arbeiderString: string;
  public extraWerken: Werk[];
  public deleted: boolean;
  public onderaannemers: User[];
  onderaannemerString: string;
  transportwerken: TransportWerk[];
  opmerkingenForPdf: string[];

  //tempvars
  werkUren: string;
  transportdagrapporten: Transportdagrapport[];
  //temp
  arbeiderCount: number;
  onderaannemerCount: number;
  constructor() {}
}
