<div class="update-container">
  <p class="update-header">Laatste updates:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-recycle"></i>Afwerking van <strong>de afvalstofmodule</strong></p>
  <p class="update-item"><i class="fa fa-user-check"></i><b>Verbeterde aanwezigheidslijst</b></p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-file-excel"></i><b> Verbeterde kostenberekening excel</b></p>
  <div class="version">Laatste versie mobiele app: V22.9</div>
</div>
<div class="update-container">
  <p class="update-header">Aankomende functies:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-circle-nodes"></i>Koppeling met API's van Grondbank & Grondwijzer in de transportmodule</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-bell"></i>Werfplanning en personeelstoewijzing met notificaties</p>
  <p class="update-item"><i style="padding-right: 3px" class="fa fa-sync-alt"></i><b>Doorlopende optimalisatie van alle modules</b></p>
</div>
