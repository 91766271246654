<div style="text-align: center;padding:14px">
  <p style="font-weight: bold;">Maand beëindigen en totalen opslaan voor volgende staat der verlet generatie? </p>
  <div style="display: flex; flex-direction:row;justify-content: space-evenly; margin: auto;">
    <div style='width:300px; border:1px solid black;border-radius: 5px; padding: 10px'>
      <p>Indien u volgend <b>vakje aanvinkt</b> wordt de <b>maand beëindigd</b> en worden de <b>totalen opgeslagen voor de volgende generatie van de staat der verlet.</b></p>
      <div style='display: flex;justify-content: center;width:100%; align-items: center'>
        <nb-checkbox type="checkbox" [(ngModel)]='checkbox'
        ></nb-checkbox>
      </div>
      <button class='button1'  mat-raised-button color="primary" (click)="saveTotalen()" >
        TOTALEN OPSLAAN +<br> DOWNLOAD STAAT DER VERLET
      </button>
    </div>
    <button class='button2' mat-raised-button color="primary" (click)="getExcel()" >
      DOWNLOAD STAAT DER VERLET
    </button>
  </div>
</div>



