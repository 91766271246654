import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'update-popup-dialog',
  templateUrl: './update-popup-dialog.component.html',
  styleUrls: ['./update-popup-dialog.component.scss']
})
export class UpdatePopupDialogComponent implements OnInit {
  text: any;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private formService: FormService,
    private router: Router,
    private toastrService: NbToastrService
  ) { }

  ngOnInit(): void {
  }



  closeDialog(){
    this.dialog.closeAll();
  }
  submit() {
    let text = this.text;
    this.apiService.sendComment(text).subscribe(x => {
      this.toastrService.success('Uw opmerking is verstuurd! U krijgt eventueel een antwoord terug via email', 'Succes!');
      this.dialog.closeAll();
    })
  }
}
