import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Countsdata } from '../models/countsdata';

@Injectable({
  providedIn: 'root',
})
export class NieuweCountService {
  private countSubject = new BehaviorSubject<Countsdata>(null);
  countsData$ = this.countSubject.asObservable();

  updateCounts(countsData: Countsdata) {
    this.countSubject.next(countsData);
  }

  getCurrentCounts(): Countsdata {
    return this.countSubject.getValue();
  }
}
