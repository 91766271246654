export const environment = {
  firebaseConfig: {
    projectId: 'werklogger-1a153',
    appId: '1:546761968465:web:e6c66b358a2a6cc90ee170',
    storageBucket: 'werklogger-1a153.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyBEqd3gIiZmLW7QVwl-IaUaEWUwHlUkXNU',
    authDomain: 'werklogger-1a153.firebaseapp.com',
    messagingSenderId: '546761968465',
    measurementId: 'G-M9H1JFGFDM',
  },
  production: true,
  //apiURL: "http://localhost:4100/v1/"
  apiURL: "https://tasklogger-node.herokuapp.com/v1/",
};
