import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './maak-connectie-dialog.component.html',
  styleUrls: ['./maak-connectie-dialog.component.scss']
})
export class MaakConnectieDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<MaakConnectieDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
  protected readonly close = close;
}
