import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import {NbTabsetComponent} from '@nebular/theme';
import {ApiService} from "../../services/api.service";
import {forkJoin, Observable, of} from "rxjs";
import {Dagrapport} from "../../models/dagrapport";
import {Transportdagrapport} from "../../models/transport-dagrapport";
import {User} from "../../models/user";
import {Router} from "@angular/router";
import {FormService} from "../../services/form.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'ngx-aanwezigheidslijst',
  templateUrl: './aanwezigheidslijst.component.html',
  styleUrls: ['./aanwezigheidslijst.component.scss', '../../styles/item-table.scss']
})
export class AanwezigheidslijstComponent implements OnInit {
  @ViewChild('autoInput') input;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChildren('userElement') userElements: QueryList<ElementRef>;
  @ViewChild('tabset') tabset: NbTabsetComponent;

  filterItemsNaam: User[] = [];
  observableFilteredItems$: Observable<string[]>;
  public isLoaded: boolean;
  public dagrapporten: Dagrapport[];
  public transportDagrapporten: Transportdagrapport[];
  currentWeekIndex: number;
  huidigeWeekIndex: number;
  currentWeek: any;
  weeks: any[];
  arbeiders:User[];
  chauffeurs:User[];
  onderaannemers:User[];
  filteredArray: User[];
  allUsers: User[];


  constructor(
    private apiService: ApiService,
    private router: Router,
    public formService: FormService,
  ) {}

  async ngOnInit(){

    let now = new Date();
    let daysToSubtract = (now.getDay() + 6) % 7;

    let startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - daysToSubtract);
    startOfWeek.setHours(0, 0, 0, 0);  // Set time to 00:00:00
    let endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4);  // Add 4 days to get to Friday
    endOfWeek.setHours(23, 59, 59, 999);  // Set time to 23:59:59
    this.weeks = this.getWeeksInYear(now.getFullYear());
    this.currentWeekIndex = this.weeks.findIndex(x => x.start.getTime() === startOfWeek.getTime());
    this.huidigeWeekIndex = this.currentWeekIndex;
    this.currentWeek = this.weeks.find(x => x.start.getTime() === startOfWeek.getTime());

    this.formService.aanwezigheidsLijstChosenSoort = this.formService.aanwezigheidsLijstChosenSoort || 'Arbeiders';

    if(this.formService.previousWeekIndexAanwezigsheidslijst){
      this.currentWeekIndex = this.formService.previousWeekIndexAanwezigsheidslijst;
      this.currentWeek = this.weeks[this.currentWeekIndex];
    }
    console.log(this.weeks)
    console.log(this.currentWeek);

    this.loadUsers();


    await this.getDagrapportenOfWeek(this.currentWeek.start, this.currentWeek.end);

    this.isLoaded = true;
    setTimeout(() => {
      this.scrollToPreviousUser();
    }, 100);
  }




  async loadUsers() {
          this.apiService.getUsers().subscribe(
            (users: User[]) => {
              const filteredUsers = users.filter(
                (x) => (x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438502' || x.role === '59cf78e883680012b0438504' ) && x.name !== 'bjorn massoels'
              );
              this.arbeiders = filteredUsers.filter(x => (x.role === '59cf78e883680012b0438501' || x.role === '59cf78e883680012b0438504') && x.functieNaam !== 'Onderaannemer');
              this.chauffeurs = filteredUsers.filter(x => x.role === '59cf78e883680012b0438502');
              this.onderaannemers = filteredUsers.filter(x => x.functieNaam === 'Onderaannemer');
              for(let user of this.arbeiders){
                // @ts-ignore
                user.days = [];
                user.totaleWerkMinuten = 0;
                for(let day of this.currentWeek.days){
                  // @ts-ignore
                  user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
                }
              }
              for(let user of this.chauffeurs){
                // @ts-ignore
                user.days = [];
                user.totaleWerkMinuten = 0;
                for(let day of this.currentWeek.days){
                  // @ts-ignore
                  user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
                }
              }
              for(let user of this.onderaannemers){
                // @ts-ignore
                user.days = [];
                user.totaleWerkMinuten = 0;
                for(let day of this.currentWeek.days){
                  // @ts-ignore
                  user.days.push({userId: user._id, day: day, werkMinuten: 0, activiteitenPerWerf: []});
                }
              }
              this.allUsers = [...this.arbeiders, ...this.chauffeurs, ...this.onderaannemers];
              this.filterItemsNaam = this.arbeiders;
              this.observableFilteredItems$ = of(this.arbeiders.map(x => x.name));
        });
  }


  private scrollToPreviousUser() {
    if (this.formService.previousUserIdAanwezigheidslijst) {
      const userId = this.formService.previousUserIdAanwezigheidslijst;
      const userIndex = this.filteredArray.findIndex(user => user._id === userId);
      if (userIndex !== -1) {
        this.scrollToUser(userIndex);
      }
    }
  }

  getDagrapportenOfWeek(date1: Date, date2: Date){
    const dagrapporten$ = this.apiService.getDagrapportenExcel(date1.toString(), date2.toString(), 'geen', 'geen', 'geen');
    const transportDagrapporten$ = this.apiService.getTransportDagrapportenExcel(date1.toString(), date2.toString(), 'geen', 'geen', 'geen');

    return new Promise<any>((resolve, reject) => {
      forkJoin([dagrapporten$, transportDagrapporten$]).subscribe(async ([dagrapportenResult, transportDagrapportenResult]) => {
        this.dagrapporten = dagrapportenResult as Dagrapport[];
        this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];
        console.log(this.dagrapporten);
        console.log(this.transportDagrapporten);
        while(this.allUsers == null){
          await this.delay(50);
        }
        if(this.dagrapporten != null && this.dagrapporten.length !== 0){
          for (let dagrapport of this.dagrapporten) {
            dagrapport.date = new Date(dagrapport.date);
            for (let werk of dagrapport.werken) {
              let werkMinuten;
              if (werk.startDate != null) {
                werk.startDate = new Date(werk.startDate);
                werk.endDate = new Date(werk.endDate);
                werk.startDate.setSeconds(0, 0);
                werk.endDate.setSeconds(0, 0);
                werkMinuten = this.calculateWorkMinutes(werk.startDate, werk.endDate);
              }
              if (werk.werkzaamheid != null && werk.startDate != null) {
                let werkzaamheid;
                if (werk.werkzaamheid.naam === 'Andere') {
                  werkzaamheid = werk.anderWerk + ' ';
                } else if (werk.werkzaamheid.naam === 'Meerwerk') {
                  werkzaamheid = 'Meerwerk ' + werk.meerWerk + ' ';
                } else if (werk.subActiviteit != null && werk.subActiviteit !== '') {
                  werkzaamheid = werk.werkzaamheid.naam + ' ' + werk.subActiviteit + ' ';
                } else {
                  werkzaamheid = werk.werkzaamheid.naam + ' ';
                }



                if (werk.machinisten != null && werk.machinisten.length !== 0) {
                  for (let machinist of werk.machinisten) {
                    let machinistIndex = this.allUsers.findIndex(x => x._id === machinist._id);
                    if (machinistIndex != null && machinistIndex !== -1) {
                      let dayIndex = this.allUsers[machinistIndex].days?.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0])
                      if(dayIndex !== -1){
                        this.allUsers[machinistIndex].days[dayIndex].werkMinuten += werkMinuten;
                        this.allUsers[machinistIndex].totaleWerkMinuten += werkMinuten;
                        if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                          this.allUsers[machinistIndex].days[dayIndex].schaft = dagrapport.schaft;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].startDate == null || this.allUsers[machinistIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].startDate = werk.startDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].endDate == null || this.allUsers[machinistIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].endDate = werk.endDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                        }
                        let werfIndex = this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                        this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                        }
                        if(this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                          this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                        }
                        this.allUsers[machinistIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                      }
                    }
                  }
                }
                if (werk.handwerkers != null && werk.handwerkers.length !== 0) {
                  for (let arbeider of werk.handwerkers) {
                    let arbeiderIndex = this.allUsers.findIndex(x => x._id === arbeider._id);
                    if (arbeiderIndex != null && arbeiderIndex !== -1) {
                      let dayIndex = this.allUsers[arbeiderIndex].days.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0])
                      this.allUsers[arbeiderIndex].days[dayIndex].werkMinuten += werkMinuten;
                      this.allUsers[arbeiderIndex].totaleWerkMinuten += werkMinuten;
                      if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                        this.allUsers[arbeiderIndex].days[dayIndex].schaft = dagrapport.schaft;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].startDate == null || this.allUsers[arbeiderIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].startDate = werk.startDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].endDate == null || this.allUsers[arbeiderIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].endDate = werk.endDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                      }
                      let werfIndex = this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                      this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                      }
                      if(this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                      }
                      this.allUsers[arbeiderIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                    }
                  }
                }
                if (werk.onderaannemers != null && werk.onderaannemers.length !== 0) {
                  for (let onderaannemer of werk.onderaannemers) {
                    let onderaannemerIndex = this.onderaannemers.findIndex(x => x._id === onderaannemer._id);
                    if (onderaannemerIndex != null && onderaannemerIndex !== -1) {
                      let dayIndex = this.onderaannemers[onderaannemerIndex].days.findIndex(x => x.day === werk.startDate.toISOString().split('T')[0])
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].werkMinuten += werkMinuten;
                      this.onderaannemers[onderaannemerIndex].totaleWerkMinuten += werkMinuten;

                      if(dagrapport.schaft != null && dagrapport.schaft !== 0){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].schaft = dagrapport.schaft;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].startDate = werk.startDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].endDate = werk.endDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === dagrapport.werf._id) == null){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.push({werf: dagrapport.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                      }
                      let werfIndex = this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === dagrapport.werf._id);
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: werkzaamheid, werkMinuten: werkMinuten, dagrapportId: dagrapport._id});
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.startDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.startDate;
                      }
                      if(this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.endDate.getTime()){
                        this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.endDate;
                      }
                      this.onderaannemers[onderaannemerIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += werkMinuten;
                    }
                  }
                }
              }
            }
          }
        }

        this.transportDagrapporten = transportDagrapportenResult as Transportdagrapport[];
        if (this.transportDagrapporten != null && this.transportDagrapporten.length !== 0) {
          for (let transportdagrapport of this.transportDagrapporten) {
            transportdagrapport.date = new Date(transportdagrapport.date);
            let userIndex = this.chauffeurs.findIndex(x => x._id === transportdagrapport.user_id?._id);

            for (let werk of transportdagrapport.transportwerken) {
              if (werk.dateLaadplaats != null) {
                werk.dateLaadplaats = new Date(werk.dateLaadplaats);
                werk.dateLosplaats = new Date(werk.dateLosplaats);
              }
              if (userIndex != null && userIndex !== -1) {
                let dayIndex = this.chauffeurs[userIndex].days.findIndex(x => x.day === werk.dateLosplaats.toISOString().split('T')[0])
                if (dayIndex != null) {
                  if(transportdagrapport.schaft != null && transportdagrapport.schaft !== 0){
                    this.chauffeurs[userIndex].days[dayIndex].schaft = transportdagrapport.schaft;
                  }
                  let werkMinuten = this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats);
                  console.log(dayIndex)
                  console.log(this.chauffeurs[userIndex].days)
                  this.chauffeurs[userIndex].days[dayIndex].werkMinuten += werkMinuten;
                  this.chauffeurs[userIndex].totaleWerkMinuten += werkMinuten;

                  if(this.chauffeurs[userIndex].days[dayIndex].startDate == null || this.chauffeurs[userIndex].days[dayIndex].startDate.getTime() > werk.dateLaadplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].startDate = werk.dateLaadplaats;
                  }
                  if(this.chauffeurs[userIndex].days[dayIndex].endDate == null || this.chauffeurs[userIndex].days[dayIndex].endDate.getTime() < werk.dateLosplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].endDate = werk.dateLosplaats;
                  }
                  this.chauffeurs[userIndex].days[dayIndex].transportDagrapportId = transportdagrapport._id;
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.find(x => x.werf._id === werk.werf._id) == null){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.push({werf: werk.werf, activiteiten: [], startDate: null, endDate: null, werkMinuten: 0});
                  }
                  let activiteit = werk.laadplaats.naam + ' - ' + werk.losplaats.naam;
                  let werfIndex = this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf.findIndex(x => x.werf._id === werk.werf._id);
                  this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].activiteiten.push({activiteit: activiteit, werkMinuten: this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats), dagrapportId: null});
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate == null || this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate.getTime() > werk.dateLaadplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].startDate = werk.dateLaadplaats;
                  }
                  if(this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate == null || this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate.getTime() < werk.dateLosplaats.getTime()){
                    this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].endDate = werk.dateLosplaats;
                  }
                  this.chauffeurs[userIndex].days[dayIndex].activiteitenPerWerf[werfIndex].werkMinuten += this.calculateWorkMinutes(werk.dateLaadplaats, werk.dateLosplaats);
                }
              } else {
                console.log('lol')
              }
            }
          }
        }

        if(this.formService.aanwezigheidsLijstChosenSoort === 'Arbeiders'){
          this.filteredArray = this.arbeiders;
        } else if(this.formService.aanwezigheidsLijstChosenSoort === 'Chauffeurs'){
          this.filteredArray = this.chauffeurs;
        } else if(this.formService.aanwezigheidsLijstChosenSoort === 'Onderaannemers'){
          this.filteredArray = this.onderaannemers;
        }
        resolve(this.dagrapporten);
      },
        (error) => {
          // If there's an error, reject the promise
          reject(error);
        });
    });
  }
  getWeeksInYear(year) {
    let weeks = [];
    let firstDayOfYear = new Date(year, 0, 1);  // January 1st
    let firstWeekday = (firstDayOfYear.getDay() + 6) % 7;  // Get the weekday, with Monday as 0
    let dayOfYear = firstDayOfYear.getDate() - firstWeekday;

    while (dayOfYear < 365) {
      let days = [];
      for (let i = 1; i < 6; i++) {  // Loop through each weekday
        let currentDay = new Date(year, 0, dayOfYear + i);
        days.push(currentDay.toISOString().split('T')[0]);
      }

      // Correctly calculate the start and end of the week
      let startOfWeek = new Date(year, 0, dayOfYear);  // Monday
      let endOfWeek = new Date(year, 0, dayOfYear + 4);  // Friday

      weeks.push({ start: startOfWeek, end: endOfWeek, days: days });
      dayOfYear += 7;  // Move to the next week
    }

    return weeks;
  }
  convertDate(date: Date){
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
  }

  convertHours(date: Date){
    return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
  }
  calculateWorkMinutes(startDate, endDate) {
    const millisecondsDifference = endDate.getTime() - startDate.getTime();
    const totalMinutes = Math.round(millisecondsDifference / 60000);
    return totalMinutes;
  }
 async  prevWeek() {
    this.currentWeekIndex--;
    this.currentWeek = this.weeks[this.currentWeekIndex];
    let startOfWeek = this.currentWeek.start;
    let endOfWeek = this.currentWeek.end;
    this.formService.previousWeekIndexAanwezigsheidslijst = this.currentWeekIndex;
    this.isLoaded = false;
    await this.loadUsers();
    await this.getDagrapportenOfWeek(startOfWeek, endOfWeek);
    this.isLoaded = true;
  }

  async nextWeek() {
    this.currentWeekIndex++;
    this.currentWeek = this.weeks[this.currentWeekIndex];
    let startOfWeek = this.currentWeek.start;
    let endOfWeek = this.currentWeek.end;
    this.formService.previousWeekIndexAanwezigsheidslijst = this.currentWeekIndex;
    this.isLoaded = false;
    await this.loadUsers();
    await this.getDagrapportenOfWeek(startOfWeek, endOfWeek);
    this.isLoaded = true;
  }

  convertMinutenToUren(werkMinuten: number) {
    return Math.floor(werkMinuten / 60) + 'u' + ('0' + (werkMinuten % 60)).slice(-2);
  }

  goToDagrapport(day: any, activiteit: any) {
    this.formService.previousUserIdAanwezigheidslijst = day.userId;
    if(day.transportDagrapportId){
      this.formService.comingFromAanwezigheidslijst = true;
      this.formService.comingFromMeerwerken = false;
      this.router.navigate(['/pages/transport-dagrapporten/edit-transport-dagrapport' , day.transportDagrapportId]);
    } else {
      this.formService.comingFromAanwezigheidslijst = true;
      this.formService.comingFromMeerwerken = false;
      this.router.navigate(['/pages/dagrapporten/editdagrapport' ,activiteit.dagrapportId]);
    }
  }
  goToTransportDagrapport( transportDagrapportId: string) {
    this.formService.comingFromAanwezigheidslijst = true;
      this.router.navigate(['/pages/edit-transport-dagrapport' , transportDagrapportId]);
  }

  choseSoort($event: any) {
    console.log($event);
    let soort = $event;

    if(soort === 'Arbeiders'){
      this.filteredArray = this.arbeiders;
    } else if(soort === 'Chauffeurs'){
      this.filteredArray = this.chauffeurs;
    } else if(soort === 'Onderaannemers'){
      this.filteredArray = this.onderaannemers;
    }
    this.formService.aanwezigheidsLijstChosenSoort = soort;
    this.filterItemsNaam = this.filteredArray;
    this.observableFilteredItems$ = of(this.filteredArray?.map(x => x.name));
    this.input.nativeElement.value = '';
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
      this.scrollContainer.nativeElement.scrollTop = 0;
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  clearAutocomplete() {
    this.input.nativeElement.value = '';
    this.filterItemsNaam = this.filteredArray;
    this.observableFilteredItems$ = of(this.filterItemsNaam.map(x => x.name));
    this.input.nativeElement.focus();
  }

  clickAutoCompleteUser($event) {
    let naam = $event;
    const userIndex = this.filteredArray.findIndex(user => user.name === naam);
    if (userIndex !== -1) {
      this.scrollToUser(userIndex);

    }

   console.log(naam);
  }

  private scrollToUser(userIndex: number) {
    setTimeout(() => {
      const userElementArray = this.userElements.toArray();
      if (userElementArray[userIndex]) {
        const userElement = userElementArray[userIndex].nativeElement;
        const container = this.scrollContainer.nativeElement;

        const userRect = userElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const relativeTop = userRect.top - containerRect.top + container.scrollTop;

        this.smoothScrollTo(container, relativeTop, 150);
      }
    }, 100); // Small delay to ensure DOM is ready
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filterItemsNaam.filter(optionValue => optionValue.name.toLowerCase().includes(filterValue)).map(optionValue => optionValue.name);
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }
  onChange() {
    this.observableFilteredItems$ = this.getFilteredOptions(this.input.nativeElement.value);
  }


  private smoothScrollTo(element: HTMLElement, to: number, duration: number) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };
    animateScroll();
  }

  private easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  }
}
