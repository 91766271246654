
export class Materiaal {
  public _id: string;
  public naam: string;
  public aantal: number;
  public standaardMateriaal: string;
  public eenheid: string;
  public richting: string;
  public materiaalGewicht: string;
  public materiaal: string;
  public hoeveelheid: number;
  //ovam afvalstoffen
  officieleOmschrijving: string;
  euralCode: string;
  aantalRitten: number;
  aardVerwerking: string; //alleen verplicht bij gevaarlijke afvalstoffen
  verwerkingsTechniek: string; //alleen verplicht bij gevaarlijke afvalstoffen
  typeVerpakking: string;
  aantalVerpakkingen: number;
  fysischeEigenschappen: string;
  chemischeEigenschappen: string;
  instructieVoorTransporteur: string;
  isGevaarlijkAfval: boolean;
  gekoppeldeNormaleMateriaal: Materiaal;
  constructor(naam: string,_id : string, euralCode?: string, officieleOmschrijving?: string, aardVerwerking?: string, verwerkingsTechniek?: string) {
    this.naam = naam;
    this._id = _id;
    this.euralCode= euralCode;
    this.officieleOmschrijving = officieleOmschrijving;
    this.aardVerwerking = aardVerwerking;
    this.verwerkingsTechniek = verwerkingsTechniek;
  }
}
