import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import {NbDatepicker, NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import moment from "moment";

@Component({
  selector: 'verlofdagen-instellingen',
  templateUrl: './verlofdagen-instellingen.component.html',
  styleUrls: ['./verlofdagen-instellingen.component.scss', '../../styles/item-table.scss']
})
export class VerlofdagenInstellingenComponent implements OnInit {
  form: FormGroup;
  company: Company;
  isLoaded: boolean;
  constructor(private fb: FormBuilder, private formService: FormService,
              private apiService: ApiService, private router: Router,
              private toastController: NbToastrService) {

  }
  async ngOnInit() {
    this.isLoaded = false;
    this.company = this.apiService.thisCompany;
    while(this.company == null){
      await this.delay(50);
      this.company = this.apiService.thisCompany;
    }
    this.buildForm();
  }

  buildForm() {
    let today = moment();
    if(this.company._id == null) this.company._id = this.company.id;
    this.form = this.fb.group({
      verlofdagen: this.fb.array([]),
    });
    if(this.company.verlofDagen != null){
      this.company.verlofDagen.forEach(x => {
        const fromDate = moment(x.fromDate);
        const toDate = moment(x.toDate);

        const monthsDifference = today.diff(toDate, 'months', true);

        if (monthsDifference <= 2) {
          this.verlofdagen().push(
            this.fb.group({
              datum: [{start: fromDate, end: x.toDate != null ? toDate : undefined}],
            })
          );
        }

      });
    } else {
      this.verlofdagen().push(
        this.fb.group({
          datum: [],
        })
      );
    }

    this.isLoaded = true;
  }
  verlofdagen(): FormArray {
    return this.form.get('verlofdagen') as FormArray;
  }

  voegVerlofdagToe() {
    this.verlofdagen().push(
      this.fb.group({
        datum: [],
      })
    );
  }

  verwijderVerlofdag(index: number) {
    this.verlofdagen().removeAt(index);
  }

  opslaan() {
    console.log(this.form.value);
    let startAndEndDates = [];
    this.form.value.verlofdagen.forEach(x => {
      if(x.datum?.start != null){
        if(x.datum.end == null || x.datum.start.toString() === x.datum.end.toString()){
          startAndEndDates.push({fromDate: x.datum.start, toDate: undefined});
        } else {
          x.datum.end = new Date(x.datum.end).setHours(5);
          startAndEndDates.push({fromDate: x.datum.start, toDate: x.datum.end});
        }
        x.datum.start = new Date(x.datum.start).setHours(5);
      }
    });
    this.company.verlofDagen = startAndEndDates as any;
    this.apiService.updateCompany(this.company).subscribe(x => {
       this.toastController.success('Verlofdagen opgeslagen', 'Success');
    });
    // Opslaan logica hier, bijvoorbeeld naar een Node.js backend.
  }
  getDutchDayName(date: Date): string {
    const dayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    return dayNames[new Date(date).getDay()];
  }

  goBack() {
    this.router.navigate(['/pages/settings'])
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
}
