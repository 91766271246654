// weiger-storting-dialog.component.ts
import { Component, Inject } from '@angular/core';
import {Bedrijf} from "../../models/E-digid/bedrijf";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-change-status-dialog',
  styles: [`
    .dialog-content {
      min-width: 300px;
      font-family: Arial, sans-serif;
      p{font-weight: bold}
    }

    .dialog-title {
      color: blue; /* Blauwe kleur voor de titel */
      margin-bottom: 20px;
    }

    .dialog-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .dialog-button {
      margin-left: 10px;
    }
    .blue{
      color: white;
      background-color: cornflowerblue;
    }
  `],
  template: `
      <h1 mat-dialog-title class="dialog-title">{{data.title}}</h1>
      <div mat-dialog-content class="dialog-content">
          <p style="line-height: 40px; white-space: pre-line">{{data.message}}</p>
      </div>
      <p class="dialog-content">Gevonden bedrijf: {{bedrijf.naam}}</p>
      <div mat-dialog-actions class="dialog-actions">
          <button mat-button class="dialog-button" (click)="onNoClick()">Annuleren</button>
          <button mat-button class="dialog-button blue" [mat-dialog-close]="true">Bevestigen</button>
      </div>
  `
})
export class BedrijfExistsDialogComponent{
  bedrijf: Bedrijf;
  constructor(
    public dialogRef: MatDialogRef<BedrijfExistsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bedrijf = data.bedrijf;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
