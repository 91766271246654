

export class Machine {
  public _id: string;
  public naam: string;
  public nummer: string;
  kostprijs: number;
  richting: string;
  isKleineMachine: boolean;
  constructor(naam: string, _id: string) {
    this.naam = naam;
    this._id = _id;
  }
}
