<h1 style="margin-bottom: 10px;" mat-dialog-title>Attentie!</h1>
<div style="margin-bottom: 60px" mat-dialog-content>
  <p style="margin-bottom: 30px;font-size: 15px"><b>Bent u zeker dat u alle testobjecten wilt verwijderen?</b></p>
  <p class="testObjectText" ><b>Testobjecten:</b> 5 activiteiten, 5 machines, 5 materialen, 5 werven,<br> 5 arbeiders, 2 onderaannemers</p>
</div>

<div style="display:flex;flex-direction: row;justify-content: space-between;padding:0 60px" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annuleer</button>
  <button mat-button style="background-color: red; " (click)="onDeleteClick()" cdkFocusInitial>Alle objecten verwijderen</button>
</div>
