import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-reject-dialog',
  template: `
    <div style="display:flex;flex-direction:column;justify-content:space-around; align-items: center">
      <h1 mat-dialog-title>Reden voor afwijzen verlof?</h1>
      <div mat-dialog-content>
        <textarea nbInput style="height:100px;width:250px;text-align:start;" [(ngModel)]="data.reason"></textarea>
      </div>
      <div mat-dialog-actions>
        <button nbButton style="background-color:orangered; color: white; margin-right: 30px;" (click)="onNoClick()">Annuleer</button>
        <button (click)="rejectVerlof()" nbButton style="background-color:blue; color: white" cdkFocusInitial>Afwijzen</button>
      </div>
    </div>
  `,
})
export class RejectDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
	  rejectVerlof() {
    this.dialogRef.close(this.data.reason);
    }
}
