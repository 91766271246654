<h2 *ngIf="isLoaded"> Van : <span
  class="bold">{{fromDate.getDate()}}/{{fromDate.getMonth() + 1}}/{{fromDate.getFullYear()}}</span> tot
  <span class="bold">{{toDate.getDate()}}/{{toDate.getMonth() + 1}}/{{toDate.getFullYear()}}</span>
</h2>
<h2 *ngIf="!isLoaded">Laden...</h2>
<p style='font-size:13px'>Aanvragen account verwijdering: {{accountDeletionRequestString}}</p>
<nb-select class="smallInput" [(ngModel)]='maandSelector' id="type">
  <nb-option value="0">Januari</nb-option>
  <nb-option value="1">Februari</nb-option>
  <nb-option value="2">Maart</nb-option>
  <nb-option value="3">April</nb-option>
  <nb-option value="4">Mei</nb-option>
  <nb-option value="5">Juni</nb-option>
  <nb-option value="6">Juli</nb-option>
  <nb-option value="7">Augustus</nb-option>
  <nb-option value="8">September</nb-option>
  <nb-option value="9">Oktober</nb-option>
  <nb-option value="10">November</nb-option>
  <nb-option value="11">December</nb-option>
</nb-select>
<h3 *ngIf="isLoaded">{{'Betaalbedrag tot nu toe: (excl. BTW)€' + totaalPrijs.toFixed(2) + '  (incl. BTW)€' + (totaalPrijs * 1.21).toFixed(2)}}</h3>
<div cdkScrollable *ngIf="isAdmin"  class="scroll-table2 stylish-table">
  <table cdkDropList class="item-list">
    <tr class="item-box">
      <th colspan="3"></th>
      <th colspan="4"><i style="margin-right: 6px" class="fa fa-user"></i>Personeel</th>
      <th colspan="4"><i style="margin-right: 6px" class="fa fa-universal-access"></i>Rapporten laatste 2 maanden</th>
      <th colspan="3">Objecten</th>
      <th></th>
    </tr>
    <tr class="item-box">
      <th></th>
      <th class="subHeader">Bedrijfsnaam</th>
      <th class="subHeader">Tot. excl BTW</th>
      <th class="subHeader">Arbeiders</th>
      <th class="subHeader">Chauffeurs</th>
      <th class="subHeader">Onderhouds<br>techniekers</th>
      <th class="subHeader">Magazijniers</th>
      <th class="subHeader">Dagrapport</th>
      <th class="subHeader">Dagboeken</th>
      <th class="subHeader">Transport</th>
      <th class="subHeader">Edigds</th>
      <th class="subHeader">Werven</th>
      <th class="subHeader">Machines</th>
      <th class="subHeader">Activiteiten</th>
      <th class="subHeader">Maanden in contract</th>
    </tr>
    <tr class="item-box" *ngFor="let company of companyList">
      <td>
        <div class="buttonsRow">
          <input type="button" value="Stuur factuur" (click)="sendInvoice(company)" class="openButton">
        </div>
      </td>

      <td (click)="goToEditCompany(company.company)" class="companyNameBewerk">{{ company.company.name }} <i style="margin-left: 3px;font-size: 17px" class="fa fa-edit"></i></td>
      <td>€ {{company.totaalPrijs?.toFixed(2)}}</td>
      <td>{{NullToEmptyStr(company.grondwerkerCount)}}</td>
      <td>{{NullToEmptyStr(company.chauffeurCount)}}</td>
      <td>{{NullToEmptyStr(company.onderhoudsTechniekerCount)}}</td>
      <td>{{NullToEmptyStr(company.magazijnierCount)}}</td>
      <td>{{NullToEmptyStr(company.dagrapportCount)}}</td>
      <td>{{NullToEmptyStr(company.dagboekenCount)}}</td>
      <td>{{NullToEmptyStr(company.transportDagrapportenCount)}}</td>
      <td>{{NullToEmptyStr(company.edigdsOfMonthCount)}}</td>
      <td>{{NullToEmptyStr(company.wervenCount)}}</td>
      <td>{{NullToEmptyStr(company.machinesCount)}}</td>
      <td>{{NullToEmptyStr(company.activiteitenCount)}}</td>
      <td>{{NullToEmptyStr(company.maandenInContract)}}</td>
    </tr>
  </table>
</div>
<div style="margin-top: 40px !important;" cdkScrollable *ngIf="isAdmin"  class="scroll-table2">
  <table cdkDropList class="item-list">
    <tr class="item-box">
      <th style="min-width:150px !important;">Aanmaakdatum</th>
      <th style="min-width:150px !important;">Bedrijfsnaam</th>
      <th style="min-width:150px !important;">Persoon</th>
      <th style="min-width:300px !important;">Opmerking</th>
    </tr>
    <tr class="item-box" *ngFor="let comment of last10Comments">
       <td>{{comment.createdDate.toLocaleDateString()}}</td>
      <td>{{ comment.companyName }}</td>
      <td>{{comment.personName}}</td>
      <td>{{comment.comment}}</td>
      <td></td>
    </tr>
  </table>
</div>
